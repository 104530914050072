import React, { useState } from 'react';
import Fade from 'react-reveal/Fade'


function Error() {

  const [Msg] = useState('Learning is always fun!')
  const [Welcome] = useState(<Fade top duration={3000} ><h2 className="top-2ß0 absolute text-2xl md:text-4xl lg:text-6xl  text-white font-serif font-semibold">Oops.. Page Not Found!</h2></Fade>)


  return (

    <div className="flex h-screen justify-center items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >

       {Welcome}

      <div class="m-auto h-150 grid grid-cols-1 flex justify-items-center absolute center text-gray-50  items-center center shadow-2xl opacity-90 w-1/2 md:w-2/6 lg:w-2/5 p-2 md:p-3 lg:p-5">

     

      </div>




      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>

    </div>





  );
}
 
export default Error;