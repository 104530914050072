import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import bImg from '../images/qr.jpg'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Slide from 'react-reveal/Slide'
import Card from '../components/Card'
import CookieConsent from '../components/Cookie'


function Home() {

  const { mode } = useParams()
  const today = new Date();
  const [Stage, setStage] = useState(mode == 1 ? 'CLOSE' : 'START')
  const [Msg, setMsg] = useState('Learning is always fun!')
//  alert(today.getMonth()+'-'+today.getDate());
  const [BMsg, setBMsg] = useState(today.getMonth()+'-'+today.getDate()==='7-9'?'Happy Birthday Eakash!':'')
  const [Welcome, setWelcome] = useState(<Fade top duration={5000} ><h2 className=" text-2xl md:text-4xl lg:text-6xl  text-white font-serif font-semibold">Stay Curious!</h2></Fade>)
  const [Img, setImg] = useState(mode == 1 ? <Fade duration={5000}><div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  mb-2 shadow-2xl  bg-gray-200'>
    <Link to='/numquest'><Card Type='Main' CardID='1' Title='Num Quest' Content='Journey with the basics' bTitle=' =     M A T H  ?' bContent='1 A⋃B  2  3  x + y - z  *  ? /  A-B  4 5 6 [ ] ⋅ ÷ / mod ab  a^b √a  %  ( )   ⊥ Δ   |x-y| π   rad  A⋂B   y(n)   | x | ' /></Link>
    <Link to='/wordquest'><Card Type='Main' CardID='2' Title='Word Quest' Content='Fun with words' bTitle='Guess  ?' bContent='Inspire  Genius Think Talent Vocal Clarity Creative Fabulous Beautiful Begin Universe' /></Link>
    <Link to='/skillapp'><Card Type='Main' CardID='3' Title='Voice / VR / AR' Content="Let's Be Creative" bTitle='    Skill Apps ' bContent=" Let's Spell  Element Info Alexa Virtual Graphics Visual Augmented Reality " /></Link>
    <Link to='/Quiz'><Card Type='Main' CardID='4' Title='Quiz / Game' Content='Explore something different' bTitle=' Interactive !' bContent=' World Topics General Knowledge Physics Chemistry Geography Technology ' /></Link>
  </div></Fade> : <div className='grid grid-cols-1'><Fade duration={5000} ><img src={bImg} alt="test" className="w-full rounded-lg shadow=2xl hover:opacity-90"></img></Fade></div>)


  function SetStage() {

    setStage(Stage === 'START' ? 'CLOSE' : 'START')
    setMsg(Stage === 'START' ? '' : <Fade bottom duration={5000}>Learning is always fun!</Fade>)
    setWelcome(Stage === 'START' ? <Slide top duration={5000} ><h2 className=" text-2xl md:text-4xl lg:text-6xl  text-white font-serif font-semibold">Stay Curious!</h2></Slide> : <Flip bottom duration={3000} ><h2 className=" text-2xl md:text-4xl lg:text-6xl  text-white font-serif font-semibold">Stay Curious!</h2></Flip>)
    setImg(Stage === 'START' ? <Fade duration={5000}><div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  mb-2'>
      <Link to='/numquest'><Card Type='Main' CardID='1' Title='Num Quest' Content='Journey with the basics' bTitle=' =     M A T H  ?' bContent='1 A⋃B  2  3  x + y - z  *  ? /  A-B  4 5 6 [ ] ⋅ ÷ / mod ab  a^b √a  %  ( )   ⊥ Δ   |x-y| π   rad  A⋂B   y(n)   | x | ' /></Link>
      <Link to='/wordquest'><Card Type='Main' CardID='2' Title='Word Quest' Content='Fun with words' bTitle='Guess  ?' bContent='Inspire  Genius Think Talent Vocal Clarity Creative Fabulous Beautiful Begin Universe' /></Link>
      <Link to='/SkillApp'><Card Type='Main' CardID='3' Title='Voice / VR / AR' Content="Let's Be Creative" bTitle='    skill apps ' bContent=" Let's Spell  Element Info Alexa Virtual Graphics Visual Augmented Reality " /></Link>
      <Link to='/Quiz'><Card Type='Main' CardID='4' Title='Quiz / Game' Content='Explore something different' bTitle=' Interactive !' bContent=' World Topics General Knowledge Physics Chemistry Geography Technology ' /></Link>
    </div></Fade> : <div className='grid grid-cols-1 '><Fade duration={5000} ><img src={bImg} alt="test" className="w-full rounded-lg shadow=2xl hover:opacity-90"></img></Fade></div>)
  }

  return (

    <div className="relative h-full ">

    <div className="h-full  ">
      <div className=" m-2  bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ">
        <div className=" grid  grid-cols-1  justify-items-center items-center "   >

          <div className="w-full p-4 mb-5    bg-opacity-70 shadow-2xl border-b-4  animate-pulse"><Fade top duration={3000}> <h2 className=" text-xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold ">  </h2></Fade></div>
        </div>

    <div className="min-h-screen items-center "  >

      <div className=" m-2 md:m-2 lg:m-4  text-indigo-200 absolute-center ">
        <div className="p-2 m-4 grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-2 flex justify-items-center items-center  shadow-2xl bg-opacity-70 rounded-lg "   >
          <div className="mt-10 mx-4 text-center  animate-pulse">
            {Welcome}
            <Fade duration={5000}><button className='bg-blue-600 text-white  m-4 lg:text-2xl sm:text-sm p-2  rounded-md transition duration-500 ease-in-out  hover:bg-yellow-500 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => SetStage()} >
              {Stage}
            </button></Fade>
          </div>
          <div className=" w-full text-center shadow-2xl p-2 bg-opacity-40 bg-gray-100" onClick={() => SetStage()}>
            {Img}
          </div>

        </div>
        <div>
        <Flip bottom duration={3000} ><h2 className=" mt-10 p-4 text-4xl md:text-5xl lg:text-6xl text-white font-semibold font-lobster">{BMsg}</h2></Flip>
        </div>
      </div>



      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>


     
    </div>

</div></div></div>




  );
}

export default Home;