import React from 'react'
 
function Footer(){
    
    
    return (
       <footer className="bg-gray-200 text-xs text-center p-3 bottom-0 w-full shadow-inner">
          &copy; RAJKIRAN 2022
       </footer>   


    )

}
 
export default Footer;