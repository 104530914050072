module.exports = {
    Capitals : [ 
   ["What is the capital of Andhra Pradesh","Amaravati","Chandigarh","Aizawl","Mumbai","Amaravati"],
   ["Name the capital city of Arunachal Pradesh","Itanagar","Jaipur","Kohima","Imphal","Itanagar"],
   ["Which city is the capital of Assam","Dispur","Gangtok","Bhubaneswar","Shillong","Dispur"],
   ["Which of the following is the capital of Bihar","Patna","Chennai","Dehradun (Winter), Gairsain (Summer)","Aizawl","Patna"],
   ["____ is the capital of Chhattisgarh","Raipur","Hyderabad","Kolkata","Kohima","Raipur"],
   ["What is the capital of Goa","Panaji","Agartala","Port Blair","Bhubaneswar","Panaji"],
   ["Name the capital city of Gujarat","Gandhinagar","Lucknow","Chandigarh","Pondicherry","Gandhinagar"],
   ["Which city is the capital of Haryana","Chandigarh","Kavaratti","Daman","Amaravati","Chandigarh"],
   ["Which of the following is the capital of Himachal Pradesh","Dehradun (Winter) Gairsain (Summer)","Shimla","New Delhi","Itanagar","Shimla"],
   ["____ is the capital of Jharkhand","Kolkata","Ranchi","Srinagar (Summer), Jammu (Winter)","Dispur","Ranchi"],
   ["What is the capital of Karnataka","Port Blair","Bengaluru","Kavaratti","Patna","Bengaluru"],
   ["Name the capital city of Kerala","Chandigarh","Thiruvananthapuram","Pondicherry","Raipur","Thiruvananthapuram"],
   ["Which city is the capital of Madhya Pradesh","Daman","Bhopal","Leh","Panaji","Bhopal"],
   ["Which of the following is the capital of Maharashtra","New Delhi","Mumbai","Jaipur","Gandhinagar","Mumbai"],
   ["____ is the capital of Manipur","Srinagar (Summer),Jammu (Winter)","Imphal","Amaravati","Chandigarh","Imphal"],
   ["What is the capital of Meghalaya","Kavaratti","Shillong","Itanagar","Chandigarh","Shillong"],
   ["Name the capital city of Mizoram","Pondicherry","Aizawl","Dispur","Jaipur","Aizawl"],
   ["Which city is the capital of Nagaland","Leh","Kohima","Patna","Gangtok","Kohima"],
   ["Which of the following is the capital of Odisha","Bengaluru","Bhubaneswar","Raipur","Chennai","Bhubaneswar"],
   ["____ is the capital of Punjab","Thiruvananthapuram","Amaravati","Chandigarh","Hyderabad","Chandigarh"],
   ["What is the capital of Rajasthan","Bhopal","Itanagar","Jaipur","Agartala","Jaipur"],
   ["Name the capital city of Sikkim","Mumbai","Dispur","Gangtok","Lucknow","Gangtok"],
   ["Which city is the capital of Tamil Nadu","Imphal","Kavaratti","Chennai","Aizawl","Chennai"],
   ["Which of the following is the capital of Telangana","Shillong","Amaravati","Hyderabad","Kohima","Hyderabad"],
   ["____ is the capital of Tripura","Aizawl","Itanagar","Agartala","Bhubaneswar","Agartala"],
   ["Which city is the capital of Uttar Pradesh","Kohima","Dispur","Lucknow","Imphal","Lucknow"],
   ["Which of the following is the capital of Uttarakhand","Bhubaneswar","Patna","Shillong","Dehradun (Winter) Gairsain (Summer)","Dehradun (Winter) Gairsain (Summer)"],
   ["____ is the capital of West Bengal","Chandigarh","Raipur","Aizawl","Kolkata","Kolkata"],
   ["What is the capital of the union territory Andaman and Nicobar Islands","Jaipur","Panaji","Kohima","Port Blair","Port Blair"],
   ["________ is the capital of Chandigarh","Gangtok","Gandhinagar","Bhubaneswar","Chandigarh","Chandigarh"],
   ["Name the capital city of Dadra & Nagar Haveli and Daman & Diu","Chennai","Chandigarh","Amaravati","Daman","Daman"],
   ["Which of the following is the capital of Delhi","Hyderabad","Chandigarh","Itanagar","New Delhi","New Delhi"],
   ["What is the capital of the union territory Jammu and Kashmir","Agartala","Jaipur","Dispur","Srinagar (Summer) Jammu (Winter)","Srinagar (Summer) Jammu (Winter)"],
   ["What is the capital of Lakshadweep","Lucknow","Gangtok","Agartala","Kavaratti","Kavaratti"],
   ["Which city is the capital of Puducherry","Kohima","Chennai","Lucknow","Pondicherry","Pondicherry"],
   ["____ is the capital of Ladakh","Bhubaneswar","Hyderabad","Panaji","Leh","Leh"],
   ],
   
   
   GK : [ 
     ["What is the capital of Andhra Pradesh","Amaravati","Chandigarh","Aizawl","Mumbai","Amaravati"],
     ["Name the capital city of Arunachal Pradesh","Itanagar","Jaipur","Kohima","Imphal","Itanagar"],
     ["Which city is the capital of Assam","Dispur","Gangtok","Bhubaneswar","Shillong","Dispur"],
     ["Which of the following is the capital of Bihar","Patna","Chennai","Dehradun (Winter), Gairsain (Summer)","Aizawl","Patna"],
     ["____ is the capital of Chhattisgarh","Raipur","Hyderabad","Kolkata","Kohima","Raipur"],
     ["What is the capital of Goa","Panaji","Agartala","Port Blair","Bhubaneswar","Panaji"],
     ["Name the capital city of Gujarat","Gandhinagar","Lucknow","Chandigarh","Pondicherry","Gandhinagar"],
     ["Which city is the capital of Haryana","Chandigarh","Kavaratti","Daman","Amaravati","Chandigarh"],
     ["Which of the following is the capital of Himachal Pradesh","Dehradun (Winter) Gairsain (Summer)","Shimla","New Delhi","Itanagar","Shimla"],
     ["____ is the capital of Jharkhand","Kolkata","Ranchi","Srinagar (Summer), Jammu (Winter)","Dispur","Ranchi"],
     ["What is the capital of Karnataka","Port Blair","Bengaluru","Kavaratti","Patna","Bengaluru"],
     ["Name the capital city of Kerala","Chandigarh","Thiruvananthapuram","Pondicherry","Raipur","Thiruvananthapuram"],
     ["Which city is the capital of Madhya Pradesh","Daman","Bhopal","Leh","Panaji","Bhopal"],
     ["Which of the following is the capital of Maharashtra","New Delhi","Mumbai","Jaipur","Gandhinagar","Mumbai"],
     ["____ is the capital of Manipur","Srinagar (Summer),Jammu (Winter)","Imphal","Amaravati","Chandigarh","Imphal"],
     ["What is the capital of Meghalaya","Kavaratti","Shillong","Itanagar","Chandigarh","Shillong"],
     ["Name the capital city of Mizoram","Pondicherry","Aizawl","Dispur","Jaipur","Aizawl"],
     ["Which city is the capital of Nagaland","Leh","Kohima","Patna","Gangtok","Kohima"],
     ["Which of the following is the capital of Odisha","Bengaluru","Bhubaneswar","Raipur","Chennai","Bhubaneswar"],
     ["____ is the capital of Punjab","Thiruvananthapuram","Amaravati","Chandigarh","Hyderabad","Chandigarh"],
     ["What is the capital of Rajasthan","Bhopal","Itanagar","Jaipur","Agartala","Jaipur"],
     ["Name the capital city of Sikkim","Mumbai","Dispur","Gangtok","Lucknow","Gangtok"],
     ["Which city is the capital of Tamil Nadu","Imphal","Kavaratti","Chennai","Aizawl","Chennai"],
     ["Which of the following is the capital of Telangana","Shillong","Amaravati","Hyderabad","Kohima","Hyderabad"],
     ["____ is the capital of Tripura","Aizawl","Itanagar","Agartala","Bhubaneswar","Agartala"],
     ["Which city is the capital of Uttar Pradesh","Kohima","Dispur","Lucknow","Imphal","Lucknow"],
     ["Which of the following is the capital of Uttarakhand","Bhubaneswar","Patna","Shillong","Dehradun (Winter) Gairsain (Summer)","Dehradun (Winter) Gairsain (Summer)"],
     ["____ is the capital of West Bengal","Chandigarh","Raipur","Aizawl","Kolkata","Kolkata"],
     ["What is the capital of the union territory Andaman and Nicobar Islands","Jaipur","Panaji","Kohima","Port Blair","Port Blair"],
     ["________ is the capital of Chandigarh","Gangtok","Gandhinagar","Bhubaneswar","Chandigarh","Chandigarh"],
     ["Name the capital city of Dadra & Nagar Haveli and Daman & Diu","Chennai","Chandigarh","Amaravati","Daman","Daman"],
     ["Which of the following is the capital ofDelhi","Hyderabad","Chandigarh","Itanagar","New Delhi","New Delhi"],
     ["What is the capital of the union territory Jammu and Kashmir","Agartala","Jaipur","Dispur","Srinagar (Summer) Jammu (Winter)","Srinagar (Summer) Jammu (Winter)"],
     ["What is the capital of Lakshadweep","Lucknow","Gangtok","Agartala","Kavaratti","Kavaratti"],
     ["Which city is the capital of Puducherry","Kohima","Chennai","Lucknow","Pondicherry","Pondicherry"],
     ["____ is the capital of Ladakh","Bhubaneswar","Hyderabad","Panaji","Leh","Leh"],
     ["Which state has the city, Amaravati as the capital?","Andhra Pradesh","Uttarakhand","Manipur","Tripura","Andhra Pradesh"],
   ["Whose capital is Itanagar?","Arunachal Pradesh","West Bengal","Meghalaya","Uttar Pradesh","Arunachal Pradesh"],
   ["Which state has the capital of Dispur?","Assam","Andaman and Nicobar Islands","Mizoram","Uttarakhand","Assam"],
   ["Which of the following states has the capital as Patna?","Bihar","Chandigarh","Nagaland","West Bengal","Bihar"],
   ["Raipur is the capital of ______","Chhattisgarh","Andhra Pradesh","Odisha","Andaman and Nicobar Islands","Chhattisgarh"],
   ["Panaji is the capital city of ____","Goa","Arunachal Pradesh","Punjab","Chandigarh","Goa"],
   ["The CIty, Gandhinagar is the capital of _____","Gujarat","Assam","Rajasthan","Dadra & Nagar Haveli and Daman & Diu","Gujarat"],
   ["Which of the following has the capital as Chandigarh?","Haryana","Bihar","Sikkim","Delhi","Haryana"],
   ["Which state has the cities, Shimla as the capital cities?","Himachal Pradesh","Chhattisgarh","Tamil Nadu","Jammu and Kashmir","Himachal Pradesh"],
   ["Whose capital is Ranchi?","Jharkhand","Goa","Telangana","Lakshadweep","Jharkhand"],
   ["Which state has the capital of Bengaluru?","Karnataka","Gujarat","Uttarakhand","Puducherry","Karnataka"],
   ["Which of the following states has the capital as Thiruvananthapuram?","Kerala","Haryana","West Bengal","Ladakh","Kerala"],
   ["Bhopal is the capital of ______","Madhya Pradesh","Sikkim","Andaman and Nicobar Islands","Manipur","Madhya Pradesh"],
   ["Mumbai is the capital city of ____","Maharashtra","Tamil Nadu","Chandigarh","Meghalaya","Maharashtra"],
   ["The CIty, Imphal is the capital of _____","Manipur","Andhra Pradesh","Tripura","Mizoram","Manipur"],
   ["Which of the following has the capital as Shillong?","Meghalaya","Arunachal Pradesh","Uttar Pradesh","Nagaland","Meghalaya"],
   ["Which state has the city Aizawl as the capital?","Mizoram","Assam","Uttarakhand","Odisha","Mizoram"],
   ["Whose capital is Kohima?","Nagaland","Bihar","West Bengal","Punjab","Nagaland"],
   ["Which state has the capital of Bhubaneswar?","Odisha","Chhattisgarh","Andaman and Nicobar Islands","Rajasthan","Odisha"],
   ["Which of the following states has the capital as Chandigarh?","Punjab","Goa","Chandigarh","Sikkim","Punjab"],
   ["Jaipur is the capital of ______","Rajasthan","Gujarat","Dadra & Nagar Haveli and Daman & Diu","Tamil Nadu","Rajasthan"],
   ["Gangtok is the capital city of ____","Sikkim","Haryana","Delhi","Telangana","Sikkim"],
   ["The CIty, Chennai as the capital?","Tamil Nadu","Himachal Pradesh","Jammu and Kashmir","Chandigarh","Tamil Nadu"],
   ["Which of the following has the capital as Hyderabad?","Telangana","Jharkhand","Nagaland","Dadra & Nagar Haveli and Daman & Diu","Telangana"],
   ["Which state has the city Agartala as the capital?","Tripura","Karnataka","Odisha","Delhi","Tripura"],
   ["Whose capital is Lucknow?","Uttar Pradesh","Kerala","Punjab","Jammu and Kashmir","Uttar Pradesh"],
   ["Dehradun (Winter), Gairsain (Summer) are the capitals of ______","Uttarakhand","Madhya Pradesh","Rajasthan","Lakshadweep","Uttarakhand"],
   ["Which of the following states has the capital as Kolkata?","West Bengal","Maharashtra","Tamil Nadu","Puducherry","West Bengal"],
   ["Port Blair is the capital of _____","Andaman and Nicobar Islands","Manipur","Haryana","Ladakh","Andaman and Nicobar Islands"],
   ["Whose capital is Chandigarh?","Chandigarh","Meghalaya","Himachal Pradesh","Odisha","Chandigarh"],
   ["The CIty, Daman is the capital of _____","Dadra & Nagar Haveli and Daman & Diu","Mizoram","Jharkhand","Punjab","Dadra & Nagar Haveli and Daman & Diu"],
   ["Which of the following has the capital of New Delhi?","Delhi","Nagaland","Karnataka","Rajasthan","Delhi"],
   ["Which state has the city Srinagar (Summer), Jammu (Winter) as the capitals?","Jammu and Kashmir","Odisha","Kerala","Sikkim","Jammu and Kashmir"],
   ["Whose capital is Kavaratti?","Lakshadweep","Punjab","Madhya Pradesh","Tamil Nadu","Lakshadweep"],
   ["Which state has the capital of Pondicherry?","Puducherry","Rajasthan","Maharashtra","Jammu and Kashmir","Puducherry"],
   ["Which of the following states has the capital as Leh?","Ladakh","Sikkim","Chandigarh","Haryana","Ladakh"],
     ],
     
   
     History : [ 
       ["What is the capital of Andhra Pradesh","Amaravati","Chandigarh","Aizawl","Mumbai","Amaravati"],
       ["Name the capital city of Arunachal Pradesh","Itanagar","Jaipur","Kohima","Imphal","Itanagar"],
       ["Which city is the capital of Assam","Dispur","Gangtok","Bhubaneswar","Shillong","Dispur"],
       ["Which of the following is the capital of Bihar","Patna","Chennai","Dehradun (Winter), Gairsain (Summer)","Aizawl","Patna"],
       ["____ is the capital of Chhattisgarh","Raipur","Hyderabad","Kolkata","Kohima","Raipur"],
       ["What is the capital of Goa","Panaji","Agartala","Port Blair","Bhubaneswar","Panaji"],
       ["Name the capital city of Gujarat","Gandhinagar","Lucknow","Chandigarh","Pondicherry","Gandhinagar"],
       ["Which city is the capital of Haryana","Chandigarh","Kavaratti","Daman","Amaravati","Chandigarh"],
       ["Which of the following is the capital of Himachal Pradesh","Dehradun (Winter) Gairsain (Summer)","Shimla","New Delhi","Itanagar","Shimla"],
       ["____ is the capital of Jharkhand","Kolkata","Ranchi","Srinagar (Summer), Jammu (Winter)","Dispur","Ranchi"],
       ["What is the capital of Karnataka","Port Blair","Bengaluru","Kavaratti","Patna","Bengaluru"],
       ["Name the capital city of Kerala","Chandigarh","Thiruvananthapuram","Pondicherry","Raipur","Thiruvananthapuram"],
       ["Which city is the capital of Madhya Pradesh","Daman","Bhopal","Leh","Panaji","Bhopal"],
       ["Which of the following is the capital of Maharashtra","New Delhi","Mumbai","Jaipur","Gandhinagar","Mumbai"],
       ["____ is the capital of Manipur","Srinagar (Summer),Jammu (Winter)","Imphal","Amaravati","Chandigarh","Imphal"],
       ["What is the capital of Meghalaya","Kavaratti","Shillong","Itanagar","Chandigarh","Shillong"],
       ["Name the capital city of Mizoram","Pondicherry","Aizawl","Dispur","Jaipur","Aizawl"],
       ["Which city is the capital of Nagaland","Leh","Kohima","Patna","Gangtok","Kohima"],
       ["Which of the following is the capital of Odisha","Bengaluru","Bhubaneswar","Raipur","Chennai","Bhubaneswar"],
       ["____ is the capital of Punjab","Thiruvananthapuram","Amaravati","Chandigarh","Hyderabad","Chandigarh"],
       ["What is the capital of Rajasthan","Bhopal","Itanagar","Jaipur","Agartala","Jaipur"],
       ["Name the capital city of Sikkim","Mumbai","Dispur","Gangtok","Lucknow","Gangtok"],
       ["Which city is the capital of Tamil Nadu","Imphal","Kavaratti","Chennai","Aizawl","Chennai"],
       ["Which of the following is the capital of Telangana","Shillong","Amaravati","Hyderabad","Kohima","Hyderabad"],
       ["____ is the capital of Tripura","Aizawl","Itanagar","Agartala","Bhubaneswar","Agartala"],
       ["Which city is the capital of Uttar Pradesh","Kohima","Dispur","Lucknow","Imphal","Lucknow"],
       ["Which of the following is the capital of Uttarakhand","Bhubaneswar","Patna","Shillong","Dehradun (Winter) Gairsain (Summer)","Dehradun (Winter) Gairsain (Summer)"],
       ["____ is the capital of West Bengal","Chandigarh","Raipur","Aizawl","Kolkata","Kolkata"],
       ["What is the capital of the union territory Andaman and Nicobar Islands","Jaipur","Panaji","Kohima","Port Blair","Port Blair"],
       ["________ is the capital of Chandigarh","Gangtok","Gandhinagar","Bhubaneswar","Chandigarh","Chandigarh"],
       ["Name the capital city of Dadra & Nagar Haveli and Daman & Diu","Chennai","Chandigarh","Amaravati","Daman","Daman"],
       ["Which of the following is the capital ofDelhi","Hyderabad","Chandigarh","Itanagar","New Delhi","New Delhi"],
       ["What is the capital of the union territory Jammu and Kashmir","Agartala","Jaipur","Dispur","Srinagar (Summer) Jammu (Winter)","Srinagar (Summer) Jammu (Winter)"],
       ["What is the capital of Lakshadweep","Lucknow","Gangtok","Agartala","Kavaratti","Kavaratti"],
       ["Which city is the capital of Puducherry","Kohima","Chennai","Lucknow","Pondicherry","Pondicherry"],
       ["____ is the capital of Ladakh","Bhubaneswar","Hyderabad","Panaji","Leh","Leh"],
       ],
       
       Geography : [ 
         ["What is the capital of Andhra Pradesh","Amaravati","Chandigarh","Aizawl","Mumbai","Amaravati"],
         ["Name the capital city of Arunachal Pradesh","Itanagar","Jaipur","Kohima","Imphal","Itanagar"],
         ["Which city is the capital of Assam","Dispur","Gangtok","Bhubaneswar","Shillong","Dispur"],
         ["Which of the following is the capital of Bihar","Patna","Chennai","Dehradun (Winter), Gairsain (Summer)","Aizawl","Patna"],
         ["____ is the capital of Chhattisgarh","Raipur","Hyderabad","Kolkata","Kohima","Raipur"],
         ["What is the capital of Goa","Panaji","Agartala","Port Blair","Bhubaneswar","Panaji"],
         ["Name the capital city of Gujarat","Gandhinagar","Lucknow","Chandigarh","Pondicherry","Gandhinagar"],
         ["Which city is the capital of Haryana","Chandigarh","Kavaratti","Daman","Amaravati","Chandigarh"],
         ["Which of the following is the capital of Himachal Pradesh","Dehradun (Winter) Gairsain (Summer)","Shimla","New Delhi","Itanagar","Shimla"],
         ["____ is the capital of Jharkhand","Kolkata","Ranchi","Srinagar (Summer), Jammu (Winter)","Dispur","Ranchi"],
         ["What is the capital of Karnataka","Port Blair","Bengaluru","Kavaratti","Patna","Bengaluru"],
         ["Name the capital city of Kerala","Chandigarh","Thiruvananthapuram","Pondicherry","Raipur","Thiruvananthapuram"],
         ["Which city is the capital of Madhya Pradesh","Daman","Bhopal","Leh","Panaji","Bhopal"],
         ["Which of the following is the capital of Maharashtra","New Delhi","Mumbai","Jaipur","Gandhinagar","Mumbai"],
         ["____ is the capital of Manipur","Srinagar (Summer),Jammu (Winter)","Imphal","Amaravati","Chandigarh","Imphal"],
         ["What is the capital of Meghalaya","Kavaratti","Shillong","Itanagar","Chandigarh","Shillong"],
         ["Name the capital city of Mizoram","Pondicherry","Aizawl","Dispur","Jaipur","Aizawl"],
         ["Which city is the capital of Nagaland","Leh","Kohima","Patna","Gangtok","Kohima"],
         ["Which of the following is the capital of Odisha","Bengaluru","Bhubaneswar","Raipur","Chennai","Bhubaneswar"],
         ["____ is the capital of Punjab","Thiruvananthapuram","Amaravati","Chandigarh","Hyderabad","Chandigarh"],
         ["What is the capital of Rajasthan","Bhopal","Itanagar","Jaipur","Agartala","Jaipur"],
         ["Name the capital city of Sikkim","Mumbai","Dispur","Gangtok","Lucknow","Gangtok"],
         ["Which city is the capital of Tamil Nadu","Imphal","Kavaratti","Chennai","Aizawl","Chennai"],
         ["Which of the following is the capital of Telangana","Shillong","Amaravati","Hyderabad","Kohima","Hyderabad"],
         ["____ is the capital of Tripura","Aizawl","Itanagar","Agartala","Bhubaneswar","Agartala"],
         ["Which city is the capital of Uttar Pradesh","Kohima","Dispur","Lucknow","Imphal","Lucknow"],
         ["Which of the following is the capital of Uttarakhand","Bhubaneswar","Patna","Shillong","Dehradun (Winter) Gairsain (Summer)","Dehradun (Winter) Gairsain (Summer)"],
         ["____ is the capital of West Bengal","Chandigarh","Raipur","Aizawl","Kolkata","Kolkata"],
         ["What is the capital of the union territory Andaman and Nicobar Islands","Jaipur","Panaji","Kohima","Port Blair","Port Blair"],
         ["________ is the capital of Chandigarh","Gangtok","Gandhinagar","Bhubaneswar","Chandigarh","Chandigarh"],
         ["Name the capital city of Dadra & Nagar Haveli and Daman & Diu","Chennai","Chandigarh","Amaravati","Daman","Daman"],
         ["Which of the following is the capital ofDelhi","Hyderabad","Chandigarh","Itanagar","New Delhi","New Delhi"],
         ["What is the capital of the union territory Jammu and Kashmir","Agartala","Jaipur","Dispur","Srinagar (Summer) Jammu (Winter)","Srinagar (Summer) Jammu (Winter)"],
         ["What is the capital of Lakshadweep","Lucknow","Gangtok","Agartala","Kavaratti","Kavaratti"],
         ["Which city is the capital of Puducherry","Kohima","Chennai","Lucknow","Pondicherry","Pondicherry"],
         ["____ is the capital of Ladakh","Bhubaneswar","Hyderabad","Panaji","Leh","Leh"],
         ],
         
   
   }
   