import React from 'react'
import Navigation from './Navigation'
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

function Header(){
    
    
    return (
        <header className="border-b  p-3 flex justify-between items-center  shadow-xl">
            <Link  to='/'>
            <span className="m-3 text-xl md:text-3xl lg:text-4xl  font-arizonia font-semibold">
                Deege Quest
            </span>
            </Link>
            <Navigation />
        </header>


    )

}
 
export default Header;
