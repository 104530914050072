import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import bImg from '../images/vr2.png'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'
import Card from '../components/Card'
import Btn from '../components/Button'
import CookieConsent from '../components/Cookie'


function SkillApp() {
  const [Stage, setStage] = useState('START')
  const [Msg, setMsg] = useState('Learning is always fun!')
  const [Welcome] = useState(<Fade top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Voice / VR / AR Apps</h2></Fade>)
  const [Category, setCategory] = useState(<Fade top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold"></h2></Fade>)

  const [Set, setSet] = useState(1)
  const [SetContent, setSetContent]= useState(<div className='grid grid-cols-1 shadow=2xl   flex m-2'><Fade duration={3000} >
                                              <img src={bImg} alt="test" className="w-full rounded-lg hover:opacity-90 "></img></Fade></div>)
  const [SetD,setSetD]=useState('')

  function SetStage() {

    switch (Set) {
      case 0: 
           setSetContent(<div className='grid grid-cols-1  flex m-2' ><Fade duration={3000}  >
           <img src={bImg} alt="test" className="w-full rounded-lg shadow=2xl hover:opacity-90 " ></img></Fade></div>)
           setSetD('')
           setCategory('')
           break;
      case 1: 
           setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 flex   '>
           <Link to='/Alexa/LetsSpell'><Card Type='SkillApp' CardID='1' Title='Lets Spell'  /></Link>
           <Link to='/Alexa/ElementInfo'><Card Type='SkillApp' CardID='2' Title='Element Info'  /></Link>
           </div> </Fade> ) 
             setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Alexa</h2></Slide>)
             setSetD('Voice Apps')
         break
      case 2: 
         setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 flex  '>
       <Link to='/VR/LightControls'><Card Type='VRApp' CardID='1' Title='Light Controls'  /></Link>
           <Link to='/VR/PoptheBalloon'><Card Type='VRApp' CardID='2' Title='Pop the Balloon'  /></Link>
           </div> </Fade> ) 
             setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Virtual Reality</h2></Slide>)
             setSetD('VR Apps')
       break
      default:
           setCategory('No Category')
           setSetContent('NO CONTENT!')  
           setSetD('NO CONTENT')
           break
 
   }
    setSet(prevSet=>prevSet>=2?0:prevSet+1)
   // alert(Set)
    setStage(Stage == 'START' ? 'CLOSE' : 'START')
    setMsg(Stage == 'START' ? '' : <Fade bottom duration={5000}>Learning is always fun!</Fade>)
  //  setWelcome(Stage == 'START' ? <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Voice / VR / AR Apps</h2></Slide> : <Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Voice / VR / AR Apps</h2></Flip>)
 //   setCategory(Stage == 'START' ? <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Alexa</h2></Slide> : <Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold"></h2></Flip>)
  }

  return (

    <div className="min-h-screen items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >
      <Card Type='Title' Title={Welcome} />




      <div id='arithmetic' className=" text-indigo-200 absolute-center py-4 ">
        <div className=" m-4 grid  grid-cols-1 md:grid-cols-1 lg:grid-cols-1 flex justify-items-center  "   >
          <div className=" text-center  lg:px-58 rounded-2xl p-4 ">
            {Category}
            <div class="inline-flex space-x-4 shadow-2xl" >
              <div className='flex-2 rounded-l-full  bg-white text-white'> lt </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Up" ></Btn>
              </div>
              <Link to='/md=1'><div className="flex-2  ..."  >
                <Btn Card='NumBtn' Content='Home' />
              </div></Link>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="List" />
              </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Dn" />
              </div>
              <div className='flex-2 rounded-r-full  bg-white text-white'>lt</div>
            </div>
            <Slide duration={3000}><div className='font-extrabold text-gray-50 text-xl lg:text=2xl'>{SetD}</div></Slide>

          </div>
          <div className=" rounded-3xl w-full grid grid-cols-1 justify-items-center text-center  shadow-2xl bg-opacity-70 pb-4 lg:pb-10" onClick={() => SetStage()}>
            {SetContent}
          </div>
        </div>


        <CookieConsent />
      </div>





      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>

    </div>





  );
}

export default SkillApp;