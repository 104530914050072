module.exports = {

 "Farooq Garden" : [ 
        ["CWhat is the capital of Virginia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["CWhich city is the capital of Arizona?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["CName the capital of West Virginia","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["CName the capital of New York","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of North Carolina?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the capital of Alabama?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Nevada?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of New Mexico?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the capital of Colorado?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Georgia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Name the capital of Washington","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Ohhio?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the  capital of California?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Texas?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Name the capital of Florida","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        

 ],

 Synonyms : [ 
  ["HWhat is the capital of Virginia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["HWhich city is the capital of Arizona?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["HName the capital of West Virginia","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["HName the capital of New York","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["HWhat is the capital of North Carolina?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the capital of Alabama?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Nevada?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of New Mexico?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the capital of Colorado?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Georgia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Name the capital of Washington","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Ohhio?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the  capital of California?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Texas?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Name the capital of Florida","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  

],

Antonyms : [ 
  ["What is the opposite of THIN?","SHORT","THICK","SMALL","BIG","THICK"],
  ["What is the opposite word for EMPTY?","SPACE","FULL","CLOSE","OPEN","FULL"],
  ["The opposite word for BUY is ","DONATE","SELL","GIVE","TAKE","SELL"],
  ["The opposite of ARRIVE is ","SLIDE","DEPART","SWING","JUMP","DEPART"],
  ["What is the opposite of WINTER?","FROST","SUMMER","RAIN","COLD","SUMMER"],
  ["What is the opposite word for GIRL?","INFANT","BOY","WOMAN","MAN","BOY"],
  ["The opposite word for ASK is ","REQUEST","REPLY","QUESTION","SPEAK","REPLY"],
  ["The opposite of SAFE is ","QUIET","DANGER","PEACE","CALM","DANGER"],
  ["What is the opposite of HAPPY?","JOY","SAD","CRY","ANGRY","SAD"],
  ["What is the opposite word for VICTORY?","ELECT","LOSS","WIN","ACHIEVEMENT","LOSS"],
  ["The opposite word for SUN is ","MOUNTAIN","MOON","OCEAN","SKY","MOON"],
  ["The opposite of CEILING is ","DECK","FLOOR","WALL","DOOR","FLOOR"],
  ["What is the opposite of CATCH?","RECEIVE","DROP","HOLD","PICK","DROP"],
  ["What is the opposite word for PRESENT?","ALWAYS","PAST","TODAY","NEVER","PAST"],
  ["The opposite word for NEAR is ","CLOSE","FAR","SIDE","DOWN","FAR"],
  
],

Spelling : [ 
  ["Fill in the blanks  D_ST_ACT","I, R","E, R","I, A","U, R","I, R","DISTRACT"],
  ["Fill in the blanks  I_TERR_PT","N, U","N, E","N, O","N, I","N, U","INTERRUPT"],
  ["Fill in the blanks  CR_Y_N","A, O","A, E","E, A","E,E","A, O","CRAYON"],
  ["Fill in the blanks  PI_T_RE","C, U","K, U","C, E","C, A","C, U","PICTURE"],
  ["Fill in the blanks  S_RPR_SE","U, I","E, I","A, I","U, E","U, I","SURPRISE"],
  ["Fill in the blanks  S_ST_M","Y, E","Y, A","I, E","E, E","Y, E","SYSTEM"],
  ["Fill in the blanks  SP_CI_L","E, A","A, A","E, E","E, O","E, A","SPECIAL"],
  ["Fill in the blanks  V_S_ON","I, I","I, E","I, U","E, I","I, I","VISION"],
  ["Fill in the blanks  BR_N_","A, D","N, D","E, D","U, D","A, D","BRAND"],
  ["Fill in the blanks  G_OGR_PHY","E, A","I, A","E, E","I, E","E, A","GEOGRAPHY"],
  ["Fill in the blanks  G_OL_GY","E, O","E, E","I, E","I, O","E, O","GEOLOGY"],
  ["Fill in the blanks  SC_EN_E","I, C","E, C","I, S","O, S","I, C","SCIENCE"],
  ["Fill in the blanks  STR_T_GY","A, E","A, A","E, E","A, I","A, E","STRATEGY"],
  ["Fill in the blanks  ST_RV_NG","A, I","A, E","U, I","A, A","A, I","STARVING"],
  ["Fill in the blanks  SP_R_T","I, I","I, E","E, I","A, E","I, I","SPIRIT"],
  ["Fill in the blanks  COMP_T_TION","E, I","E, E","I, I","I, E","E, I","COMPETITION"],
  ["Fill in the blanks  DICT_ON_RY","I, A","E, A","I, E","E, E","I, A","DICTIONARY"],
  ["Fill in the blanks  VOC_BUL_RY","A, A","A, E","O, A","O, E","A, A","VOCABULARY"],
  
],


}