import React, { useState } from 'react';
import cImg from '../images/cont-1.png'
import Fade from 'react-reveal/Fade'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import CookieConsent from '../components/Cookie'

function Contact() {

  const [Msg] = useState('Learning is always fun!')
  const [Welcome] = useState(<Fade top duration={3000} ><h2 className="top-2ß0 absolute text-2xl md:text-4xl lg:text-6xl  text-white font-serif font-semibold">Contact</h2></Fade>)
  const [Img] = useState(<div className='grid grid-cols-1  flex m-2'><Fade duration={3000} ><img src={cImg} alt="test" class="w-full p-4 rounded-lg shadow=2xl hover:opacity-90 h-1/4"></img></Fade></div>)
  
  return (

    <div className="flex h-screen justify-center items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >

       {Welcome}

      <div class="m-auto h-150 grid grid-cols-1 flex justify-items-center absolute center text-gray-50  items-center center shadow-2xl opacity-90 w-1/2 md:w-2/6 lg:w-2/5 p-2 md:p-3 lg:p-5">

      

      <span  className='flex inline-flex space-x-4'>
        <a href = "mailto: deegequest@gmail.com"><i className="fas fa-at text-3xl"> </i></a>
        <a href="https://www.facebook.com/deegequest/"
          className="facebook social">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://www.youtube.com/channel/UCnCYnFTj7dCiqhmseTYVwjA" className="youtube social">
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a href="https://www.instagram.com/me.rajkiran"
          className="instagram social">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        </span>
        {Img}

      </div>




      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>

      <CookieConsent />

    </div>





  );
}

export default Contact;