import React from 'react';
import ReactDOM from 'react-dom'
import Button from '../components/Button';
import Draggable from 'react-draggable'
import { motion } from 'framer-motion';
import { blue } from '@material-ui/core/colors';

const items = [
    { number: "1", title: "🇦🇷 Argentina"},
    { number: "2", title: "🤩 YASS"},
    { number: "3", title: "👩🏼‍💻 Tech Girl"},
    { number: "4", title: "💋 Lipstick & Code"},
    { number: "5", title: "💃🏼 Latina"},
  ]



  

export default class TestClient extends React.Component {
    constructor(props) {
        super(props);

        this.textInput = null;
        this.drag1 = null;

        this.state = {
            W: 0,
            QNum1: { a: 0, b: 0, c: 0, d: 0 },
            Drag1: { a: 0, b: 0, c: 0, d: 0 },
            deltaPosition: {
                x: 0, y: 0
            },
            absPosition: {
                x: 0, y: 0
            },
            yoval:1,
            InputClass:'m-4 alig-center bg-yellow-600 text-xl text-red-200',
            InputBg: "",
        }

        this.setTextInputRef = element => {
            this.textInput = element;
        };
        this.setDrag1Ref = element => {
            this.drag1 = element;
        };

        this.focusTextInput = () => {
            // Focus the text input using the raw DOM API
            if (this.textInput) this.textInput.focus();

        };



    }

    showDimensions() {
        alert('here..' + this.textInput.clientWidth)
        this.setState({ W: this.textInput.clientWidth })
        this.setState({
            QNum1: {
                a: this.textInput.offsetLeft,
                b: this.textInput.offsetLeft + this.textInput.clientWidth,
                c: this.textInput.offsetTop,
                d: this.textInput.offsetTop + this.textInput.clientHeight,
            },
            Drag1: {
                a: this.drag1.offsetLeft,
                b: this.drag1.offsetLeft + this.drag1.clientWidth,
                c: this.drag1.offsetTop,
                d: this.drag1.offsetTop + this.drag1.clientHeight,
            },
            absPosition:{
                x: this.drag1.offsetLeft,
                y: this.drag1.offsetTop,
            }

        })
    }


    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            },
            Drag1: {
                a: this.drag1.offsetLeft,
                b: this.drag1.offsetLeft + this.drag1.clientWidth,
                c: this.drag1.offsetTop,
                d: this.drag1.offsetTop + this.drag1.clientHeight,
            },



        });
        if (this.state.deltaPosition.x > 700 && this.state.deltaPosition.x < 800){
            this.setState({ yoval: Infinity, InputBg: "green", InputClass:'m-4 alig-center bg-green-600 text-yellow-300 text-2xl' })
        } else {
            this.setState({ yoval: 1 , InputBg:"red" , InputClass:'m-4 alig-center bg-blue-400 text-white text-xl'})
        }
    }

    handleStop = (e, ui) => {
        const { x, y } = this.state.absPosition;
        this.setState({
            absPosition: {
                x: ui.x,
                y: ui.y,
            },
            InputClass:'bg-indigo-400',
        });

    }





    componentDidMount() {
        // autofocus the input on mount
        this.focusTextInput();
        this.showDimensions();

    }

    render() {
        // Use the `ref` callback to store a reference to the text input DOM
        // element in an instance field (for example, this.textInput).
        const { deltaPosition, absPosition } = this.state;

        return (
            <div>
                <input
                    type="text"
                    ref={this.setTextInputRef}
                />
                <motion.div
                    className={this.state.InputClass}
                    whileHover={{ scale: 1.2 , background: this.state.InputBg }}
                    whileTap={{ scale: 0.8 }}
                    initial={{ scale: 1, opacity: 1 }}
                    animate={{ scale: 1.1, opacity: .5, background: this.state.InputBg }}
                    transition={{ yoyo: this.state.yoval, duration: 1 ,  background: this.state.InputBg}}
                    >
                    <input className='bg-green-400'
                        type="button"
                        value="Focus the text input"
                        onClick={this.focusTextInput.bind(this)}
                    />
                    <div >  a = {this.state.QNum1.a}
        b = {this.state.QNum1.b}
        c = {this.state.QNum1.c}
        d = {this.state.QNum1.d}

                    </div>
                    <div > da = {this.state.Drag1.a}
         db = {this.state.Drag1.b}
         dc = {this.state.Drag1.c}
         dd = {this.state.Drag1.d}
         </div>
                </motion.div>

                <Draggable 
                    onDrag={this.handleDrag}
                    onStop={this.handleStop}
        

                >

                    <div className="drag-box w-1/5  bg-blue-600"   ref={this.setDrag1Ref} >
                        <div>Tacking Delta</div>
                        <div>x: {deltaPosition.x.toFixed(0)}, y: {deltaPosition.y.toFixed(0)}</div>
                        <div>Abs-x: {absPosition.x.toFixed(0)}, Abs-y: {absPosition.y.toFixed(0)}</div>

                    </div>

                </Draggable>




            </div>


        );
    }
}