import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import TestGetDimensions from '../components/TestGetDimensions'
import TestLayout from '../components/TestLayout'

function TestMissingNumbers(props) {

    const [windowDimensions, setWindowDimensions] = useState(
        { w:0, h:0 }
    );
   var h=0
   var w=0
 //  es6Function = (value) => {
 //   console.log(value)
 // }

 // simplifiedFunction (value) {
 //   console.log(value)
 // }

//  <Child
//  es6Function = {this.es6Function}
//  simplifiedFunction = {this.simplifiedFunction} 
///>

   function SetWindowDimensions(h,w) {

      setWindowDimensions( { h:h, w:w } )

   }

     
  
    return (
        <div>
              <TestGetDimensions SetWindowDimensions={SetWindowDimensions} />
             {windowDimensions.w}
        </div>
    )
}

TestMissingNumbers.propTypes = {

}

export default TestMissingNumbers

