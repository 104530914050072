import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import bImg from '../images/qp.png'
import icap from '../images/icap.png'
import igk from '../images/igk.png'
import ugk from '../images/ugk.png'
import ucap from '../images/ucap.png'
import wcap from '../images/wcap.png'
import wcur from '../images/wcur.png'
import madd from '../images/madd.png'
import msub from '../images/msub.png'
import mdiv from '../images/mdiv.png'
import mmult from '../images/mmult.png'
import mface from '../images/mface.png'
import esp from '../images/esp.png'
import eant from '../images/eant.png'
import HRC from '../images/hrc.png'
import HCl from '../images/hcl.png'
import HMn from '../images/hmn.png'
import HOp from '../images/hop.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Slide from 'react-reveal/Slide'
import Card from '../components/Card'
import Btn from '../components/Button'
import CookieConsent from '../components/Cookie'


function QuizMain() {
  var qSubjTopics = require('../qBank/qSubjTopics.js');
  var qUSA=qSubjTopics["USA"];
  var qWORLD=qSubjTopics["WORLD"];
  var qINDIA=qSubjTopics["INDIA"];
  var qCLASS2_MATHS=qSubjTopics["CLASS2_MATHS"];
  var qCLASS2_HINDI=qSubjTopics["CLASS2_HINDI"];
  var qCLASS2_ENGLISH=qSubjTopics["CLASS2_ENGLISH"];

  var uSfx='/quiz/portal/';
 // alert(qSubjects);
//  alert(qTopics["USA"])

var QuizList= <div id='quiz' className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center text-xl  lg:text-2xl m-4 rounded-lg shadow-2xl opacity-70  bg-white text-white ">
        <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg;grid-cols-3 items-center text-xl  lg:text-2xl p-4 m-4 sm:m-1 rounded-lg shadow-2xl   bg-green-600 text-white ">
        {qCLASS2_MATHS.map((val,ind) => (      
            <div className="inline-flex space-x-4 w-full px-4" >
                  <div className='flex-2 rounded '>
                    <Link to={ind===0?uSfx+"class2_maths/1":uSfx+"class2_maths/"+val}> {ind===0?<div className='text-green-400 bg-white p-2 shadow-2xl font-bold font-potta-one rounded-lg'>{val.toUpperCase()}</div>:val} </Link> 
                  </div>          
            </div>
        ))}</div> 

        <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg;grid-cols-3 items-center text-xl  lg:text-2xl p-4 m-4 rounded-lg shadow-2xl   bg-yellow-600 text-white ">
        {qCLASS2_ENGLISH.map((val,ind) => (      
            <div className="inline-flex space-x-4 w-full px-4" >
                  <div className='flex-2 rounded '>
                    <Link to={ind===0?uSfx+"class2_english/1":uSfx+"class2_english/"+val}> {ind===0?<div className='text-yellow-400 bg-white p-2 shadow-2xl font-bold font-potta-one rounded-lg'>{val.toUpperCase()}</div>:val} </Link> 
                  </div>          
            </div>
        ))}</div> 

        <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg;grid-cols-3 items-center text-xl  lg:text-2xl p-4 m-4 rounded-lg shadow-2xl   bg-red-600 text-white ">
        {qCLASS2_HINDI.map((val,ind) => (      
            <div className="inline-flex space-x-4  w-full px-4" >
                  <div className='flex-2 rounded '>
                    <Link to={ind===0?uSfx+"class2_hindi/1":uSfx+"class2_hindi/"+val}> {ind===0?<div className='text-red-400 bg-white p-2 shadow-2xl font-bold font-potta-one rounded-lg'>{val.toUpperCase()}</div>:val} </Link> 
                  </div>          
            </div>
        ))}</div> 

        <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg;grid-cols-3 items-center text-xl  lg:text-2xl p-4 m-4 rounded-lg shadow-2xl   bg-blue-600 text-white ">
        {qWORLD.map((val,ind) => (      
            <div className="inline-flex space-x-4  w-full px-4" >
                  <div className='flex-2 rounded '>
                    <Link to={ind===0?uSfx+"world/1":uSfx+"world/"+val}> {ind===0?<div className='text-blue-400 bg-white p-2 shadow-2xl font-bold font-potta-one rounded-lg'>{val.toUpperCase()}</div>:val} </Link> 
                  </div>          
            </div>
        ))}</div> 

        <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg;grid-cols-3 items-center text-xl  lg:text-2xl p-4 m-4 rounded-lg shadow-2xl   bg-yellow-400 text-white ">
        {qUSA.map((val,ind) => (      
            <div className="inline-flex space-x-4  w-full px-4" >
                  <div className='flex-2 rounded '>
                    <Link to={ind===0?uSfx+"usa/1":uSfx+"usa/"+val}> {ind===0?<div className='text-yellow-600 bg-white p-2 shadow-2xl font-bold font-potta-one rounded-lg'>{val.toUpperCase()}</div>:val} </Link> 
                  </div>          
            </div>
        ))}</div> 

        <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg;grid-cols-3 items-center text-xl  lg:text-2xl p-4 m-4 rounded-lg shadow-2xl   bg-green-400 text-white ">
        {qINDIA.map((val,ind) => (      
            <div className="inline-flex space-x-4  w-full px-4" >
                  <div className='flex-2 rounded '>
                    <Link to={ind===0?uSfx+"india/1":uSfx+"india/"+val}> {ind===0?<div className='text-green-600 bg-white p-2 shadow-2xl font-bold font-potta-one rounded-lg'>{val.toUpperCase()}</div>:val} </Link> 
                  </div>          
            </div>
        ))}</div> 

</div>


  const [Stage, setStage] = useState('START')
  const [Msg, setMsg] = useState('Learning is always fun!')
  const [Welcome, setWelcome] = useState(<Fade top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Quiz Portal</h2></Fade>)
  const [Category, setCategory] = useState('')

  const [Set, setSet] = useState(1)
  const [SetContent, setSetContent]= useState(<div className='grid grid-cols-1  m-2  justify-items-center'><Fade duration={3000} >
                                              <img src={bImg} alt="test" className="rounded-lg shadow-2xl hover:opacity-70  "></img></Fade></div>)
  const [SetD,setSetD]=useState('')


  function SetList() {
            setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Subjects & Topics</h2></Slide>)
            setSetContent(<Fade duration={3000}>
                {QuizList}
            </Fade> ) 
  }


  function SetStage() {

    switch (Set) {
      case 0: 
           setSetContent(<div className='grid grid-cols-1 justify-items-center' ><Fade duration={3000}  >
           <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
            <Link to="/quiz/portal/"><img src={bImg} alt="India Capitals" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link> 
            </div>
            </Fade> </div> ) 
            setSetD('')
           setCategory('')
           break;
      case 1: 
            setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 shadow-2xl bg-opacity-40 bg-gray-100'>
              <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
                  <Link to="/quiz/portal/india/Capitals"><img src={icap} alt="India Capitals"className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
              </div>
              <div className='m-4 shadow-2xl  bg-opacity-40 justify-items-center'> 
                  <Link to="/quiz/portal/india/GK"><img src={igk} alt="India GK" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
              </div>
            </div> </Fade> ) 
           setSetD('quiz tests')
           setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">INDIA</h2></Slide>)
         break;
      case 2: 
          setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2  bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
          <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
              <Link to="/quiz/portal/usa/Capitals"><img src={ucap} alt="India Capitals" className="rounded-lg shadow-2xl hover:opacity-70 "></img></Link>
          </div>
          <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
              <Link to="/quiz/portal/usa/GK"><img src={ugk} alt="India GK" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
          </div>
          </div> </Fade> ) 
          setSetD('quiz tests')
          setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">USA</h2></Slide>)
        break;
      case 3: 
          setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2   bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
          <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
              <Link to="/quiz/portal/world/Capitals"><img src={wcap} alt="India Capitals" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
          </div>
          <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
              <Link to="/quiz/portal/world/Currencies"><img src={wcur} alt="India GK" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
          </div>
        </div> </Fade> ) 
        setSetD('quiz tests')
        setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">WORLD</h2></Slide>)
        break;
      case 4: 
        setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2   bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
        <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'>  
            <Link to="/quiz/portal/CLASS2_MATHS/Face_place_value"><img src={mface} alt="" className="rounded-lg shadow-2xl hover:opacity-70 "></img></Link>
        </div>
        <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
            <Link to="/quiz/portal/CLASS2_MATHS/Addition"><img src={madd} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
        </div>
        <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
        <Link to="/quiz/portal/CLASS2_MATHS/Subtraction"><img src={msub} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
        </div>
        <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
        <Link to="/quiz/portal/CLASS2_MATHS/Division"><img src={mdiv} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
        </div>
        <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
        <Link to="/quiz/portal/CLASS2_MATHS/Multiplication"><img src={mmult} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
        </div>
      </div> </Fade> ) 
      setSetD('quiz tests')
      setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">CLASS2 MATHS</h2></Slide>)
      break;    
  case 5: 
      setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2   bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
      <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
          <Link to="/quiz/portal/CLASS2_ENGLISH/Spelling"><img src={esp} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
      </div>
      <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
          <Link to="/quiz/portal/CLASS2_ENGLISH/Antonyms"><img src={eant} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
      </div>
    </div> </Fade> ) 
    setSetD('quiz tests')
    setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">CLASS2 ENGLISH</h2></Slide>)
    break;     
  case 6: 
      setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2   bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
      <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
          <Link to="/quiz/portal/CLASS2_HINDI/Rainbow+Colors"><img src={HRC} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
      </div>
      <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
          <Link to="/quiz/portal/CLASS2_HINDI/Colors"><img src={HCl} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
      </div>
      <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
      <Link to="/quiz/portal/CLASS2_HINDI/Meanings"><img src={HMn} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
      </div>
      <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
        <Link to="/quiz/portal/CLASS2_HINDI/Opposites"><img src={HOp} alt="" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></Link>
      </div>
    </div> </Fade> ) 
    setSetD('quiz tests')
    setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">CLASS2 HINDI</h2></Slide>)
    break;       
  default:
           setSetContent('NO CONTENT!')  
           setSetD('NO CONTENT')
           setCategory('')
           break
 
   }
    setSet(prevSet=>prevSet>=6?0:prevSet+1)
   // alert(Set)
    setStage(Stage == 'START' ? 'CLOSE' : 'START')
    setMsg(Stage == 'START' ? '' : <Fade bottom duration={5000}>Learning is always fun!</Fade>)
    setWelcome(Stage == 'START' ? <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Quiz Portal</h2></Slide> : <Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Quiz Portal</h2></Flip>)

  }

  return (

    <div className="min-h-screen items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >
      <Card Type='Title' Title={Welcome} />




      <div id='arithmetic' className=" m-2 md:m-4 lg:m-6  text-indigo-200 absolute-center py-4 ">
        <div className=" m-4 grid  grid-cols-1  justify-items-center items-center   "   >
          <div className=" text-center  lg:px-58 rounded-2xl p-4 ">
            {Category}
            <div className="inline-flex space-x-4 shadow-2xl" >
              <div className='flex-2 rounded-l-full  bg-white text-white'> lt </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Up" ></Btn>
              </div>
              <Link to='/md=1'><div className="flex-2  ..."  >
                <Btn Card='NumBtn' Content='Home' />
              </div></Link>
              <div className="flex-1 ..." onClick={() => SetList()} >
                <Btn Card='NumBtn' Content="List" />
              </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Dn" />
              </div>
              <div className='flex-2 rounded-r-full  bg-white text-white'>lt</div>
            </div>
            <Slide duration={3000}><div className='font-extrabold text-gray-50 text-xl lg:text=2xl'>{SetD}</div></Slide>

          </div>
          <div className=" text-center shadow-2xl bg-opacity-70" onClick={() => SetStage()}>
            {SetContent}
          </div>
        </div>
      </div>





      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>

      <CookieConsent />
     
    </div>


  );
}

export default QuizMain;