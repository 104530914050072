import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import bImg from '../images/arithmetic-11.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Slide from 'react-reveal/Slide'
import Card from '../components/Card'
import Btn from '../components/Button'
import CookieConsent from '../components/Cookie'
import fvpv1 from '../images/fvpv-1-600.jpg'
import fvpv2 from '../images/fvpv-2-600.jpg'


function NumQuest() {
  const [Stage, setStage] = useState('START')
  const [Msg, setMsg] = useState('Learning is always fun!')
  const [Welcome, setWelcome] = useState(<Fade top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Num Quest</h2></Fade>)
  const [Category, setCategory] = useState(<Fade top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold"></h2></Fade>)

  const [Set, setSet] = useState(1)
  const [SetContent, setSetContent]= useState(<div className='grid grid-cols-1 justify-items-center flex m-2'><Fade duration={3000} >
                                              <img src={bImg} alt="test" class="w-full rounded-lg shadow=2xl hover:opacity-90 "></img></Fade></div>)
  const [SetD,setSetD]=useState('')

  function SetStage() {

    switch (Set) {
      case 0: 
           setSetContent(<div className='grid grid-cols-1  flex m-2 justify-items-center' ><Fade duration={3000}  >
           <img src={bImg} alt="test" class="w-full rounded-lg shadow=2xl hover:opacity-90 " ></img></Fade></div>)
           setSetD('')
           setCategory(<Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold"></h2></Flip>)
           break;
      case 1: 
           setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 flex  bg-gray-100  bg-opacity-40 '>
           <Link to='/numquest/arithmetic/Addition'><Card Type='NumQuest' CardID='1' Title='Addition' Content='X + Y' bTitle='ADD' bContent=' x + y 3 + 5 + 7 = ? 0 x + y + z ( 3 + 4 ) + 9 5 + 7 = ? x + y + 3 + 5 + 7 = ? 0' Border='rounded-tr-3xl  rounded-bl-3xl' /></Link>
           <Link to='/numquest/arithmetic/Subtraction'><Card Type='NumQuest' CardID='2' Title='Subtraction' Content='X - Y' bTitle='SUBTRACT' bContent=' z - x 3 = 5 (4 -6) y =? a - b - c ? 456 - 32 23 - 34 - x 2 + 3 - 3' Border='rounded-tr-3xl  rounded-bl-3xl' /></Link>
           <Link to='/numquest/arithmetic/Multiplication'><Card Type='NumQuest' CardID='3' Title='Multiplication' Content='X * Y ' bTitle='MULTIPLY' bContent=' x *  y 3 X  5 * 7 = ? 0 x * y *  0  676 * 34 X * Y * Z' Border='rounded-tr-3xl  rounded-bl-3xl' /></Link>
           <Link to='/numquest/arithmetic/Division'><Card Type='NumQuest' CardID='4' Title='Division' Content='X / Y' bTitle='DIVIDE' bContent=' x / y 3 / 5  7 = ? 56 / 45 7.00 a / b / c 0 / 0 32.0 14.5 367/34 t / r = ? q' Border='rounded-tr-3xl  rounded-bl-3xl' /></Link>
         </div> </Fade> ) 
             setSetD('Basic Operations')
             setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Arithmetics</h2></Slide>)
         break;
      case 2: 
         setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 flex  bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
         <div className='sm:m-4 md:m-5 lg:m-10'> 
         <a href='https://youtu.be/N9RIwHl618c' target="_blank"  rel="noreferrer noopener" > <img src={fvpv1} alt="test" className="w-full rounded-lg shadow=2xl hover:opacity-90 " ></img></a>
         </div>
         <div className='sm:m-4 md:m-5 lg:m-10'> 
         <a href='https://youtu.be/lkNAF979tjU' target="_blank" rel="noreferrer noopener"> <img src={fvpv2} alt="test" className="w-full rounded-lg shadow=2xl hover:opacity-90 " ></img></a>
         </div>
         </div> </Fade> ) 
           setSetD('Tutorial Videos')
           setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Mathematics</h2></Slide>)
         break;
      default:
           setSetContent('NO CONTENT!')  
           setSetD('NO CONTENT')
           setCategory(<Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold"></h2></Flip>)
           break
 
   }
    setSet(prevSet=>prevSet>=2?0:prevSet+1)
   // alert(Set)
    setStage(Stage == 'START' ? 'CLOSE' : 'START')
    setMsg(Stage == 'START' ? '' : <Fade bottom duration={5000}>Learning is always fun!</Fade>)
    setWelcome(Stage == 'START' ? <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Num Quest</h2></Slide> : <Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Num Quest</h2></Flip>)

  }

  return (

    <div className="min-h-screen items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >
      <Card Type='Title' Title={Welcome} />




      <div id='arithmetic' className=" m-2 md:m-4 lg:m-6  text-indigo-200 absolute-center py-4 ">
        <div className=" m-4 grid  grid-cols-1  flex justify-items-center items-center   "   >
          <div className=" text-center  lg:px-58 rounded-2xl p-4 ">
            {Category}
            <div class="inline-flex space-x-4 shadow-2xl" >
              <div className='flex-2 rounded-l-full  bg-white text-white'> lt </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Up" ></Btn>
              </div>
              <Link to='/md=1'><div className="flex-2  ..."  >
                <Btn Card='NumBtn' Content='Home' />
              </div></Link>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="List" />
              </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Dn" />
              </div>
              <div className='flex-2 rounded-r-full  bg-white text-white'>lt</div>
            </div>
            <Slide duration={3000}><div className='font-extrabold text-gray-50 text-xl lg:text=2xl'>{SetD}</div></Slide>

          </div>
          <div className=" w-full  text-center  shadow-2xl bg-opacity-70" onClick={() => SetStage()}>
            {SetContent}
          </div>
        </div>
      </div>





      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>

      <CookieConsent />
     
    </div>


  );
}

export default NumQuest;