import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Select from 'react-select';
import Animated from 'react-select/animated' 


const customStyles = {

      borderBottom: '1px dotted pink',
      padding: 20,
    }


export default function Modal({ open, children, onClose, setGo, options, levels, onOptChange, onLvlChange, ToLink }) {
    const [Opt, setOpt] = useState('')
    const [Lvl, setLvl] = useState('')

    if (!open) return null


    function OptChange(e){
   //     alert(e.value)
        setOpt(e.value)
        onOptChange(e.value)
        
    }
    function LvlChange(e){
     //   alert(e.value)
     setLvl(e.value)
     onLvlChange(e.value)
         
    }

    function Go(){
  //      alert('here')
        if (Opt=='' && Lvl == '') {
            setGo()
        } 
        else {
            onClose()
        }
        
    }


    return (
        <>
            <div >
                <div class="absolute z-10 inset-x-0  shadow-2xl w-full opacity-95">

                    <div class=" absolute  z-12 inset-x-0  grid grid-cols-1 lg:grid-cols-2 justify-items-center shadow-xl
                            bg-green-900 mr-6 ml-6 md:mr-8 md:ml-8 lg:mr-10 lg:ml-10 p-2 rounded-2xl  ">
                        <div className=" grid-cols-1 flex justify-items-center items-center   "   >

                            {children}
                        </div>
                        <div className='lg:w-full w-4/5    border rounded-xl  justify-items-center items-center grid grid-cols-1' >
                             <div className='text-xl md:text-2xl lg:text-3xl text-yellow-300'>Try Another Skill / Level?</div>
                            <div className='w-4/5 grid lg:grid-cols-2' ><span className='p-2'>
                            <label for="Subject" className='font-bold text-white'>Different Subject?</label> 
                            <Select  label="Subject" 
                                defaultValue={options[0]}
                 //               isClearable
                                isSearchable
                                components={Animated}
                                name="ArithOpts"
                                options={options}
                                onChange={OptChange}
                                styles={ customStyles }
                            /></span>
                            <span className='p-2 mb-2'>
                            <label for="Topic" className='font-bold text-white'>Different Topic?</label> 
                            <Select  label="Topic" 
                                defaultValue={levels[0]}
               //                 isClearable
                                isSearchable
                                components={Animated}
                                name="LevelOpts"
                                options={levels}
                                onChange={LvlChange}
                            />
                            </span></div>
                            <div className='mb-2'>
                            <button className='far fa-times-circle bg-red-700 text-white  m-2 text-xl p-2 rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={onClose} > </button>
                            <button className='far fa-caret-square-right bg-blue-700 text-white  text-xl  px-4 py-2 ml-2 mr-2rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl cursor-pointer' onClick={Go} >
                             <span className='pl-2'>Go</span></button>
                             <Link to={ToLink}><button  className='fas fa-home  bg-purple-700 text-white  m-2 text-xl p-2  rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl'  ></button></Link>
                        </div>
                        </div>
                    </div>
              
                </div>
            </div>
        </>

    )
}

