import React from 'react';
import ReactDOM from 'react-dom'
import { motion, useMotionValue, useTransform, useSpring, useMotionTemplate } from "framer-motion"
import Btn from '../components/Button'
import { Link } from 'react-router-dom'
import Draggable from 'react-draggable'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Bounce from 'react-reveal/Bounce'
import HeadShake from 'react-reveal/HeadShake'
import CookieConsent from '../components/Cookie'


export default class MissingNumbers extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            NumVal: 0,
            Xpos: 0,
            activeDrags: 0,
            deltaPosition: {
                x: 0, y: 0
            },
            absPosition: {
                x: 0, y: 0
            },
            numBoxPos: {
                a: 0, b: 0, c: 0, d: 0
            },
            width: 0,
            height: 0,
            Stage: 'STARTED',
            dwidth: window.innerWidth,
            dheight: window.innerHeight,
            PortLand: '',
            DType: '',
            PLayout: '',
            LLayout: '',
            QNum1Pos: {
                a: 0, b: 0, c: 0, d: 0
            },
            QNum1W: 0,
            QNum2Pos: {
                a: 0, b: 0, c: 0, d: 0
            },

        }

        this.QNum1Ref = React.createRef();
        this.displayDimensions = this.displayDimensions.bind(this);
        this.textInput = null;

        this.setTextInputRef = element => {
            this.textInput = element;
            this.setState({ QNum1W: element.clientWidth })
            alert(element.clientWidth)
            if (element.clientWidth > 100) {
                alert('greth than 100')
            }
        };




    }



    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            },
            Drag1: {
                a: this.drag1.offsetLeft,
                b: this.drag1.offsetLeft + this.drag1.clientWidth,
                c: this.drag1.offsetTop,
                d: this.drag1.offsetTop + this.drag1.clientHeight,
            },



        });
        if (this.state.deltaPosition.x > 700 && this.state.deltaPosition.x < 800) {
            this.setState({ yoval: Infinity, InputBg: "green", InputClass: 'm-4 alig-center bg-green-600 text-yellow-300 text-2xl' })
        } else {
            this.setState({ yoval: 1, InputBg: "red", InputClass: 'm-4 alig-center bg-blue-400 text-white text-xl' })
        }
    }

    handleStop = (e, ui) => {
        const { x, y } = this.state.absPosition;
        this.setState({
            absPosition: {
                x: ui.x,
                y: ui.y,
            },
            InputClass: 'bg-indigo-400',
        });

    }


    displayDimensions() {
        console.log(this.QNum1Ref)
        alert(this.QNum1Ref)
    }

    QNum1Refs = element => {
        // this.textInput = element;
        //    var a = element.offsetLeft;
        var w = element.clientWidth;
        var h = element.clientHeight;

        console.log(element)
        this.setState({
            QNum1W: element.clientWidth,
        })
        alert('Num1 Width' + element.clientWidth + ' Num1 Height ' + element.clientHeight + ' Num1 Offset Top ' + element.offsetTop + ' Num1 Offset Left ' + element.offsetLeft
            + ' Num1 Offset Height ' + element.offsetHeight + ' Num1 Offset Width ' + element.offsetWidth)
    };

    QNum2Refs = element => {
        // this.textInput = element;
        console.log(element)
        this.setState({
            QNum2Pos: {
                a: element.offsetLeft,
                b: element.offsetLeft + element.clientWidth,
            }
        })
        alert('Num2 Width' + element.clientWidth + ' Num2 Height ' + element.clientHeight + ' Num2 Offset Top ' + element.offsetTop + ' Num2 Offset Left ' + element.offsetLeft
            + ' Num2 Offset Height ' + element.offsetHeight + ' Num2 Offset Width ' + element.offsetWidth)
    };


    DisplayKeys(s) {
        const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const keysc = ['CLR', 'ENTR']
        var keyb = '';
        var keyc = '';
        if (this.state.Stage == 'START') {
            keyb = <div className=" grid grid-cols-1 rounded-md text-2xl md:text-3xl lg:text-3xl ">
                <div className=" p-2 rounded-md text-left ">
                    <Fade top>
                        <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
                            Choose the desired Skill / Level and You will be presented with 10 Random Questions!
              </div></Fade>
                </div>
            </div>
        } else {
            keyb = keys.map(key => (
                <div className='   text-gray-200  hover:text-white bg-teal-600 flex items-center
                 flex-grow justify-center  m-2'>
                    <div

                    >

                        <motion.button className='m-2 focus:outline-none  '
                            onClick={() => this.Input(key)}
                            drag
                            dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                            dragElastic={1}
                            whileDrag={{ scale: s }}
                            whileHover={{ scale: s }}
                            animate={{ scale: s }}
                            onDrag={this.handleDrag}
                            onStop={this.handleStop}

                        >{key}</motion.button>

                    </div>
                </div>
            ))

            keyc = keysc.map(key => (
                <div className='   text-gray-200  hover:text-white bg-teal-600 flex items-center
                 flex-grow  justify-items-stretch justify-self-stretch m-2'>
                    <div

                    >

                        <motion.button className='m-2 focus:outline-none  '
                            onClick={() => this.Input(key)}
                            drag
                            dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                            dragElastic={1}
                            whileDrag={{ scale: s }}
                            whileHover={{ scale: s }}
                            animate={{ scale: s }}


                        >{key}</motion.button>

                    </div>
                </div>
            ))

        }
        return (
            <div>
                <div className='flex grid-flow-col gap-4 flex-wrap place-self-center' >
                    {keyb}
                </div>
                <div className='flex grid-flow-col gap-4 flex-wrap place-self-center' >
                    {keyc}
                </div>
            </div>
        );
    }


    Input(k) {

        console.log('Button clicked' + k);


        return (
            <div>
                <h1>Hello, world!</h1>
                <h2>It is .</h2>
            </div>
        );
    }

    BackXpos() {
        this.setState({ Xpos: (this.state.Xpos == 0 ? this.state.Xpos : this.state.Xpos - 10) })
    }

    ForwardXpos() {
        this.setState({ Xpos: (this.state.Xpos == 300 ? this.state.Xpos : this.state.Xpos + 10) })
    }

    handleEvent = (e, data) => {
        console.log('Event Type', e.type);
        console.log(e, data);
    }


    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
    }

    handleStop = (e, ui) => {
        const { x, y } = this.state.absPosition;
        this.setState({
            absPosition: {
                x: ui.x,
                y: ui.y,
            }
        });
    }

    updateDimensions = () => {
        this.setState({ dwidth: window.innerWidth, dheight: window.innerHeight });
    };

    componentDidMount() {

        //      window.addEventListener('DOMContentLoaded', (event) => {
        //         console.log('DOM fully loaded and parsed');
        //     });

        this.setState({ dwidth: window.innerWidth, dheight: window.innerHeight });
        window.addEventListener('resize', this.updateDimensions);
        //    window.addEventListener('DOMContentLoaded', this.QNum1Ref);
        //    window.addEventListener('DOMContentLoaded', this.QNum2Ref);


        var DType = ''
        var PortLand = ''
        var MScale = 1
        var L1Scale = 1
        var M1Scale = 1
        var R1Scale = 1
        var R2Scale = 1
        var MLayout1 = ''
        var mode = ['A', 'D', 'D']
        var modet = mode.map(key => (<div>{key}</div>))
        var lvl = ['L', 'E', 'V', 'E', 'L']
        var lvlt = lvl.map(key => (<div>{key}</div>))
        //    this.QNum1Ref()
        //     this.QNum2Ref()


        if (this.state.dwidth > this.state.dheight) {
            this.setState({ PortLand: 'Landscape' })
            PortLand = 'Landscape'
        } else {
            this.setState({ PortLand: 'Portrait' })
            PortLand = 'Portrait'
        }

        if ((this.state.dwidth <= 330 && this.state.dheight <= 600) ||
            (this.state.dheight <= 330 && this.state.dwidth <= 600)) {
            this.setState({ Dtype: 'XS' })
            this.setState({ R1Scale: 1 })
            DType = 'XS'
            L1Scale = .8
            M1Scale = .8
            R1Scale = 1.4
            R2Scale = 2
        } else if (((this.state.dwidth > 330 && this.state.dwidth <= 400) && (this.state.dheight > 600 & this.state.dheight <= 850)) ||
            ((this.state.dheight > 330 && this.state.dheight <= 400) && (this.state.dwidth > 600 & this.state.dwidth <= 850))) {
            this.setState({ Dtype: 'SM' })
            this.setState({ R1Scale: 1 })
            DType = 'SM'
            L1Scale = 1
            M1Scale = .8
            R1Scale = 1.4
            R2Scale = 2
        } else if (((this.state.dwidth > 400 && this.state.dwidth <= 450) && (this.state.dheight > 700 & this.state.dheight <= 850)) ||
            ((this.state.dheight > 400 && this.state.dheight <= 450) && (this.state.dwidth > 700 & this.state.dwidth <= 850))) {
            this.setState({ Dtype: 'MD' })
            this.setState({ R1Scale: 1 })
            DType = 'MD'
            L1Scale = 1
            M1Scale = .8
            R1Scale = 1.4
            R2Scale = 2
        } else if (((this.state.dwidth > 500 && this.state.dwidth <= 700) && (this.state.dheight > 700 & this.state.dheight <= 1000)) ||
            ((this.state.dheight > 500 && this.state.dheight <= 700) && (this.state.dwidth > 700 & this.state.dwidth <= 1000))) {
            this.setState({ Dtype: 'L1' })
            this.setState({ R1Scale: 2 })
            DType = 'L1'
            L1Scale = 1
            M1Scale = 1.25
            R1Scale = 2
            R2Scale = 3
        } else if (((this.state.dwidth > 700 && this.state.dwidth <= 1000) && (this.state.dheight > 1000 & this.state.dheight <= 1200)) ||
            ((this.state.dheight > 700 && this.state.dheight <= 1000) && (this.state.dwidth > 1000 & this.state.dwidth <= 1200))) {
            this.setState({ Dtype: 'L2' })
            this.setState({ R1Scale: 2.5 })
            DType = 'L2'
            L1Scale = 1.5
            M1Scale = 1.75
            R1Scale = 3
            R2Scale = 4
        } else if ((this.state.dwidth > 1000 && this.state.dheight > 1200) ||
            (this.state.dheight > 1000 && this.state.dwidth > 1200)) {
            this.setState({ Dtype: 'L3' })
            this.setState({ R1Scale: 3 })
            DType = 'L3'
            M1Scale = 2
            L1Scale = 2
            R1Scale = 4
            R2Scale = 5
        } else {

            this.setState({ Dtype: 'RSP' })
            this.setState({ R1Scale: 3 })
            DType = 'RSP'
            M1Scale = 2
            L1Scale = 2
            R1Scale = 4
            R2Scale = 5

        }


        const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        var keyset1 = ''
        var keyset2 = ''
        const keysb = [<i class="fas text-red-700 fa-undo"></i>,
        <i class="fas  text-green-800 fa-check"></i>,
        <i class="fas  text-indigo-700  fa-arrow-right"></i>,
        ]

        if (DType == 'L3' || DType == 'L2' || DType == 'RSP') {

            keyset1 = keys.map(key => (
                <div className='   text-gray-200  hover:text-white bg-teal-600 flex
       m-8'>

                    <motion.button className='focus:outline-none  '
                        onClick={() => this.Input(key)}
                        drag
                        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                        dragElastic={1}
                        whileDrag={{ scale: R2Scale, color: 'yellow' }}
                        whileHover={{ scale: R2Scale, color: 'red' }}
                        animate={{ scale: R1Scale }}

                    >{key}</motion.button>
                </div>
            ))

            keyset2 = keysb.map(key => (
                <div className=' text-gray-200  hover:text-white bg-teal-600 flex items-center
         flex-grow   m-8'>

                    <motion.button className='focus:outline-none text-xs  shadow-2xl'
                        whileHover={{ scale: R1Scale, color: 'red' }}
                        onClick={() => this.Input(key)}
                        animate={{ scale: R2Scale }}
                    >{key}</motion.button>
                </div>
            ))


        } else {
            keyset1 = keys.map(key => (
                <div className='   text-gray-200  hover:text-white bg-teal-600 flex
       m-2'>

                    <motion.button className='focus:outline-none  '
                        onClick={() => this.Input(key)}
                        drag
                        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                        dragElastic={1}
                        whileDrag={{ scale: R2Scale, color: 'yellow' }}
                        whileHover={{ scale: R2Scale, color: 'red' }}
                        animate={{ scale: R1Scale }}

                    >{key}</motion.button>
                </div>
            ))

            keyset2 = keysb.map(key => (
                <div className=' text-gray-200  hover:text-white bg-teal-600 flex items-center
         flex-grow   m-3'>

                    <motion.button className='focus:outline-none text-xs '
                        whileHover={{ scale: R1Scale, color: 'red' }}
                        onClick={() => this.Input(key)}
                        animate={{ scale: R2Scale }}
                    >{key}</motion.button>
                </div>
            ))


        }


        if (DType == 'L3' || DType == 'L2' || DType == 'L1' || DType == 'RSP') {

            MLayout1 = <div className='grid grid-cols-1 m-4 place-self-center h-full w-full'>
                <div className=' grid grid-cols-2 w-full h-3/4 place-self-center'>
                    <div className='grid grid-cols-1 shade-2xl h-2/3 w-5/6  rounded-full bg-opacity-90 shadow-2xl  place-self-center'>
                        <motion.div className='text-white  place-self-center ' animate={{ scale: M1Scale }}> 368794 </motion.div>
                    </div>
                    <div className='grid grid-cols-1 w-full h-full place-self-center'>
                        <motion.div id='QNum1' className=' text-green-700  border-b-2  border-white px-4 py-4 place-self-center shadow-2xl' animate={{ scale: M1Scale }}> 56765 </motion.div>
                        <motion.div className=' place-self-center text-white  px-4 py-2' animate={{ scale: L1Scale }}> + </motion.div>
                        <motion.div id='QNum2' className=' text-indigo-700  border-b-2  border-white px-4 py-4 place-self-center shadow-2xl' animate={{ scale: M1Scale }}> 45645  </motion.div>
                    </div>
                    <div
                        type="text"
                        ref={this.setTextInputRef}

                    >  {this.Qa} </div>
                    <input
                        type="text"
                        ref={this.QNum1ref}
                    /> {this.state.QNum1W}

                </div>
            </div>
        } else {



            MLayout1 = <div className='m-auto place-self-center '>
                <div className=' grid grid-cols-2 w-full place-self-center'>
                    <div className='grid grid-cols-1 shade-2xl  rounded-full bg-opacity-90 shadow-2xl h-3/4 mx-4 my-2 place-self-center'>
                        <motion.div className='text-white  place-self-center ' animate={{ scale: M1Scale }}> 368794 </motion.div>
                    </div>
                    <div className='grid grid-cols-1 w-full place-self-center'>
                        <motion.div id='QNum1' className=' text-green-700  border-b-2  border-white px-4 py-4 place-self-center shadow-2xl' animate={{ scale: M1Scale }}> 56765 </motion.div>
                        <motion.div className=' place-self-center text-white  px-4 py-2' animate={{ scale: L1Scale }}> + </motion.div>
                        <motion.div id='QNum2' className=' text-indigo-700  border-b-2  border-white px-4 py-4 place-self-center shadow-2xl' animate={{ scale: M1Scale }}> 45645  </motion.div>
                    </div>
                </div>
            </div>


        }


        var PMLayout1 = <div className='grid grid-cols-1 m-4 place-self-center h-full w-full'>
            <div className=' grid grid-cols-1 w-full h-3/4 place-self-center'>
                <div className='grid grid-cols-1 shade-2xl h-2/3 w-5/6  rounded-full bg-opacity-90 shadow-2xl  place-self-center'>
                    <motion.div className='text-white  place-self-center ' animate={{ scale: M1Scale }}> 368794 </motion.div>
                </div>
                <div className='grid grid-cols-3 w-full h-full place-self-center'>
                    <motion.div id='QNum1' ref={this.QNum1Ref} className=' text-green-700  border-b-2  border-white px-4 py-4 place-self-center shadow-2xl' animate={{ scale: M1Scale }}> 56765 </motion.div>
                    <motion.div className=' place-self-center text-white  px-4 py-2' animate={{ scale: L1Scale }}> + </motion.div>
                    <motion.div id='QNum2' className=' text-indigo-700  border-b-2  border-white px-4 py-4 place-self-center shadow-2xl' animate={{ scale: M1Scale }}> 45645  </motion.div>
                </div>
            </div>
        </div>



        var RLayout1 = <div className='grid w-full  m-4  h-5/6 col-start-10 col-end-13  place-self-center'><div className='flex grid-flow-col  flex-wrap place-self-center' >
            {keyset1}
        </div>
            <div className='flex grid-flow-col  flex-wrap place-self-center' >
                {keyset2}
            </div>
        </div>

        alert('Device ' + DType)
        this.setState({
            PLayout:
                <div className="relative h-full " >
                    <div className=" grid  grid-cols-1  gap-2 h-full   bg-gray-400  mx-auto bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ">

                        <div className='w-full rounded-lg  '>

                            <motion.div className=' grid grid-cols-1 w-full bg-opacity-70  rounded-lg  my-4 place-self-center '
                                animate={{ scale: L1Scale }}>
                                <div className='  grid grid-flow-col  place-self-center '>{modet}</div>
                                <div className='w-full grid grid-cols-4 bg-opacity-70 shadow-2xl  rounded-lg '>
                                    <Link to="/numquest"
                                    ><button className="   text-white  text-2xl focus:outline-none  fa fa-home transition duration-500 ease-in-out  hover:text-white" ></button></Link>
                                    <button className='  text-white  text-2xl focus:outline-none '>
                                        <i className="fa fa-cog text-purple-700  transition duration-500 ease-in-out  hover:text-white   text-2xl" onClick={this.displayDimensions}></i>
                                    </button>
                                    <button className=' text-white  text-2xl focus:outline-none  ' >
                                        <i class="fas fa-layer-group  text-yellow-500 transition duration-500 ease-in-out  hover:text-white   text-2xl"></i>
                                    </button>
                                    <button className='text-white  text-2xl focus:outline-none  ' >
                                        <i class="fas fa-info  text-red-400 transition duration-500 ease-in-out  hover:text-white  text-2xl "  ></i>
                                    </button>
                                </div>
                                <div className=' grid grid-flow-col place-self-center '>{lvlt}</div>

                            </motion.div>


                            <div >

                            </div>

                        </div>


                        <div id='MainCtrl' className=" text-white-700 text-4xl m-auto grid grid-cols-1  h-full shadow-2xl  mx-auto  w-full rounded-lg place-self-center ">
                            {PMLayout1}
                        </div>

                        <div id="KeyCtl" className='grid w-full  m-4  h-5/6   place-self-center'>
                            {RLayout1}
                        </div>

                    </div>
                </div>,


            LLayout:
                <div className="relative h-full " >
                    <div className=" grid  grid-cols-12  gap-2 h-full   bg-gray-400  mx-auto bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ">

                        <div className=' m-auto rounded-lg col-start-1 col-span-2 '>

                            <motion.div className=' grid grid-cols-3 w-full bg-opacity-70  rounded-lg  place-self-center '
                                animate={{ scale: L1Scale }}>
                                <div className=' m-auto  place-self-center '>{modet}</div>
                                <div className='m-auto grid grid-cols-1 bg-opacity-70 shadow-2xl  rounded-lg  place-self-center'>
                                    <Link to="/numquest"
                                    ><button className="   text-white  text-2xl focus:outline-none  fa fa-home transition duration-500 ease-in-out  hover:text-white" ></button></Link>
                                    <button className='  text-white  text-2xl focus:outline-none ' onClick={this.displayDimensions} >
                                        <i className="fa fa-cog text-purple-700  transition duration-500 ease-in-out  hover:text-white   text-2xl"></i>
                                    </button>
                                    <button className=' text-white  text-2xl focus:outline-none  ' >
                                        <i class="fas fa-layer-group  text-yellow-500 transition duration-500 ease-in-out  hover:text-white   text-2xl"></i>
                                    </button>
                                    <button className='text-white  text-2xl focus:outline-none  ' >
                                        <i class="fas fa-info  text-red-400 transition duration-500 ease-in-out  hover:text-white  text-2xl "  ></i>
                                    </button>
                                </div>
                                <div className=' p-2 mr-0 place-self-center '>{lvlt}</div>
                             
                            </motion.div>


                            <div >

                            </div>

                        </div>


                        <div id='MainCtrl' className=" text-white-700 text-4xl m-auto grid grid-cols-1  h-full shadow-2xl  mx-auto col-start-3 col-span-7 w-full rounded-lg place-self-center ">
                            {MLayout1}
                        </div>

                        <div id="KeyCtl" className='grid w-full  m-4  h-5/6 col-start-10 col-end-13  place-self-center'>
                            {RLayout1}
                        </div>
                    </div>
                </div>,



        })



    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        //     window.removeventListener('DOMContentLoaded', this.QNum2Ref);
        //    window.removeventListener('DOMContentLoaded', this.QNum1Ref);

    }


    render() {
        const { deltaPosition, absPosition } = this.state;


        if (this.state.PortLand == 'Portrait') {

            return (

                this.state.PLayout
            )

        } else {

            return (
                this.state.LLayout
            )

        }
    }



}