import React from 'react'

export default function Button(props) {
    var ClsName=''
    var Cnt=''
    if(props.Card=='HomeBtn'){
        ClsName='bg-blue-600 text-white  m-4 lg:text-2xl sm:text-sm p-2 shadow-2xl rounded-md transition duration-500 ease-in-out hover:bg-yellow-500 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl'
    } else if (props.Card=='NumBtn'){
        ClsName=' text-white  lg:text-2xl sm:text-sm p-2 shadow-2xl rounded-md transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl'
        if(props.Content=='Dn') {
            Cnt =<i class="far fa-arrow-alt-circle-down text-2xl hover:text-indigo-700"></i>   
        } else if (props.Content=='List'){ 
            Cnt = <i class="far fa-list-alt text-2xl hover:text-yellow-300"></i>
        } else if (props.Content=='Up'){
            Cnt = <i class="far fa-arrow-alt-circle-up text-2xl hover:text-indigo-700"></i>   
        }  else if (props.Content=='Home'){
            Cnt = <i class="fas fa-home text-2xl hover:text-red-700"></i>   
        }  else if (props.Content=='Rt'){
            Cnt = <i class="far fa-arrow-alt-circle-right text-2xl hover:text-indigo-700"></i>   
        }  else if (props.Content=='Lt'){
            Cnt = <i class="far fa-arrow-alt-circle-left text-2xl hover:text-indigo-700"></i>   
        }
        }  else if (props.Card=='AnimBtn'){
            ClsName=' text-white  lg:text-2xl sm:text-sm p-2 shadow-2xl rounded-md transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none  shadow-2xl'
            if(props.Content=='Dn') {
                Cnt =<i class="far fa-arrow-alt-circle-down text-2xl lg:text-4xl hover:text-indigo-700"></i>   
            } else if (props.Content=='List'){ 
                Cnt = <i class="far fa-list-alt text-2xl lg:text-4xl hover:text-yellow-300"></i>
            } else if (props.Content=='Up'){
                Cnt = <i class="far fa-arrow-alt-circle-up text-2xl lg:text-4xl hover:text-indigo-700"></i>   
            }  else if (props.Content=='Home'){
                Cnt = <i class="fas fa-home text-2xl lg:text-4xl hover:text-red-700"></i>   
            }  else if (props.Content=='Rt'){
                Cnt = <i class="far fa-arrow-alt-circle-right text-2xl lg:text-4xl hover:text-indigo-700"></i>   
            }  else if (props.Content=='Lt'){
                Cnt = <i class="far fa-arrow-alt-circle-left text-2xl lg:text-4xl hover:text-indigo-700"></i>   
            }
        }
         else {
            Cnt = props.Content
        }

    return (
       
            <button className={ClsName}>{Cnt}</button>
      
    )
}
