import React, { useEffect, useState } from "react";

const TestGetDimensions = (props) => {
   
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
      //const windowDimensions=window;

      return {
            width,
            height
        //  windowDimensions 
        };
    }


    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

    window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    props.SetWindowDimensions(windowDimensions)

    return (
        <div>
            HEY KIRAN
        </div>
    )
}

export default TestGetDimensions
