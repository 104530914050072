import React from 'react'
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link, useParams } from 'react-router-dom'

function Cookie() {
    return (
        <div  className='flex text-justify '>
            <CookieConsent id='DC' location="bottom" cookieName="dqc" expires={120} overlay
                style={{ background: "#2B373B" }, {right:'10px'}}
                buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
                buttonText='Accept'
            >
                <div className='m-4 lg:m-10 '> This website uses cookies to enhance the user experience, analyze the web traffic and display ads.
                By continuing to use this website, you consent with  <Link to='About' ><span className='text-;g font-semibold text-yellow-300'> the use of cookies and the privacy policy</span></Link>
                </div>
            </CookieConsent>

        </div>
    )
}

export default Cookie
