import React from 'react'
import { Link } from "react-router-dom"
const Style1='font-potta-one flex justify-left text-xm md:text-lg lg:text-xl bg-gradient-to-r from-blue-400 to-green-500 hover:from-pink-500 hover:to-yellow-500 p-2  border-t border-b block hover:bg-gray-100 hover:text-red-500 transition ease-in duration-200'
const Style2='text-white  text-left font-serif flex justify-left text-xm md:text-lg lg:text-xl bg-gradient-to-r from-blue-400 to-green-500 hover:from-pink-500 hover:to-yellow-500 p-2  block hover:bg-gray-100 hover:text-red-500 transition ease-in duration-200'
const Style3='text-white font-mono flex justify-left text-xm md:text-lg lg:text-xl bg-gradient-to-r from-blue-400 to-green-500 hover:from-pink-500 hover:to-yellow-500 p-2  border-t  block hover:bg-gray-100 hover:text-red-500 transition ease-in duration-200'

function NavigationMenu(props) {

    return (
        <div>
            <div className="border-b flex justify-between items-center  shadow-xl">
                <span className="mb-3 ml-3 text-xl md:text-3xl lg:text-4xl items-center font-arizonia">
                    Deege Quest
                </span>
            </div>
            <ul>
                <li>
                   <Link to="/" className={Style1}  onClick={props.closeMenu} > <span className="ml-5 text-white  text-left">  Home </span> </Link>
                    
                        <div>
                        <li>
                          <Link to="/NumQuest"  onClick={props.closeMenu} className={Style1} > <span className="ml-5 text-white  text-left"> NumQuest </span> </Link>
                        </li>
                      </div>

                        <div>
                        <li>
                          <Link to="/SkillApp"  onClick={props.closeMenu} className={Style1} > <span className="ml-5 text-white  text-left"> Alexa / VR  </span> </Link>
                        </li>
                        
                        </div>



                        <div>
                        <li>
                          <Link to="/WordQuest"  onClick={props.closeMenu} className={Style1} > <span className="ml-5 text-white  text-left"> Word Quest </span> </Link>
                        </li>
                       </div>
                       <div>
                        <li>
                          <Link to="/Quiz"  onClick={props.closeMenu} className={Style1} > <span className="ml-5 text-white  text-left"> Quiz </span> </Link>
                        </li>
                       </div>
                       <div>
                        <li>
                          <Link to="/About"  onClick={props.closeMenu} className={Style1} > <span className="ml-5 text-white  text-left"> About </span> </Link>
                        </li>
                       </div>
                       <div>
                        <li>
                          <Link to="/Contact"  onClick={props.closeMenu} className={Style1} > <span className="ml-5 text-white  text-left"> Contact </span> </Link>
                        </li>
                       </div>

                </li>


    
            </ul>
        </div>
    )
}

export default NavigationMenu


