import React from 'react';
import ReactDOM from 'react-dom'
import { motion, useMotionValue, useTransform, useSpring, useMotionTemplate } from "framer-motion"
import Btn from '../components/Button'
import { Link } from 'react-router-dom'
import Draggable from 'react-draggable'



export default class Animtest extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            NumVal: 0,
            Xpos: 0,
            activeDrags: 0,
            deltaPosition: {
                x: 0, y: 0
            },
            absPosition: {
                x: 0, y: 0
            },
            numBoxPos: {
                a: 0, b: 0, c: 0, d: 0
            },
            width: 0,
            height: 0

        }

    }

    BackXpos() {
        this.setState({ Xpos: (this.state.Xpos == 0 ? this.state.Xpos : this.state.Xpos - 10) })
    }

    ForwardXpos() {
        this.setState({ Xpos: (this.state.Xpos == 300 ? this.state.Xpos : this.state.Xpos + 10) })
    }

    handleEvent = (e, data) => {
        console.log('Event Type', e.type);
        console.log(e, data);
    }


    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
    }

    handleStop = (e, ui) => {
        const { x, y } = this.state.absPosition;
        this.setState({
            absPosition: {
                x: ui.x,
                y: ui.y,
            }
        });
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    setTextInputRef = element => {
        this.textInput = element;
        console.log(element)
        alert(element.clientWidth + ' ' +  element.clientHeight + ' ' + element.offsetTop + ' ' + element.offsetLeft 
        + ' ' + element.offsetHeight+ ' '+ element.offsetWidth)
      };


    render() {
        const { deltaPosition, absPosition } = this.state;

        return (
            <div className="relative h-full " >
                <div className=" grid lg:grid-cols-6  grid-cols-1 lg:gap-4 h-full  shadow-3xl bg-gray-400  mx-auto ">


                    <div className='lg:col-start-1 lg:col-span-1 shadow-3xl lg:ml-5 m-auto h-5/6 w-11/12  md:w-7/8 rounded-lg '>
                        <div id='NavCtrl' className=" text-indigo-200  h-full m-auto grid grid-cols-1  ">
                            <div className="  grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-self-center   bg-blue-600 rounded-2xl "   >
                                <div className=" text-center  lg:px-58 ">
                                    <div className="grid grid-cols-4 lg:grid-cols-1 shadow-2xl  " >
                                        <div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content="Up" ></Btn>
                                        </div>
                                        <Link to='/md=1'><div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content='Home' />
                                        </div></Link>
                                        <div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content="List" />
                                        </div>
                                        <div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content="Dn" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className=' lg:col-start-2 lg:col-span-4  shadow-2xl bg-blue-600 mx-auto lg:m-auto lg:h-5/6 w-11/12 lg:w-full md:w-7/8 rounded-lg'>

                        <div id='MainCtrl' className=" text-indigo-200  h-full m-auto grid grid-cols-1 ">
                            <div className='w-full  place-self-center'>
                                <Draggable
                                    scale={1}
                                    handle=".handle"
                                    defaultPosition={{ x: 0, y: 0 }}
                                    onDrag={this.handleEvent}
                                    onStart={this.handleEvent}
                                    onStop={this.handleEvent}
                                    onMouseDown={this.handleEvent}
                                    onMouseUp={this.handleEvent}
                                    onTouchStart={this.handleEvent}
                                    onTouchEnd={this.handleEvent}>
                                    <div className='h-10 w-10 bg-yellow-500  text-4xl handle'>{this.state.NumVal}</div>
                                </Draggable>

                                <span>Window size: {this.state.width} x {this.state.height}</span>;
                                <Draggable
                                    onDrag={this.handleDrag}
                                    onStop={this.handleStop}
                                >

                                    <div className="drag-box">
                                        <div>Tacking Delta</div>
                                        <div>x: {deltaPosition.x.toFixed(0)}, y: {deltaPosition.y.toFixed(0)}</div>
                                        <div>Abs-x: {absPosition.x.toFixed(0)}, Abs-y: {absPosition.y.toFixed(0)}</div>
                                    </div>

                                </Draggable>


                                <Draggable
                                    bounds={{ top: -100, left: -100, right: 100, bottom: 100 }}>

                                    <div className="drag-box">
                                        <div>I can only be moved 100px in any direction.</div>
                                    </div>

                                </Draggable>




                                <motion.h2
                                    className='w-2 h-2 lg:h-10 lg:w-10 bg-blue-500  text-2xl'
                                    animate={
                                        { x: this.state.Xpos }
                                    }
                                    transition={{ duration: .1, ease: "easeInOut" }}
                                    drag='x'
                                    dragConstraints={{ left: 0, right: 300 }}

                                >
                                    <div className='text-5xl text-white'>{this.state.Xpos}</div>
                                </motion.h2>



                                <motion.div
                                    className='w-2 h-2 lg:h-10 lg:w-10 bg-red-600 text-2xl'
                                    whileHover={{ scale: 1.2 }}
                                    whileTap={{ scale: 0.8 }}
                                    style={{ x: 100 }}

                                >
                                </motion.div>

                                <motion.h2
                                    className='w-2 h-2 lg:h-10 lg:w-10 bg-white text-2xl'
                                    initial={{ x: 10, y: 50 }}
                                    animate={
                                        { x: 400, y: 50 }
                                    }
                                    transition={{ delay: .5, duration: 10 }}

                                >

                                </motion.h2>






                                <motion.h2
                                    className='font-potta-one text-2xl'
                                    animate={
                                        { scale: 1.5, x: -100, y: -10 }
                                    }
                                    transition={{ delay: 1.5, type: 'spring', stiffness: 120 }}

                                >
                                    Hey Kiran
                            </motion.h2>

                                <motion.button
                                    className='bg-green-800 m-4'
                                    whileHover={{ scale: 1.2 }}
                                    whileTap={{ scale: 0.8 }}
                                    initial={{ scale: 1, opacity: 1 }}
                                    animate={{ scale: 1.2, opacity: .5 }}
                                    transition={{ yoyo: Infinity, duration: 1 }}
                                    onClick={this.ForwardXpos.bind(this)}
                                >
                                    Forward
                            </motion.button>

                                <motion.button
                                    whileHover={{ scale: 1.2 }}
                                    whileTap={{ scale: 0.8 }}
                                    className='bg-red-800 m-4'
                                    onClick={this.BackXpos.bind(this)}
                                >
                                    Backward
                            </motion.button>





                            </div>
                        </div>
                    </div>



                    <div id="numBox" className=' lg:col-start-6 lg:col-span-1  shadow-2xl lg:mr-5 m-auto bg-blue-600 h-5/6 w-11/12 md:w-7/8 rounded-lg'>

                        <Draggable
                            onDrag={this.handleDrag}
                            onStop={this.handleStop}
                            Position={null}
                        >

                            <div className="drag-box">
                                <div>Tacking Delta</div>
                                <div>x: {deltaPosition.x.toFixed(0)}, y: {deltaPosition.y.toFixed(0)}</div>
                                <div>Abs-x: {absPosition.x.toFixed(0)}, Abs-y: {absPosition.y.toFixed(0)}</div>
                            </div>

                        </Draggable>

                        <input
                            type="text"
                            ref={this.setTextInputRef}
                        />



                    </div>


                </div>
            </div>
        )
    }



}