module.exports = {

  subjects : [ 'SUBJECT',"USA","INDIA","WORLD","CLASS2_MATHS","CLASS2_ENGLISH","CLASS2_HINDI",],

  topics : 
  {
      "USA" : ['Topic',"Capitals",'GK'],
      "INDIA" : ['Topic','GK',"Capitals"],
      "CLASS2_MATHS" : ['Topic',"Face & Place Values",'Addition','Subtraction','Division','Multiplication'],
      "CLASS2_ENGLISH" : ['Topic','Antonyms','Spelling'],
      "CLASS2_HINDI" : ['Topic','Rainbow Colors','Colors','Meanings','Opposites'],
      "WORLD" : ['Topic',"Country Capitals",'Currencies'],
  },

  USA : ['USA',"Capitals",'GK'],
  INDIA : ['India','GK',"Capitals"],
  CLASS2_MATHS : ['Class2 Maths',"Face & Place Values",'Addition','Subtraction','Division','Multiplication'],
  CLASS2_ENGLISH : ['Class2 English','Antonyms','Spelling'],
  CLASS2_HINDI : ['Class2 Hindi','Rainbow Colors','Colors','Meanings','Opposites'],
  WORLD : ['World',"Country Capitals",'Currencies'],


}

