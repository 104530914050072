import React from 'react';
import bImg from '../images/qr.jpg'
import CookieConsent from '../components/Cookie'

function Apps() {
  return (



    <div className=" m-2 md:m-6 lg:m-12  text-indigo-100 min-h-screen ">
      <div className=" mb-4 grid  grid-cols-1  flex justify-items-center items-center bg-blue-600   shadow-2xl bg-opacity-70"   >
        <div className=" flex justify-items-center m-4 text-center items-center">
          <h2 className=" text-2xl md:text-4xl lg:text-6xl  text-white  font-arizonia font-semibold">Apps</h2>
        </div>

      </div>
      <div className=" t=20 grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3  flex justify-items-center items-center "   >
        <div className="bg-white p-2 m-16 bg-yellow-600   transition duration-500 ease-in-out  hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 bg-opacity-60 rounded-3xl">
          <img src={bImg} alt="test" class="w-full rounded-lg shadow=2xl"></img>
          <span className="text-white  text-2xl font-semibold"> App1 </span>
        </div>
   
        <div className="bg-white p-2 m-16 bg-green-600   transition duration-500 ease-in-out  hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 bg-opacity-60 rounded-3xl">
          <img src={bImg} alt="test" class="w-full rounded-lg shadow=2xl"></img>
          <span className="text-white  text-2xl font-semibold"> App2 </span>
        </div>

        <div className="bg-white p-2 m-16 bg-indigo-600   transition duration-500 ease-in-out  hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 bg-opacity-60 rounded-3xl">
          <img src={bImg} alt="test" class="w-full rounded-lg shadow=2xl"></img>
          <span className="text-white  text-2xl font-semibold"> App3 </span>
        </div>

        <div className="bg-white p-2 m-16 bg-pink-500   transition duration-500 ease-in-out  hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 bg-opacity-60 rounded-3xl">
          <img src={bImg} alt="test" class="w-full rounded-lg shadow=2xl"></img>
          <span className="text-white  text-2xl font-semibold"> App4 </span>
        </div>
   
        </div>

      

        <div class="container my-12 mx-auto px-4 md:px-12">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">


        <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">


            <article class="overflow-hidden rounded-lg shadow-lg">

                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src={bImg} />
                </a>

                <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" href="#">
                            Article Title
                        </a>
                    </h1>
                    <p class="text-grey-darker text-sm">
                        11/1/19
                    </p>
                </header>

                <footer class="flex items-center justify-between leading-none p-2 md:p-4">
                    <a class="flex items-center no-underline hover:underline text-black" href="#">
                       
                        <p class="ml-2 text-sm">
                            Author Name
                        </p>
                    </a>
                    <a class="no-underline text-grey-darker hover:text-red-dark" href="#">
                        <span class="hidden">Like</span>
                        <i class="fa fa-heart"></i>
                    </a>
                </footer>

            </article>


        </div>



        <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">


<article class="overflow-hidden rounded-lg shadow-lg">

    <a href="#">
        <img alt="Placeholder" class="block h-auto w-full" src={bImg} />
    </a>

    <header class="flex items-center justify-between leading-tight p-2 md:p-4">
        <h1 class="text-lg">
            <a class="no-underline hover:underline text-black" href="#">
                Article Title
            </a>
        </h1>
        <p class="text-grey-darker text-sm">
            11/1/19
        </p>
    </header>

    <footer class="flex items-center justify-between leading-none p-2 md:p-4">
        <a class="flex items-center no-underline hover:underline text-black" href="#">
           
            <p class="ml-2 text-sm">
                Author Name
            </p>
        </a>
        <a class="no-underline text-grey-darker hover:text-red-dark" href="#">
            <span class="hidden">Like</span>
            <i class="fa fa-heart"></i>
        </a>
    </footer>

</article>


</div>


<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">


<article class="overflow-hidden rounded-lg shadow-lg">

    <a href="#">
        <img alt="Placeholder" class="block h-auto w-full" src={bImg} />
    </a>

    <header class="flex items-center justify-between leading-tight p-2 md:p-4">
        <h1 class="text-lg">
            <a class="no-underline hover:underline text-black" href="#">
                Article Title
            </a>
        </h1>
        <p class="text-grey-darker text-sm">
            11/1/19
        </p>
    </header>

    <footer class="flex items-center justify-between leading-none p-2 md:p-4">
        <a class="flex items-center no-underline hover:underline text-black" href="#">
           
            <p class="ml-2 text-sm">
                Author Name
            </p>
        </a>
        <a class="no-underline text-grey-darker hover:text-red-dark" href="#">
            <span class="hidden">Like</span>
            <i class="fa fa-heart"></i>
        </a>
    </footer>

</article>


</div>


<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">


<article class="overflow-hidden rounded-lg shadow-lg">

    <a href="#">
        <img alt="Placeholder" class="block h-auto w-full" src={bImg} />
    </a>

    <header class="flex items-center justify-between leading-tight p-2 md:p-4">
        <h1 class="text-lg">
            <a class="no-underline hover:underline text-black" href="#">
                Article Title
            </a>
        </h1>
        <p class="text-grey-darker text-sm">
            11/1/19
        </p>
    </header>

    <footer class="flex items-center justify-between leading-none p-2 md:p-4">
        <a class="flex items-center no-underline hover:underline text-black" href="#">
           
            <p class="ml-2 text-sm">
                Author Name
            </p>
        </a>
        <a class="no-underline text-grey-darker hover:text-red-dark" href="#">
            <span class="hidden">Like</span>
            <i class="fa fa-heart"></i>
        </a>
    </footer>

</article>


</div>




    </div>
</div>


<CookieConsent />

    </div>





  );
}

export default Apps;