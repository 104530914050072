module.exports = {

Capitals : [
["What is the capital of Alabama","Montgomery","Salt Lake City","Baton Rouge","Salem","Montgomery"],
["Name the capital city of Alaska","Juneau","Montpelier","Augusta","Harrisburg","Juneau"],
["Which city is the capital of Arizona","Phoenix","Richmond","Annapolis","Providence","Phoenix"],
["Which of the following is the capital of Arkansas","Little Rock","Olympia","Boston","Columbia","Little Rock"],
["____ is the capital of California","Sacramento","Charleston","Lansing","Pierre","Sacramento"],
["What is the capital city of Colorado","Denver","Madison","Saint Paul","Nashville","Denver"],
["What is the capital of Connecticut","Hartford","Cheyenne","Jackson","Austin","Hartford"],
["Name the capital city of Delaware","Dover","Salem","Jefferson City","Juneau","Dover"],
["Which city is the capital of Florida","Tallahassee","Harrisburg","Helena","Phoenix","Tallahassee"],
["Which of the following is the capital of Georgia","Atlanta","Providence","Lincoln","Little Rock","Atlanta"],
["____ is the capital of Hawaii","Honolulu","Columbia","Carson City","Sacramento","Honolulu"],
["What is the capital city ofIdaho","Boise","Pierre","Concord","Denver","Boise"],
["What is the capital of Illinois","Springfield","Nashville","Trenton","Hartford","Springfield"],
["Name the capital city of Indiana","Indianapolis","Austin","Santa Fe","Dover","Indianapolis"],
["Which city is the capital of Iowa","Des Moines","Augusta","Albany","Tallahassee","Des Moines"],
["Which of the following is the capital of Kansas","Topeka","Annapolis","Raleigh","Atlanta","Topeka"],
["____ is the capital of Kentucky","Frankfort","Boston","Bismarck","Honolulu","Frankfort"],
["What is the capital city of Louisiana","Salt Lake City","Baton Rouge","Indianapolis","Boise","Baton Rouge"],
["What is the capital of Maine","Montpelier","Augusta","Des Moines","Springfield","Augusta"],
["Name the capital city of Maryland","Richmond","Annapolis","Topeka","Indianapolis","Annapolis"],
["Which city is the capital ofMassachusetts","Olympia","Boston","Frankfort","Des Moines","Boston"],
["Which of the following is the capital of Michigan","Charleston","Lansing","Salt Lake City","Topeka","Lansing"],
["____ is the capital of Minnesota","Madison","Saint Paul","Montpelier","Frankfort","Saint Paul"],
["What is the capital city of Mississippi","Cheyenne","Jackson","Richmond","Baton Rouge","Jackson"],
["What is the capital of Missouri","Helena","Jefferson City","Olympia","Augusta","Jefferson City"],
["Name the capital city of Montana","Lincoln","Helena","Charleston","Annapolis","Helena"],
["Which city is the capital of Nebraska","Carson City","Lincoln","Madison","Boston","Lincoln"],
["Which of the following is the capital of Nevada","Concord","Carson City","Cheyenne","Lansing","Carson City"],
["____ is the capital of New Hampshire","Trenton","Concord","Pierre","Saint Paul","Concord"],
["What is the capital city ofNew Jersey","Santa Fe","Trenton","Nashville","Jackson","Trenton"],
["What is the capital of New Mexico","Albany","Santa Fe","Austin","Jefferson City","Santa Fe"],
["Name the capital city of New York","Raleigh","Albany","Juneau","Helena","Albany"],
["Which city is the capital of North Carolina","Bismarck","Raleigh","Phoenix","Lincoln","Raleigh"],
["Which of the following is the capital of North Dakota","Columbus","Bismarck","Little Rock","Carson City","Bismarck"],
["____ is the capital of Ohio","Oklahoma City","Columbus","Boston","Concord","Columbus"],
["What is the capital city of Oklahoma","Frankfort","Oklahoma City","Lansing","Trenton","Oklahoma City"],
["What is the capital of Oregon","Salt Lake City","Atlanta","Salem","Santa Fe","Salem"],
["Name the capital city of Pennsylvania","Montpelier","Honolulu","Harrisburg","Albany","Harrisburg"],
["Which city is the capital of Rhode Island","Richmond","Boise","Providence","Raleigh","Providence"],
["Which of the following is the capital of South Carolina","Olympia","Springfield","Columbia","Bismarck","Columbia"],
["____ is the capital of South Dakota","Charleston","Indianapolis","Pierre","Columbus","Pierre"],
["What is the capital city of Tennessee","Montgomery","Des Moines","Nashville","Oklahoma City","Nashville"],
["What is the capital of Texas","Juneau","Topeka","Austin","Trenton","Austin"],
["Name the capital city of Utah","Phoenix","Frankfort","Concord","Salt Lake City","Salt Lake City"],
["Which city is the capital of Vermont","Harrisburg","Montgomery","Trenton","Montpelier","Montpelier"],
["Which of the following is the capital of Virginia","Providence","Juneau","Santa Fe","Richmond","Richmond"],
["____ is the capital of Washington","Columbia","Phoenix","Albany","Olympia","Olympia"],
["What is the capital city of West Virginia","Pierre","Little Rock","Raleigh","Charleston","Charleston"],
["What is the capital of Wisconsin","Nashville","Sacramento","Bismarck","Madison","Madison"],
["Name the capital city of Wyoming","Austin","Denver","Columbus","Cheyenne","Cheyenne"],
],


Geography : [
  ["What is the capital of Alabama","Montgomery","Salt Lake City","Baton Rouge","Salem","Montgomery"],
  ["Name the capital city of Alaska","Juneau","Montpelier","Augusta","Harrisburg","Juneau"],
  ["Which city is the capital of Arizona","Phoenix","Richmond","Annapolis","Providence","Phoenix"],
  ["Which of the following is the capital of Arkansas","Little Rock","Olympia","Boston","Columbia","Little Rock"],
  ["____ is the capital of California","Sacramento","Charleston","Lansing","Pierre","Sacramento"],
  ["What is the capital city of Colorado","Denver","Madison","Saint Paul","Nashville","Denver"],
  ["What is the capital of Connecticut","Hartford","Cheyenne","Jackson","Austin","Hartford"],
  ["Name the capital city of Delaware","Dover","Salem","Jefferson City","Juneau","Dover"],
  ["Which city is the capital of Florida","Tallahassee","Harrisburg","Helena","Phoenix","Tallahassee"],
  ["Which of the following is the capital of Georgia","Atlanta","Providence","Lincoln","Little Rock","Atlanta"],
  ["____ is the capital of Hawaii","Honolulu","Columbia","Carson City","Sacramento","Honolulu"],
  ["What is the capital city ofIdaho","Boise","Pierre","Concord","Denver","Boise"],
  ["What is the capital of Illinois","Springfield","Nashville","Trenton","Hartford","Springfield"],
  ["Name the capital city of Indiana","Indianapolis","Austin","Santa Fe","Dover","Indianapolis"],
  ["Which city is the capital of Iowa","Des Moines","Augusta","Albany","Tallahassee","Des Moines"],
  ["Which of the following is the capital of Kansas","Topeka","Annapolis","Raleigh","Atlanta","Topeka"],
  ["____ is the capital of Kentucky","Frankfort","Boston","Bismarck","Honolulu","Frankfort"],
  ["What is the capital city of Louisiana","Salt Lake City","Baton Rouge","Indianapolis","Boise","Baton Rouge"],
  ["What is the capital of Maine","Montpelier","Augusta","Des Moines","Springfield","Augusta"],
  ["Name the capital city of Maryland","Richmond","Annapolis","Topeka","Indianapolis","Annapolis"],
  ["Which city is the capital ofMassachusetts","Olympia","Boston","Frankfort","Des Moines","Boston"],
  ["Which of the following is the capital of Michigan","Charleston","Lansing","Salt Lake City","Topeka","Lansing"],
  ["____ is the capital of Minnesota","Madison","Saint Paul","Montpelier","Frankfort","Saint Paul"],
  ["What is the capital city of Mississippi","Cheyenne","Jackson","Richmond","Baton Rouge","Jackson"],
  ["What is the capital of Missouri","Helena","Jefferson City","Olympia","Augusta","Jefferson City"],
  ["Name the capital city of Montana","Lincoln","Helena","Charleston","Annapolis","Helena"],
  ["Which city is the capital of Nebraska","Carson City","Lincoln","Madison","Boston","Lincoln"],
  ["Which of the following is the capital of Nevada","Concord","Carson City","Cheyenne","Lansing","Carson City"],
  ["____ is the capital of New Hampshire","Trenton","Concord","Pierre","Saint Paul","Concord"],
  ["What is the capital city ofNew Jersey","Santa Fe","Trenton","Nashville","Jackson","Trenton"],
  ["What is the capital of New Mexico","Albany","Santa Fe","Austin","Jefferson City","Santa Fe"],
  ["Name the capital city of New York","Raleigh","Albany","Juneau","Helena","Albany"],
  ["Which city is the capital of North Carolina","Bismarck","Raleigh","Phoenix","Lincoln","Raleigh"],
  ["Which of the following is the capital of North Dakota","Columbus","Bismarck","Little Rock","Carson City","Bismarck"],
  ["____ is the capital of Ohio","Oklahoma City","Columbus","Boston","Concord","Columbus"],
  ["What is the capital city of Oklahoma","Frankfort","Oklahoma City","Lansing","Trenton","Oklahoma City"],
  ["What is the capital of Oregon","Salt Lake City","Atlanta","Salem","Santa Fe","Salem"],
  ["Name the capital city of Pennsylvania","Montpelier","Honolulu","Harrisburg","Albany","Harrisburg"],
  ["Which city is the capital of Rhode Island","Richmond","Boise","Providence","Raleigh","Providence"],
  ["Which of the following is the capital of South Carolina","Olympia","Springfield","Columbia","Bismarck","Columbia"],
  ["____ is the capital of South Dakota","Charleston","Indianapolis","Pierre","Columbus","Pierre"],
  ["What is the capital city of Tennessee","Montgomery","Des Moines","Nashville","Oklahoma City","Nashville"],
  ["What is the capital of Texas","Juneau","Topeka","Austin","Trenton","Austin"],
  ["Name the capital city of Utah","Phoenix","Frankfort","Concord","Salt Lake City","Salt Lake City"],
  ["Which city is the capital of Vermont","Harrisburg","Montgomery","Trenton","Montpelier","Montpelier"],
  ["Which of the following is the capital of Virginia","Providence","Juneau","Santa Fe","Richmond","Richmond"],
  ["____ is the capital of Washington","Columbia","Phoenix","Albany","Olympia","Olympia"],
  ["What is the capital city of West Virginia","Pierre","Little Rock","Raleigh","Charleston","Charleston"],
  ["What is the capital of Wisconsin","Nashville","Sacramento","Bismarck","Madison","Madison"],
  ["Name the capital city of Wyoming","Austin","Denver","Columbus","Cheyenne","Cheyenne"],
  ],

  GK : [
    ["What is the capital of Alabama","Montgomery","Salt Lake City","Baton Rouge","Salem","Montgomery"],
    ["Name the capital city of Alaska","Juneau","Montpelier","Augusta","Harrisburg","Juneau"],
    ["Which city is the capital of Arizona","Phoenix","Richmond","Annapolis","Providence","Phoenix"],
    ["Which of the following is the capital of Arkansas","Little Rock","Olympia","Boston","Columbia","Little Rock"],
    ["____ is the capital of California","Sacramento","Charleston","Lansing","Pierre","Sacramento"],
    ["What is the capital city of Colorado","Denver","Madison","Saint Paul","Nashville","Denver"],
    ["What is the capital of Connecticut","Hartford","Cheyenne","Jackson","Austin","Hartford"],
    ["Name the capital city of Delaware","Dover","Salem","Jefferson City","Juneau","Dover"],
    ["Which city is the capital of Florida","Tallahassee","Harrisburg","Helena","Phoenix","Tallahassee"],
    ["Which of the following is the capital of Georgia","Atlanta","Providence","Lincoln","Little Rock","Atlanta"],
    ["____ is the capital of Hawaii","Honolulu","Columbia","Carson City","Sacramento","Honolulu"],
    ["What is the capital city ofIdaho","Boise","Pierre","Concord","Denver","Boise"],
    ["What is the capital of Illinois","Springfield","Nashville","Trenton","Hartford","Springfield"],
    ["Name the capital city of Indiana","Indianapolis","Austin","Santa Fe","Dover","Indianapolis"],
    ["Which city is the capital of Iowa","Des Moines","Augusta","Albany","Tallahassee","Des Moines"],
    ["Which of the following is the capital of Kansas","Topeka","Annapolis","Raleigh","Atlanta","Topeka"],
    ["____ is the capital of Kentucky","Frankfort","Boston","Bismarck","Honolulu","Frankfort"],
    ["What is the capital city of Louisiana","Salt Lake City","Baton Rouge","Indianapolis","Boise","Baton Rouge"],
    ["What is the capital of Maine","Montpelier","Augusta","Des Moines","Springfield","Augusta"],
    ["Name the capital city of Maryland","Richmond","Annapolis","Topeka","Indianapolis","Annapolis"],
    ["Which city is the capital ofMassachusetts","Olympia","Boston","Frankfort","Des Moines","Boston"],
    ["Which of the following is the capital of Michigan","Charleston","Lansing","Salt Lake City","Topeka","Lansing"],
    ["____ is the capital of Minnesota","Madison","Saint Paul","Montpelier","Frankfort","Saint Paul"],
    ["What is the capital city of Mississippi","Cheyenne","Jackson","Richmond","Baton Rouge","Jackson"],
    ["What is the capital of Missouri","Helena","Jefferson City","Olympia","Augusta","Jefferson City"],
    ["Name the capital city of Montana","Lincoln","Helena","Charleston","Annapolis","Helena"],
    ["Which city is the capital of Nebraska","Carson City","Lincoln","Madison","Boston","Lincoln"],
    ["Which of the following is the capital of Nevada","Concord","Carson City","Cheyenne","Lansing","Carson City"],
    ["____ is the capital of New Hampshire","Trenton","Concord","Pierre","Saint Paul","Concord"],
    ["What is the capital city ofNew Jersey","Santa Fe","Trenton","Nashville","Jackson","Trenton"],
    ["What is the capital of New Mexico","Albany","Santa Fe","Austin","Jefferson City","Santa Fe"],
    ["Name the capital city of New York","Raleigh","Albany","Juneau","Helena","Albany"],
    ["Which city is the capital of North Carolina","Bismarck","Raleigh","Phoenix","Lincoln","Raleigh"],
    ["Which of the following is the capital of North Dakota","Columbus","Bismarck","Little Rock","Carson City","Bismarck"],
    ["____ is the capital of Ohio","Oklahoma City","Columbus","Boston","Concord","Columbus"],
    ["What is the capital city of Oklahoma","Frankfort","Oklahoma City","Lansing","Trenton","Oklahoma City"],
    ["What is the capital of Oregon","Salt Lake City","Atlanta","Salem","Santa Fe","Salem"],
    ["Name the capital city of Pennsylvania","Montpelier","Honolulu","Harrisburg","Albany","Harrisburg"],
    ["Which city is the capital of Rhode Island","Richmond","Boise","Providence","Raleigh","Providence"],
    ["Which of the following is the capital of South Carolina","Olympia","Springfield","Columbia","Bismarck","Columbia"],
    ["____ is the capital of South Dakota","Charleston","Indianapolis","Pierre","Columbus","Pierre"],
    ["What is the capital city of Tennessee","Montgomery","Des Moines","Nashville","Oklahoma City","Nashville"],
    ["What is the capital of Texas","Juneau","Topeka","Austin","Trenton","Austin"],
    ["Name the capital city of Utah","Phoenix","Frankfort","Concord","Salt Lake City","Salt Lake City"],
    ["Which city is the capital of Vermont","Harrisburg","Montgomery","Trenton","Montpelier","Montpelier"],
    ["Which of the following is the capital of Virginia","Providence","Juneau","Santa Fe","Richmond","Richmond"],
    ["____ is the capital of Washington","Columbia","Phoenix","Albany","Olympia","Olympia"],
    ["What is the capital city of West Virginia","Pierre","Little Rock","Raleigh","Charleston","Charleston"],
    ["What is the capital of Wisconsin","Nashville","Sacramento","Bismarck","Madison","Madison"],
    ["Name the capital city of Wyoming","Austin","Denver","Columbus","Cheyenne","Cheyenne"],
    ],


    History : [
      ["What is the capital of Alabama","Montgomery","Salt Lake City","Baton Rouge","Salem","Montgomery"],
      ["Name the capital city of Alaska","Juneau","Montpelier","Augusta","Harrisburg","Juneau"],
      ["Which city is the capital of Arizona","Phoenix","Richmond","Annapolis","Providence","Phoenix"],
      ["Which of the following is the capital of Arkansas","Little Rock","Olympia","Boston","Columbia","Little Rock"],
      ["____ is the capital of California","Sacramento","Charleston","Lansing","Pierre","Sacramento"],
      ["What is the capital city of Colorado","Denver","Madison","Saint Paul","Nashville","Denver"],
      ["What is the capital of Connecticut","Hartford","Cheyenne","Jackson","Austin","Hartford"],
      ["Name the capital city of Delaware","Dover","Salem","Jefferson City","Juneau","Dover"],
      ["Which city is the capital of Florida","Tallahassee","Harrisburg","Helena","Phoenix","Tallahassee"],
      ["Which of the following is the capital of Georgia","Atlanta","Providence","Lincoln","Little Rock","Atlanta"],
      ["____ is the capital of Hawaii","Honolulu","Columbia","Carson City","Sacramento","Honolulu"],
      ["What is the capital city ofIdaho","Boise","Pierre","Concord","Denver","Boise"],
      ["What is the capital of Illinois","Springfield","Nashville","Trenton","Hartford","Springfield"],
      ["Name the capital city of Indiana","Indianapolis","Austin","Santa Fe","Dover","Indianapolis"],
      ["Which city is the capital of Iowa","Des Moines","Augusta","Albany","Tallahassee","Des Moines"],
      ["Which of the following is the capital of Kansas","Topeka","Annapolis","Raleigh","Atlanta","Topeka"],
      ["____ is the capital of Kentucky","Frankfort","Boston","Bismarck","Honolulu","Frankfort"],
      ["What is the capital city of Louisiana","Salt Lake City","Baton Rouge","Indianapolis","Boise","Baton Rouge"],
      ["What is the capital of Maine","Montpelier","Augusta","Des Moines","Springfield","Augusta"],
      ["Name the capital city of Maryland","Richmond","Annapolis","Topeka","Indianapolis","Annapolis"],
      ["Which city is the capital ofMassachusetts","Olympia","Boston","Frankfort","Des Moines","Boston"],
      ["Which of the following is the capital of Michigan","Charleston","Lansing","Salt Lake City","Topeka","Lansing"],
      ["____ is the capital of Minnesota","Madison","Saint Paul","Montpelier","Frankfort","Saint Paul"],
      ["What is the capital city of Mississippi","Cheyenne","Jackson","Richmond","Baton Rouge","Jackson"],
      ["What is the capital of Missouri","Helena","Jefferson City","Olympia","Augusta","Jefferson City"],
      ["Name the capital city of Montana","Lincoln","Helena","Charleston","Annapolis","Helena"],
      ["Which city is the capital of Nebraska","Carson City","Lincoln","Madison","Boston","Lincoln"],
      ["Which of the following is the capital of Nevada","Concord","Carson City","Cheyenne","Lansing","Carson City"],
      ["____ is the capital of New Hampshire","Trenton","Concord","Pierre","Saint Paul","Concord"],
      ["What is the capital city ofNew Jersey","Santa Fe","Trenton","Nashville","Jackson","Trenton"],
      ["What is the capital of New Mexico","Albany","Santa Fe","Austin","Jefferson City","Santa Fe"],
      ["Name the capital city of New York","Raleigh","Albany","Juneau","Helena","Albany"],
      ["Which city is the capital of North Carolina","Bismarck","Raleigh","Phoenix","Lincoln","Raleigh"],
      ["Which of the following is the capital of North Dakota","Columbus","Bismarck","Little Rock","Carson City","Bismarck"],
      ["____ is the capital of Ohio","Oklahoma City","Columbus","Boston","Concord","Columbus"],
      ["What is the capital city of Oklahoma","Frankfort","Oklahoma City","Lansing","Trenton","Oklahoma City"],
      ["What is the capital of Oregon","Salt Lake City","Atlanta","Salem","Santa Fe","Salem"],
      ["Name the capital city of Pennsylvania","Montpelier","Honolulu","Harrisburg","Albany","Harrisburg"],
      ["Which city is the capital of Rhode Island","Richmond","Boise","Providence","Raleigh","Providence"],
      ["Which of the following is the capital of South Carolina","Olympia","Springfield","Columbia","Bismarck","Columbia"],
      ["____ is the capital of South Dakota","Charleston","Indianapolis","Pierre","Columbus","Pierre"],
      ["What is the capital city of Tennessee","Montgomery","Des Moines","Nashville","Oklahoma City","Nashville"],
      ["What is the capital of Texas","Juneau","Topeka","Austin","Trenton","Austin"],
      ["Name the capital city of Utah","Phoenix","Frankfort","Concord","Salt Lake City","Salt Lake City"],
      ["Which city is the capital of Vermont","Harrisburg","Montgomery","Trenton","Montpelier","Montpelier"],
      ["Which of the following is the capital of Virginia","Providence","Juneau","Santa Fe","Richmond","Richmond"],
      ["____ is the capital of Washington","Columbia","Phoenix","Albany","Olympia","Olympia"],
      ["What is the capital city of West Virginia","Pierre","Little Rock","Raleigh","Charleston","Charleston"],
      ["What is the capital of Wisconsin","Nashville","Sacramento","Bismarck","Madison","Madison"],
      ["Name the capital city of Wyoming","Austin","Denver","Columbus","Cheyenne","Cheyenne"],
      ],
}
