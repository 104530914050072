import React from 'react'
import Fade from 'react-reveal/Fade'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import cImg from '../images/dq1.png'
import AImg2 from '../images/element-info.png'
import  AImg1 from '../images/lets-spell.png'
import VImg2 from '../images/vr-2.png'
import  VImg1 from '../images/vr-1.png'
import { Link } from "react-router-dom"


export default function Card(props) {

    var Disclaimer= <Fade duration={3000} ><div className='text-left text-justify mx-6 text-gray-800 my-4 lg:my-8'>
    <div className=' py-5'> If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by <a href = "mailto: rajkiran@deegeplanet.com">Email</a> </div>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Disclaimer for Deege Quest</div>
    <p>All the information on this website - https://deegequest.com - is published in good faith and for general information purpose only. Deege Quest does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Deege Quest), is strictly at your own risk. Deege Quest will not be liable for any losses and/or damages in connection with the use of our website. Our Disclaimer was generated with the help of the <a href="https://www.privacypolicyonline.com/disclaimer-generator/">Disclaimer Generator</a> and the <a href="https://www.generateprivacypolicy.com">Privacy Policy Generator</a>.</p>
    <p className=' py-5'>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.</p>
    <p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Consent</div>
    <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Update</div>
    <p className=' pb-5'>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p></div>
    </Fade>

    var PrivPolicy= <Fade duration={3000} ><div className='text-left text-justify mx-6 text-gray-800 my-4 lg:my-8'>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Privacy Policy for Deege Quest</div>
     <p className=' py-5'>At Deege Quest, accessible from https://deegequest.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Deege Quest and how we use it.</p>
    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was created with the help of the <a href="https://www.generateprivacypolicy.com">Privacy Policy Generator</a>.</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Log Files</div>
    <p>Deege Quest follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Cookies and Web Beacons</div>
    <p>Like any other website, Deege Quest uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
    <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies" from Cookie Consent</a>.</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Google DoubleClick DART Cookie</div>
    <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Our Advertising Partners</div>
    <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
    <ul>
        <li>
            <p>Google</p>
            <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
        </li>
    </ul>    
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Privacy Policies</div>
    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Deege Quest.</p>
   <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Deege Quest, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
    <p>Note that Deege Quest has no access to or control over these cookies that are used by third-party advertisers.</p>
    <h2>Third Party Privacy Policies</h2>
     <p>Deege Quest's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>
    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Children's Information</div>
    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
    <p>Deege Quest does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
    <div className='text-xl text-gray-700 font-bold font-serif py-4'>Online Privacy Policy Only</div>
     <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Deege Quest. This policy is not applicable to any information collected offline or via channels other than this website.</p>
     <div className='text-xl text-gray-700 font-bold font-serif py-4'>Consent</div>
     <p className=' pb-5'>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p></div>
     </Fade>

var About= <div className='text-left text-justify mx-6 text-white my-2'>
<p>Yes, it is! And we are never too old to go back to the basics, re-learn the fundamentals in math, science & technology and appreciate how significant they are, in defining our universe and in enriching our daily lives.</p>
<p>Deege Quest is an attempt to provide some apps that will be useful in learning and testing the concepts on various topics. The site is still work in progress and will also be updated with few interactive voice and VR apps as they become available.
Thank you for taking some time to visit Deege Quest! Hope you find it interesting!</p>
<p> - rajkiran </p>
         
         
</div>

    var cardDesign = ''
    var ClsName = ''
    var Border = ''
    if (props.Type == 'Title') {
        cardDesign = <div className=" text-indigo-200 absolute-center py-2">
            <div className=" border-b-4  grid  grid-cols-1 flex justify-items-center items-center  shadow-2xl bg-opacity-70 "   >
                <div className=" mx-4 text-center  animate-pulse">
                    {props.Title}
                </div>
            </div>
        </div>
    }
    else if (props.Type == 'NumQuest') {
        //    alert(props.Border)
        if (props.CardID == '1') {
            Border = ' rounded-tr-3xl  rounded-bl-3xl hover:text-yellow-400 '
        } else if (props.CardID == '2') {
            Border = ' rounded-tl-3xl  rounded-br-3xl hover:text-red-400 '
        } else if (props.CardID == '3') {
            Border = ' rounded-tl-3xl  rounded-br-3xl hover:text-purple-400 '
        } else if (props.CardID == '4') {
            Border = ' rounded-tr-3xl  rounded-bl-3xl hover:text-green-400 '
        }
        ClsName = 'flex grid grid-cols-1 justify-items-center  h-52  bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500 shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-95 overflow-hidden ' + Border
       cardDesign = <div className='m-3 '><div className={ClsName}>
                        <div className='absolute mt-10 font-potta-one text-3xl  w-full border-b-2 animate-pulse shadow-2xl '>  {props.Title}</div>
            <div className=' fixed h-full w-full flex items-center justify-center  text-4xl font-semibold my-4'>  {props.Content}</div>

            <span className='z-0 absolute center mt-15 font-potta-one text-4xl w-full opacity-30'> {props.bTitle} </span>
            <span className='z-0  flex absolute bottom-2 center font-serif text-4xl  w-full  opacity-25  '>{props.bContent}</span>

            </div>
         </div>
        
    } else if (props.Type == 'Arithmetic') {
        cardDesign = <div className="  w-full  text-center  shadow-2xl bg-opacity-70">{props.Image}  </div>
    } else if (props.Type == 'About') {
        cardDesign = <div className='mx-2 lg:mx-20 md:mx-10 overflow-scroll'>
        <Tabs>
    <TabList>
      <Tab><span className='lg:text-xl'>About</span>  </Tab>
      <Tab><span className='lg:text-xl'>Disclaimer</span>  </Tab>
      <Tab><span className='lg:text-xl'>Privacy Policy</span>  </Tab>
    </TabList>
    <TabPanel>
        <div className='grid grid-cols-1 lg:grid-cols-2'>
         <div className='my-4 lg:my-8'>
          <Fade duration={3000} ><img src={cImg} alt="test" class="w-full  rounded-lg shadow=2xl hover:opacity-90 p-4 md:p-10 lg:p-20 md:mx-10 lg:mx-20 "></img></Fade>
         </div>
         <div className='my-4'>
           <span className='font-potta-one text-4xl w-full opacity-30'> {props.bTitle} </span>
            <span className=' font-serif  text-xl lg:text-2xl  w-full text-white '>{props.bContent}</span>
            {About}
         </div>
         
         </div>

    </TabPanel>
    <TabPanel>
        <div className=' bg-gray-200 rounded-lg shadow-2xl my-4 lg:my-8'>
          {Disclaimer}
          </div>
    </TabPanel>
    <TabPanel>
    <div className=' bg-gray-200 rounded-lg  shadow-2xl my-4 lg:my-8'>
          {PrivPolicy}
          </div>
    </TabPanel>
  </Tabs>
        </div>
    
    }  else if (props.Type === 'Main') {

        if (props.CardID === '1') {
            Border = ' rounded-tl-3xl rounded-br-3xl  '
        } else if (props.CardID === '2') {
            Border = ' rounded-tr-3xl rounded-bl-3xl '
        } else if (props.CardID === '3') {
            Border = ' rounded-bl-3xl rounded-tr-3xl '
        } else if (props.CardID === '4') {
            Border = ' rounded-br-3xl rounded-tl-3xl '
        }
        ClsName = 'flex grid grid-cols-1 justify-items-center  w-full h-60 bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500 shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-95 overflow-hidden ' + Border
        cardDesign = <div className='m-1 '><div className={ClsName} >
            <div className='absolute mt-15 py-20 font-potta-one text-3xl  w-full border-b-2 hover:animate-pulse shadow-2xl bg-red-500 text-white bg-opacity-70 '>  {props.Title}</div>
            <div className=' fixed h-full w-ful py-10 flex items-center justify-center  text-2xl font-semibold '>  {props.Content}</div>

            <span className='z-0 absolute center  font-potta-one text-4xl w-full opacity-60'> {props.bTitle} </span>
            <span className='z-0 absolute mt-16 center font-serif text-4xl  w-full  opacity-25  '>{props.bContent}</span>

        </div></div>

    }  else if (props.Type == 'SkillApp') {
           var  Img = ''
        if (props.CardID == '1') {
            Img = AImg1
        } else if (props.CardID == '2') {
            Img = AImg2
        } 
           cardDesign = <div className='m-1 rounded-2xl shadow=2xl'><div >
            <Fade duration={3000} ><img src={Img} alt="test" className="  p-4   hover:opacity-90"></img></Fade>
        </div></div>

    } else if (props.Type == 'VRApp') {
        var Img = ''
        if (props.CardID == '1') {
            Img = VImg1
        } else if (props.CardID == '2') {
            Img = VImg2
        } 
           cardDesign = <div className='m-1 rounded-2xl shadow=2xl'><div >
            <Fade duration={3000} ><img src={Img} alt="test" className="  p-4   hover:opacity-90"></img></Fade>
        </div></div>

    } 

    return (
        <div>
            {cardDesign}
        </div>
    )
}
