module.exports = {

  "Rainbow Colors" : [ 
    ["What is Violet called in Hindi?","बैंगनी ","हरा","नीला","गहरा नीला","बैंगनी "],
    ["Translate Indigo into Hindi","गहरा नीला","पीला","हरा","नीला","गहरा नीला"],
    ["The color Blue is called as ___ in Hindi ","नीला","नारंगी","पीला","हरा","नीला"],
    ["How do you say Green in Hindi?","हरा","लाल","नारंगी","पीला","हरा"],
    ["What do you call Yellow in Hindi?","पीला","बैंगनी ","लाल","नारंगी","पीला"],
    ["The color Orange means?","नारंगी","गहरा नीला","बैंगनी ","लाल","नारंगी"],
    ["The word Red means?","लाल","नीला","गहरा नीला","बैंगनी ","लाल"],
    ["What is Violet called in Hindi?","बैंगनी ","हरा","नीला","गहरा नीला","बैंगनी "],
    ["Translate Indigo into Hindi","गहरा नीला","पीला","हरा","नीला","गहरा नीला"],
    ["The color Blue is called as ___ in Hindi ","नीला","नारंगी","पीला","हरा","नीला"],
    ["How do you say Green in Hindi?","हरा","लाल","नारंगी","पीला","हरा"],
    ["What do you call Yellow in Hindi?","पीला","बैंगनी ","लाल","नारंगी","पीला"],
    ["The color Orange means?","नारंगी","गहरा नीला","बैंगनी ","लाल","नारंगी"],
    ["The word Red means?","लाल","नीला","गहरा नीला","बैंगनी ","लाल"],
    ["What is बैंगनी  called in English?","Violet","Green","Indigo","Green","Violet"],
    ["Translate गहरा नीला into English","Indigo","Red","Green","Yellow","Indigo"],
    ["नीला means","Blue","Violet","Yellow","Orange","Blue"],
    ["हरा","Green","Indigo","Yellow","Red","Green"],
    ["What is पीला called in English?","Yellow","Blue","Violet","Indigo","Yellow"],
    ["Translate नारंगी into English","Orange","Green","Violet","Indigo","Orange"],
    ["लाल means","Red","Yellow","Blue","Violet","Red"],
    ["बैंगनी ","Violet","Orange","Green","Indigo","Violet"],
    ["What is गहरा नीला called in English?","Indigo","Green","Yellow","Blue","Indigo"],
    ["Translate नीला into English","Blue","Yellow","Orange","Green","Blue"],
    ["हरा means","Green","Orange","Red","Yellow","Green"],
    ["पीला","Yellow","Red","Violet","Orange","Yellow"],
    ["What is नारंगी called in English?","Orange","Violet","Indigo","Red","Orange"],
    ["Translate लाल into English","Red","Indigo","Orange","Violet","Red"],
    ["What is बैंगनी  called in English?","Violet","Green","Indigo","Green","Violet"],
    ["Translate गहरा नीला into English","Indigo","Red","Green","Yellow","Indigo"],
    ["नीला means","Blue","Violet","Yellow","Orange","Blue"],
    ["हरा","Green","Indigo","Yellow","Red","Green"],
    ["What is पीला called in English?","Yellow","Blue","Violet","Indigo","Yellow"],
        ],

 Colors : [ 
  ["White","सफेद","चमेली","भूरा","गहरा पीला","सफेद"],
  ["Blue","नीला","बैगनी","चमेली","आसमानी नील","नीला"],
  ["yellow","पीला","काला","गहरा पीला","चाँदी","पीला"],
  ["Red","लाल","भूरा","आसमानी नील","पीतल","लाल"],
  ["Green","हरा","गहरा पीला","चमेली","फ़िरोज़ा","हरा"],
  ["Pink","गुलाबी","आसमानी नील","बैगनी","धातु","गुलाबी"],
  ["Brown","भूरा","बैगनी","काला","मिट्टी","भूरा"],
  ["Orange","नारंगी","सफेद","भूरा","सुनहरा","नारंगी"],
  ["Maroon","भूरा लाल","नीला","चमेली","जंग","भूरा लाल"],
  ["Ruby","माणिक","पीला","बैगनी","बैगनी","माणिक"],
  ["Navy Blue","गहरा नीला","लाल","काला","केसर","गहरा नीला"],
  ["Indigo","नील","हरा","भूरा","दुधिया","नील"],
  ["Coral","मूंगा","गुलाबी","गहरा पीला","पानी","मूंगा"],
  ["Beige","गहरा पीला","भूरा","आसमानी नील","गहरा लाल","गहरा पीला"],
  ["Azure","आसमानी नील","भूरा","सफेद","चमेली","आसमानी नील"],
  ["Silver","चाँदी","नारंगी","नीला","बैगनी","चाँदी"],
  ["Bronze","पीतल","भूरा लाल","पीला","काला","पीतल"],
  ["Torquoise","फ़िरोज़ा","माणिक","लाल","भूरा","फ़िरोज़ा"],
  ["Metallic","धातु","गहरा नीला","हरा","बैगनी","धातु"],
  ["Clay","मिट्टी","नील","गुलाबी","काला","मिट्टी"],
  ["Golden","सुनहरा","मूंगा","भूरा","भूरा","सुनहरा"],
  ["Rust","जंग","गहरा पीला","भूरा","सफेद","जंग"],
  ["Purple","बैगनी","आसमानी नील","नारंगी","नीला","बैगनी"],
  ["Saffron","केसर","चाँदी","भूरा लाल","पीला","केसर"],
  ["Opal","दुधिया","पीतल","माणिक","लाल","दुधिया"],
  ["Aqua","पानी","फ़िरोज़ा","गहरा नीला","हरा","पानी"],
  ["Crimson","गहरा लाल","धातु","नील","गुलाबी","गहरा लाल"],
  ["Jasmine","चमेली","मिट्टी","मूंगा","भूरा","चमेली"],
  ["Violet","बैगनी","सुनहरा","गहरा पीला","भूरा","बैगनी"],
  ["Black","काला","जंग","आसमानी नील","नारंगी","काला"],
  ["Grey","भूरा","बैगनी","चाँदी","भूरा लाल","भूरा"],
  ["सफेद","White","Opal","Bronze","Green","White"],
  ["नीला","Blue","Aqua","Torquoise","Pink","Blue"],
  ["पीला","yellow","Crimson","Metallic","Brown","yellow"],
  ["लाल","Red","Jasmine","Clay","Opal","Red"],
  ["हरा","Green","Violet","Golden","Aqua","Green"],
  ["गुलाबी","Pink","Black","Rust","Crimson","Pink"],
  ["भूरा","Brown","Grey","Purple","Jasmine","Brown"],
  ["नारंगी","Orange","Clay","Saffron","Violet","Orange"],
  ["भूरा लाल","Maroon","White","Opal","Bronze","Maroon"],
  ["माणिक","Ruby","Blue","Aqua","Torquoise","Ruby"],
  ["गहरा नीला","Navy Blue","yellow","Crimson","Metallic","Navy Blue"],
  ["नील","Indigo","Red","Jasmine","Clay","Indigo"],
  ["मूंगा","Coral","Green","Violet","Golden","Coral"],
  ["गहरा पीला","Beige","Pink","Black","Rust","Beige"],
  ["आसमानी नील","Azure","Brown","Grey","Purple","Azure"],
  ["चाँदी","Silver","Orange","Clay","Saffron","Silver"],
  ["पीतल","Bronze","Green","White","Opal","Bronze"],
  ["फ़िरोज़ा","Torquoise","Pink","Blue","Aqua","Torquoise"],
  ["धातु","Metallic","Clay","yellow","Crimson","Metallic"],
  ["मिट्टी","Clay","Opal","Red","Jasmine","Clay"],
  ["सुनहरा","Golden","Aqua","Green","Violet","Golden"],
  ["जंग","Rust","Crimson","Pink","Black","Rust"],
  ["बैगनी","Purple","Jasmine","Brown","Grey","Purple"],
  ["केसर","Saffron","Violet","Orange","Clay","Saffron"],
  ["दुधिया","Opal","Coral","Bronze","White","Opal"],
  ["पानी","Aqua","Beige","Torquoise","Blue","Aqua"],
  ["गहरा लाल","Crimson","Azure","Metallic","yellow","Crimson"],
  ["चमेली","Jasmine","Silver","Clay","Red","Jasmine"],
  ["बैगनी","Violet","Blue","Golden","Green","Violet"],
  ["काला","Black","yellow","Rust","Pink","Black"],
  ["भूरा","Gray","Red","Purple","Brown","Gray"],
],

Meanings : [ 
  ["हार","Defeat","Dirty","Slow","Day","Defeat"],
  ["जीत","Victory","clean","Friend","night","Victory"],
  ["लाभ","gain","raw","enemy","reverse","gain"],
  ["हानि","Loss","ripe","lie","Straight","Loss"],
  ["सरदी","winter","off","Truth","new","winter"],
  ["गरमी","Heat","open","low","old","Heat"],
  ["अच्छा","Good","original","More","within","Good"],
  ["बुरा","bad","fake","far","out","bad"],
  ["लेना","Take","Country","Near","Morning","Take"],
  ["देना","Give","abroad","here","Evening","Give"],
  ["अपना","My","Quick","there","Friend","My"],
  ["पराया","Alien","Morning","Laugh","enemy","Alien"],
  ["गंदा","Dirty","Defeat","Weeping","lie","Dirty"],
  ["साफ़","clean","Victory","Poor","Truth","clean"],
  ["कच्चा","raw","gain","Hot","low","raw"],
  ["पका","ripe","Loss","cold","More","ripe"],
  ["बंद","off","winter","within","far","off"],
  ["खुला","open","Heat","out","Near","open"],
  ["असली","original","Good","Morning","here","original"],
  ["नकली","fake","bad","Evening","there","fake"],
  ["देश","Country","Take","raw","Laugh","Country"],
  ["विदेश","abroad","Give","ripe","Weeping","abroad"],
  ["तेज","Quick","My","off","Hot","Quick"],
  ["धीरे","Slow","Alien","open","cold","Slow"],
  ["मित्र","Friend","within","Defeat","Dirty","Friend"],
  ["शत्रु","enemy","out","Victory","clean","enemy"],
  ["झूठ","lie","Morning","gain","raw","lie"],
  ["सच","Truth","Evening","Loss","ripe","Truth"],
  ["कम","low","Poor","winter","off","low"],
  ["ज्यादा","More","Hot","Heat","open","More"],
  ["दूर","far","cold","Good","original","far"],
  ["पास","Near","within","bad","fake","Near"],
  ["इधर","here","out","Take","Country","here"],
  ["उधर","there","Morning","Give","abroad","there"],
  ["हंसना","Laugh","Evening","My","Quick","Laugh"],
  ["रोना","Weeping","reverse","Alien","ripe","Weeping"],
  ["ऊँचा","high","Slow","Dirty","Defeat","high"],
  ["नीचा","low","Friend","clean","Victory","low"],
  ["दिन","Day","enemy","raw","gain","Day"],
  ["रात","night","lie","ripe","Loss","night"],
  ["उल्टा","reverse","Truth","off","winter","reverse"],
  ["सीधा","Straight","low","open","Heat","Straight"],
  ["नया","new","More","original","Good","new"],
  ["पुराना","old","far","fake","bad","old"],
  ["अमीर","Rich","Near","Country","Take","Rich"],
  ["गरीब","Poor","here","abroad","Give","Poor"],
  ["गरम","Hot","there","Quick","My","Hot"],
  ["ठंडा","cold","Laugh","Day","Alien","cold"],
  ["अंदर","within","Weeping","night","far","within"],
  ["बाहर","out","Heat","reverse","Near","out"],
  ["सुबह","Morning","Good","Straight","here","Morning"],
  ["शाम","Evening","bad","new","there","Evening"],
  ["Defeat","हार","अंदर","ऊँचा","कच्चा","हार"],
  ["Victory","जीत","बाहर","नीचा","पका","जीत"],
  ["gain","लाभ","सुबह","दिन","बंद","लाभ"],
  ["Loss","हानि","शाम","रात","खुला","हानि"],
  ["winter","सरदी","झूठ","उल्टा","असली","सरदी"],
  ["Heat","गरमी","सच","सीधा","नकली","गरमी"],
  ["Good","अच्छा","कम","नया","देश","अच्छा"],
  ["bad","बुरा","ज्यादा","पुराना","विदेश","बुरा"],
  ["Take","लेना","दूर","अमीर","तेज","लेना"],
  ["Give","देना","पास","गरीब","धीरे","देना"],
  ["My","अपना","इधर","गरम","मित्र","अपना"],
  ["Alien","पराया","उधर","ठंडा","शत्रु","पराया"],
  ["Dirty","गंदा","हंसना","अंदर","ऊँचा","गंदा"],
  ["clean","साफ़","रोना","बाहर","नीचा","साफ़"],
  ["raw","कच्चा","हार","सुबह","दिन","कच्चा"],
  ["ripe","पका","जीत","शाम","रात","पका"],
  ["off","बंद","लाभ","अपना","उल्टा","बंद"],
  ["open","खुला","हानि","पराया","सीधा","खुला"],
  ["original","असली","सरदी","गंदा","नया","असली"],
  ["fake","नकली","गरमी","साफ़","पुराना","नकली"],
  ["Country","देश","अच्छा","सच","अमीर","देश"],
  ["abroad","विदेश","बुरा","कम","गरीब","विदेश"],
  ["Quick","तेज","लेना","ज्यादा","गरम","तेज"],
  ["Slow","धीरे","देना","दूर","ठंडा","धीरे"],
  ["Friend","मित्र","अपना","पास","अंदर","मित्र"],
  ["enemy","शत्रु","पराया","इधर","बाहर","शत्रु"],
  ["lie","झूठ","गंदा","उधर","सुबह","झूठ"],
  ["Truth","सच","साफ़","हंसना","शाम","सच"],
  ["low","कम","कच्चा","रोना","अपना","कम"],
  ["More","ज्यादा","पका","जीत","पराया","ज्यादा"],
  ["far","दूर","बंद","लाभ","गंदा","दूर"],
  ["Near","पास","खुला","हानि","साफ़","पास"],
  ["here","इधर","असली","सरदी","खुला","इधर"],
  ["there","उधर","नकली","गरमी","सच","उधर"],
  ["Laugh","हंसना","देश","अच्छा","कम","हंसना"],
  ["Weeping","रोना","विदेश","बुरा","ज्यादा","रोना"],
  ["high","ऊँचा","तेज","लेना","दूर","ऊँचा"],
  ["low","नीचा","धीरे","देना","पास","नीचा"],
  ["Day","दिन","मित्र","अपना","इधर","दिन"],
  ["night","रात","शत्रु","पराया","उधर","रात"],
  ["reverse","उल्टा","ऊँचा","कच्चा","हंसना","उल्टा"],
  ["Straight","सीधा","नीचा","पका","रोना","सीधा"],
  ["new","नया","दिन","बंद","हार","नया"],
  ["old","पुराना","रात","खुला","जीत","पुराना"],
  ["Rich","अमीर","उल्टा","असली","लाभ","अमीर"],
  ["Poor","गरीब","सीधा","नकली","हानि","गरीब"],
  ["Hot","गरम","नया","देश","सरदी","गरम"],
  ["cold","ठंडा","पुराना","विदेश","गरमी","ठंडा"],
  ["within","अंदर","अमीर","तेज","अच्छा","अंदर"],
  ["out","बाहर","गरीब","धीरे","बुरा","बाहर"],
  ["Morning","सुबह","गरम","मित्र","लेना","सुबह"],
  ["Evening","शाम","ठंडा","शत्रु","देना","शाम"],

],

Opposites : [ 
  ["अच्छा","बुरा","ज्यादा","गरमी","देना","बुरा"],
  ["अंदर","बाहर","साफ़","शाम","गरमी","बाहर"],
  ["अपना","पराया","ठंडा","रोना","शाम","पराया"],
  ["अमीर","गरीब","सच","जीत","विदेश","गरीब"],
  ["असली","नकली","धीरे","पक्का","बुरा","नकली"],
  ["इधर","उधर","रात","विदेश","बाहर","उधर"],
  ["उल्टा","सीधा","देना","ज्यादा","पराया","सीधा"],
  ["ऊँचा","नीचा","गरमी","साफ़","गरीब","नीचा"],
  ["कच्चा","पक्का","शाम","ठंडा","नकली","पक्का"],
  ["कम","ज्यादा","बुरा","सच","उधर","ज्यादा"],
  ["गंदा","साफ़","बाहर","धीरे","सीधा","साफ़"],
  ["गरम","ठंडा","पराया","रात","नीचा","ठंडा"],
  ["झूठ","सच","गरीब","पास","पक्का","सच"],
  ["तेज","धीरे","नकली","विदेश","ज्यादा","धीरे"],
  ["दिन","रात","उधर","पुराना","साफ़","रात"],
  ["दूर","पास","सीधा","खुला","ठंडा","पास"],
  ["देश","विदेश","नीचा","शत्रु","सच","विदेश"],
  ["नया","पुराना","पक्का","हानि","धीरे","पुराना"],
  ["बंद","खुला","देना","बुरा","रात","खुला"],
  ["मित्र","शत्रु","गरमी","बाहर","पास","शत्रु"],
  ["लाभ","हानि","शाम","पराया","विदेश","हानि"],
  ["लेना","देना","पास","गरीब","पुराना","देना"],
  ["सरदी","गरमी","विदेश","नकली","खुला","गरमी"],
  ["सुबह","शाम","पुराना","उधर","शत्रु","शाम"],
  ["हंसना","रोना","खुला","सीधा","हानि","रोना"],
  ["हार","जीत","शत्रु","नीचा","हानि","जीत"],
  ["बुरा","अच्छा","मित्र","तेज","इधर","अच्छा"],
  ["बाहर","अंदर","लाभ","दिन","उल्टा","अंदर"],
  ["पराया","अपना","लेना","दूर","ऊँचा","अपना"],
  ["गरीब","अमीर","सरदी","देश","कच्चा","अमीर"],
  ["नकली","असली","सुबह","नया","कम","असली"],
  ["उधर","इधर","हंसना","बंद","गंदा","इधर"],
  ["सीधा","उल्टा","हार","मित्र","गरम","उल्टा"],
  ["नीचा","ऊँचा","अच्छा","लाभ","झूठ","ऊँचा"],
  ["पक्का","कच्चा","अंदर","लेना","तेज","कच्चा"],
  ["ज्यादा","कम","अपना","सरदी","दिन","कम"],
  ["साफ़","गंदा","अमीर","सुबह","दूर","गंदा"],
  ["ठंडा","गरम","असली","हंसना","देश","गरम"],
  ["सच","झूठ","इधर","हार","नया","झूठ"],
  ["धीरे","तेज","उल्टा","झूठ","बंद","तेज"],
  ["रात","दिन","ऊँचा","अच्छा","मित्र","दिन"],
  ["पास","दूर","कच्चा","अंदर","लाभ","दूर"],
  ["विदेश","देश","कम","अपना","लेना","देश"],
  ["पुराना","नया","गंदा","अमीर","सरदी","नया"],
  ["खुला","बंद","गरम","असली","सुबह","बंद"],
  ["शत्रु","मित्र","झूठ","इधर","हंसना","मित्र"],
  ["हानि","लाभ","तेज","उल्टा","हार","लाभ"],
  ["देना","लेना","दिन","ऊँचा","अच्छा","लेना"],
  ["गरमी","सरदी","दूर","कच्चा","अंदर","सरदी"],
  ["शाम","सुबह","देश","कम","अपना","सुबह"],
  ["रोना","हंसना","नया","गंदा","अमीर","हंसना"],
  ["जीत","हार","बंद","गरम","असली","हार"],
  

],


}