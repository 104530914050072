import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';

import DocumentMeta from 'react-document-meta';
import Header from './components/Header'
import Home from './views/Home'
import About from './views/About'
import Contact from './views/Contact'
import Apps from './views/Apps'
import Error from './components/Error'
import Footer from './components/Footer'
import NumQuest from './views/NumQuest';
import Arithmetic from './views/Arithmetic';
import SkillApp from './views/SkillApp';
import Tbd from './views/Tbd';
import Alexa  from './views/Alexa';
import Animtest from './views/Animtest'
import QCalc from './views/QCalc'
import MissingNumbers from './views/MissingNumbers'
import TestClient from './views/testclient'
import TestMissingNumbers from './views/TestMissingNumbers'
import MathVideos from './views/MathVideos'
import WordQuest from './views/WordQuest'
import QuizMain from './views/QuizMain'
import QuizPortal from './views/QuizPortal';
import Test from './views/Test';

function App () {


  const meta = {
    title: 'Deege Quest',
    description: 'Learning is always fun! Yes, it is! And we are never too old to go back to the basics, re-learn the fundamentals in math, science & technology and appreciate how significant they are, in defining our universe and in enriching our daily lives. Deege Quest is an attempt to provide some apps that will be useful in learning and testing the concepts on various topics.',
    canonical: 'https://deegequest.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'deegequest, learning, tutorial, apps, quest, quiz'
        }
    }
  }


   


  
    return (

        <div className="App align-middle flex flex-col h-screen">
        <DocumentMeta {...meta} />
          <Router>
            <Header />
          
  
            <div className="p-3 flex-1  overflow-y-auto">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route   path="/md=:mode">
              <Home />
            </Route>
            <Route exact path="/apps">
              <Apps />
            </Route>
            <Route exact path="/numquest">
              <NumQuest />
            </Route>
            <Route exact path="/numquest/arithmetic" >
              <Arithmetic />
            </Route>
            <Route exact path="/numquest/mathvideos" >
            <MathVideos />
            </Route>
            <Route  path="/numquest/arithmetic/:mode?/:lvl?" >
              <Arithmetic  />
            </Route>
            <Route exact path="/wordquest">
            <WordQuest />
            </Route>
            <Route exact path="/skillapp">
              <SkillApp />
            </Route>
          <Route exact path="/quiz/portal/:mode/:lvl" >
            <QuizPortal />
          </Route>
          <Route  path="/quiz" >
           <QuizMain  />
          </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>   
            <Route path="/alexa/:skill?">
              <Alexa />
            </Route>  
            <Route path="/tbd">
              <Tbd />
            </Route>  
            <Route path="/Animtest">
              <Animtest />
            </Route>  
            <Route path="/QCalc">
              <QCalc />
            </Route>  
            <Route path="/MissingNumbers">
              <MissingNumbers />
            </Route>  
            <Route path="/TestMissingNumbers">
              <TestMissingNumbers />
            </Route>  
            <Route path="/TestClient">
              <TestClient />
            </Route>  
          <Route exact path="/test/:mode/:lvl" >
          <Test />
        </Route>
            <Route>
              <Error />
            </Route>
          </Switch>
          </div>


            <Footer />

          </Router>

        </div>

    );
  }


export default App;