import React, { useState } from 'react';
import cImg from '../images/csoon-11.png'
import Fade from 'react-reveal/Fade'
import CookieConsent from '../components/Cookie'


function Tbd() {
  const [Msg] = useState('Learning is always fun!')
  const [Img] = useState(<div className='grid grid-cols-1  flex m-2'><Fade duration={3000} ><img src={cImg} alt="test" class="w-full p-4 rounded-lg shadow=2xl hover:opacity-90 h-1/4"></img></Fade></div>)

  return (

    <div className="flex h-screen justify-center items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >

     
<div class=" text-2xl  m-auto h-92 grid grid-cols-1 flex justify-items-center absolute center text-gray-50  items-center center shadow-2xl opacity-90 w-3/4 lg:w-3/5 rounded-2xl">
   This Page Will Be Updated Soon!
   {Img}
</div>




      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
       
      </div> </Fade>


      <CookieConsent />
    </div>





  );
}

export default Tbd;