import React from 'react';
import ReactDOM from 'react-dom'
import { motion, useMotionValue, useTransform, useSpring, useMotionTemplate } from "framer-motion"
import Btn from '../components/Button'
import { Link } from 'react-router-dom'
import Draggable from 'react-draggable'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Bounce from 'react-reveal/Bounce'
import HeadShake from 'react-reveal/HeadShake'
import CookieConsent from '../components/Cookie'


export default class Animtest extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            NumVal: 0,
            Xpos: 0,
            activeDrags: 0,
            deltaPosition: {
                x: 0, y: 0
            },
            absPosition: {
                x: 0, y: 0
            },
            numBoxPos: {
                a: 0, b: 0, c: 0, d: 0
            },
            width: 0,
            height: 0,
            Stage: 'STARTED',
            dwidth: window.innerWidth,
            dheight: window.innerHeight,
        }

    }

    DisplayKeys() {
        const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0','C','ENTR']
        var keyb = '';
        if (this.state.Stage == 'START') {
            keyb = <div className=" grid grid-cols-1 rounded-md text-2xl md:text-3xl lg:text-3xl ">
                <div className=" p-2 rounded-md text-left ">
                    <Fade top>
                        <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
                            Choose the desired Skill / Level and You will be presented with 10 Random Questions!
              </div></Fade>
                </div>
            </div>
        } else {
            keyb = keys.map(key => (


                  <div className=' bg-blue-600 hover:bg-red-500  text-gray-200  hover:text-white
                  rounded-lg shadow-2xl  px-4 py-2 m-2 '>
                    <motion.div 
                        drag
                        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                        dragElastic={1}
                        whileDrag={{ scale: 2 }}
                        whileHover={{ scale: 1.5 }}
                    >

                        <button className=" focus:outline-none text-2xl lg:text-4xl  "
                            onClick={() => this.Input(key)}


                        >{key}</button>

                    </motion.div>
                    </div>
            ))
        }
        return (
            <div className='grid lg:grid-cols-3 grid-cols-4 m-4' >
                { keyb}
            </div>
            
        );
    }


    Input(k) {

        console.log('Button clicked' + k);


        return (
            <div>
                <h1>Hello, world!</h1>
                <h2>It is .</h2>
            </div>
        );
    }

    BackXpos() {
        this.setState({ Xpos: (this.state.Xpos == 0 ? this.state.Xpos : this.state.Xpos - 10) })
    }

    ForwardXpos() {
        this.setState({ Xpos: (this.state.Xpos == 300 ? this.state.Xpos : this.state.Xpos + 10) })
    }

    handleEvent = (e, data) => {
        console.log('Event Type', e.type);
        console.log(e, data);
    }


    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
    }

    handleStop = (e, ui) => {
        const { x, y } = this.state.absPosition;
        this.setState({
            absPosition: {
                x: ui.x,
                y: ui.y,
            }
        });
    }

    updateDimensions = () => {
        this.setState({ dwidth: window.innerWidth, dheight: window.innerHeight });
    };

    componentDidMount() {
        this.setState({ dwidth: window.innerWidth, dheight: window.innerHeight });
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    setTextInputRef = element => {
        this.textInput = element;
        console.log(element)
        //   alert(element.clientWidth + ' ' +  element.clientHeight + ' ' + element.offsetTop + ' ' + element.offsetLeft 
        //  + ' ' + element.offsetHeight+ ' '+ element.offsetWidth)
    };


    render() {
        const { deltaPosition, absPosition } = this.state;

        return (
            <div className="relative h-full " >
                <div className=" grid lg:grid-cols-6  grid-cols-1 lg:gap-4 h-full  shadow-3xl bg-gray-400  mx-auto bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ">
                    window width: {this.state.dwidth}  window height:{this.state.dheight}

                    <div className='lg:col-start-1 lg:col-span-1 shadow-3xl lg:ml-5 m-auto h-5/6 w-11/12  md:w-7/8 rounded-lg '>
                        <div id='NavCtrl' className=" text-indigo-200  h-full m-auto grid grid-cols-1  ">
                            <div className="  grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-self-center   bg-blue-600 rounded-2xl "   >
                                <div className=" text-center  lg:px-58 ">
                                    <div className="grid grid-cols-4 lg:grid-cols-1 shadow-2xl  " >
                                        <div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content="Up" ></Btn>
                                        </div>
                                        <Link to='/md=1'><div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content='Home' />
                                        </div></Link>
                                        <div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content="List" />
                                        </div>
                                        <div className="flex-1"  >
                                            <Btn Card='AnimBtn' Content="Dn" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className=' lg:col-start-2 lg:col-span-4  shadow-2xl bg-blue-600 mx-auto lg:m-auto lg:h-5/6 w-11/12 lg:w-full md:w-7/8 rounded-lg'>

                        <div id='MainCtrl' className=" text-indigo-200  h-full m-auto grid grid-cols-1 ">
                            <div className='text-white lg:text-6xl text-2xl font-bold w-full h-10 py-4 '> ADDITION</div>
                            <div className='w-full h-full place-self-center'>

                                <div className='grid grid-cols-1 shadow-2xl mb-10'>
                                    <motion.div className='text-red-400 lg:text-6xl text-2xl font-lobster w-1/2 pb-10 lg:pb-20 place-self-center  '
                                        animate={{ scale: 2 }}
                                    >

                                        <span className='text-yellow-400 p-2 border-white border-b-2 lg:text-6xl text-4xl my-4'>?</span>
                                        <span className=' p-4 font-potta-one lg:text-6xl text-4xl my-4'>+</span>
                                        <span className='text-yellow-400 p-2 border-white border-b-2 lg:text-6xl text-4xl  my-4'>?</span>






                                    </motion.div>
                                    <motion.div className='text-yellow-400 lg:text-6xl text-4xl font-potta-one w-1/4 py-10 mb-10 lg:mb-20 place-self-center'
                                        animate={{ scale: 2 }}
                                        whileHover={{ scale: 2.2 }}
                                    >
                                        110
                                      </motion.div>

                                </div>


                            </div>



                        </div>
                    </div>



                    <div id="numBox" className=' lg:col-start-6 lg:col-span-1  shadow-2xl lg:mr-5 m-auto bg-blue-600 h-5/6 w-11/12 md:w-7/8 rounded-lg'>
                        <div className=' m-4 bg-blue-300 rounded-2xl shadow-2xl  text-white 
                                         '  >
                            {this.DisplayKeys()}
                        </div>
                

                    </div>


                </div>
            </div>
        )
    }



}