import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import Modal from '../components/Modal.js'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Bounce from 'react-reveal/Bounce'
import HeadShake from 'react-reveal/HeadShake'
import CookieConsent from '../components/Cookie'


function Arithmetic(props) {
  const { lvl } = useParams()
  const { mode } = useParams()

  //alert(mode+lvl)
  const ops = ['', '+', '-', '*', '/']
  const opt = ['Mode', 'Addition', 'Subtraction', 'Multiplication', 'Division']
  const opts = ['Mode', 'Plus', 'Minus', 'Multiplied by', 'Divided by']
  var imode = opt.indexOf(mode)
  // alert(imode+opt[imode])
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'CLR', 'ENTR', '+/-', '.']
  const [Count, setCount] = useState(0)
  const [Score, setScore] = useState(0)
  const [WScore, setWScore] = useState(0)
  const [SScore, setSScore] = useState(0)
  const [Mode, setMode] = useState((imode > -1 && imode < 5) ? imode : 1)
  const [uAns, setuAns] = useState('')
  const [Qn, setQn] = useState('')
  const [TQn, setTQn] = useState('')
  const [Stage, setStage] = useState('START')
  const [Level, setLevel] = useState((lvl > -1 && lvl < 4) ? lvl : 0)
  const [Result, setResult] = useState('Test Your Math Skill!')
  const [Submit, setSubmit] = useState(false)
  const [MsgBoard, setMsgBoard] = useState(<div className='fas fa-medal  text-gray-400'></div>)
  const [IsOpen, setIsOpen] = useState(false)
  const [ScoreCard, setScoreCard] = useState(false)
  const [IsMute, setIsMute] = useState(false)
  const [MuteBtn, setMuteBtn] = useState(<span class='fas fa-volume-up text-xl text-gray-700 transition duration-500 ease-in-out  hover:text-white' ></span>)
  const Synthref = React.useRef(window.speechSynthesis);



  const options = [
    { value: 1, label: <div><span className='fas fa-plus bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Addition</span></div> },
    { value: 2, label: <div><span className='fas fa-minus bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Subtraction</span></div> },
    { value: 3, label: <div><span className='fas fa-times bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Multiplication</span></div> },
    { value: 4, label: <div><span className='fas fa-divide bg-gray-300  text-yellow-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Division</span></div> },
  ];

  const levels = [
    { value: 0, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Level 0</span></div> },
    { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Level 1</span></div> },
    { value: 2, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Level 2</span></div> },
    { value: 3, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Level 3</span></div> },
  ];





  var voices = window.speechSynthesis.getVoices();
  var UVoices = voices
    //     .filter(voice => voice.name.includes("Samantha") || voice.name.includes("Alex"))
    .filter(voice => voice.lang.includes("en-US"))
    .map(voice => voice);




  function onOptChange(value) {
    setMode(value)
    setCount(0)
    setScore(0)
    setWScore(0)
    setSScore(0)
    setQn('');
    setTQn('');
    setStage('START')
    setuAns('')
    setResult('Test Your Math Skill!')
    Speak(opt[value])
    //     console.log(value)
  }
  function onLvlChange(value) {
    setLevel(value)
    setCount(0)
    setScore(0)
    setWScore(0)
    setSScore(0)
    setQn('');
    setTQn('');
    setStage('START')
    setuAns('')
    setResult('Test Your Math Skill!')
    Speak('Level ' + value)
    //   console.log(value)
  }
  function SetGo() {
    setLevel(0)
    setMode(1)
    setCount(0)
    setScore(0)
    setWScore(0)
    setSScore(0)
    setQn('');
    setTQn('');
    setStage('START')
    setuAns('')
    setResult('Test Your Math Skill!')
    setIsOpen(false)
  }


  function Speak(x) {
    //alert(x + '=> ' + TQn + '-> '+IsMute)
    if (x != TQn && IsMute) {
      x = ''
    } else {
      x = x.replace(':', ' is ')
    }

    const utter = new SpeechSynthesisUtterance(x)
    var randVoice = UVoices[Math.floor(Math.random() * UVoices.length)];
    utter.voice = randVoice;

    //   alert(utter.voice.name+utter.voice.lang)
    Synthref.current.speak(utter)

  }

  function SetAudio() {
    setIsMute(prevIsMute => IsMute ? false : true)
    setMuteBtn(IsMute ? <span class='fas fa-volume-up text-xl text-gray-700 transition duration-500 ease-in-out  hover:text-white' ></span> : <span class='fas fa-volume-mute text-xl text-gray-700 transition duration-500 ease-in-out  hover:text-white' ></span>)
    Speak(IsMute ? 'Unmuted' : 'Muted')
  }

  function ShowReader() {

    return (
      TQn == '' ? '' : <button class='fas fa-volume-up text-xl lg:text-yellow-500 md:text-yellow-500  text-red-500 ' onClick={() => Speak(TQn)}></button>

    )
  }
  function Input(k) {

    console.log('Button clicked' + k);
    if (Stage == 'Next' && !Submit) {
      if (k == '.') {
        if (uAns.includes('.')) {
          setuAns(uAns);
        } else {
          setuAns(prevuAns => (prevuAns == '?') ? k : prevuAns + k);
        }
      } else if (k === '+/-') {
        if (uAns.includes('-')) {
          setuAns(prevuAns => (prevuAns == '-') ? '?' : prevuAns.replace('-', ''));
        } else {
          setuAns(prevuAns => (prevuAns == '?') ? '-' : '-' + prevuAns);
        }
      } else if (k == '0') {
        if ((uAns.substr(0, 1) == '-' && uAns.length == 1) || uAns == '0') {
          setuAns(uAns);
        } else {
          setuAns(prevuAns => (prevuAns == '?') ? k : prevuAns + k);

        }
      } else if (k == 'CLR') {
        setuAns('?');

      } else if (k == 'ENTR') {
        setSubmit(true);
        ValidateAns();
      } else if (uAns.length > 10) {
        setuAns(uAns);
      }
      else {
        setuAns(prevuAns => (prevuAns == '?' || prevuAns == '0') ? k : prevuAns + k);
      }
    }

    return (
      <div>
        <h1>Hello, world!</h1>
        <h2>It is .</h2>
      </div>
    );
  }


  function DisplayKeys() {
    var keyb = '';
    if (Stage == 'START') {
      keyb = <div className=" grid grid-cols-1 flex rounded-md text-2xl md:text-3xl lg:text-3xl ">
        <div className='  rounded-lg shadow-2xl'>
          <Flip top><div className="  flex  justify-start" >
            <div className=' mx-4 p-2 my-1 hover:text-gray-50 w-full'>
              <i class="fa fa-cog text-red-500   cursor-pointer hover:text-gray-50 shadow-inner" onClick={SetMode}></i>
              <span className=" ml-4 uppercase  text-indigo-700 hover:text-gray-50  font-potta-one">{opt[Mode]}</span></div>
          </div></Flip>

          <Flip top >  <div className="  flex  justify-start font-potta-one " >
            <div className=' mx-4 p-2 my-1 w-full '>
              <span class="fa fa-layer-group  text-yellow-500  hover:text-gray-50 cursor-pointer " onClick={SetLevel}></span>
              <span className=" ml-4 text-indigo-700 hover:text-gray-50 ">Level {Level}</span></div>
          </div></Flip>
        </div>


        <div className=" p-2 rounded-md text-left ">
          <Fade top>
            <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
              Choose the desired Skill / Level and You will be presented with 10 Random Questions!
          </div></Fade>
        </div>
      </div>
    } else {
      keyb = keys.map(key => (
        <button className=" focus:outline-none p-2 m-1 text-xl lg:text-2xl bg-blue-700 w-1/5 transition duration-500 ease-in-out 
         hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 rounded-2xl shadow-2xl"
          onClick={() => Input(key)}  >{key}</button>
      ))
    }
    return (
      <div >
        { keyb}

      </div>
    );
  }

  function SetControls() {

    var s = <button className='bg-red-700 text-white  m-4 text-xl p-2 shadow-2xl rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => NextQn()} >
      {Stage}
    </button>
    if (ScoreCard) {
      s = <div><button className='bg-red-700 text-white  m-4 text-xl p-2 shadow-2xl rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => NextQn()} >
        {Stage}</button><button className='bg-green-700 text-white  m-4 text-xl p-2 shadow-2xl rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => setIsOpen(true)}>SCORE</button></div>
    }

    return (
      <div>{s}</div>
    )

  }
  function SetMode() {
    if (IsOpen) {
      return;
    } else {
      setMode(prevMode => prevMode > 3 ? 1 : prevMode + 1);
      Speak(opt[Mode > 3 ? 1 : Mode + 1])
      setCount(0)
      setScore(0)
      setWScore(0)
      setSScore(0)
      setQn('');
      setTQn('');
      setStage('START')
      setuAns('')
      setResult('Test Your Math Skill!')
    }
    return (
      <div>
        <h1>Hello, Mode!</h1>
        <h2>It is ops[{Mode}]</h2>
      </div>
    );

  }


  function SetLevel() {
    if (IsOpen) {
      return;
    } else {
      setLevel(prevLevel => prevLevel > 2 ? 0 : prevLevel + 1);
    //  alert(Level)
      Speak('Level ' + eval(Level > 2 ? 0 : Level + 1))
      setCount(0)
      setScore(0)
      setWScore(0)
      setSScore(0)
      setQn('');
      setStage('START')
      setuAns('')
      setResult('Test Your Math Skill!')
    }
    return (
      <div>
        <h1>Hello, Level!</h1>

      </div>
    );

  }

  function SetDash() {
    var mesg = (Score > 8 ? "Excellent!" : (Score > 6 ? "Good!" : ""))

    var s1 = <Fade top><div className=" grid  grid-cols-3  flex justify-items-center m-4 text-center  items-baseline ">
      <i className="text-2xl lg:text-4xl font-bold text-red-400 p-2 " ><span className=' text-green-900'>
        <span className='fas fa-question-circle text-red-700'><span className='p-2  text-white'>{Count}/10</span></span></span></i>
      <span className='uppercase font-2xl font-serif font-extrabold  text-yellow-900'>{opt[Mode]} </span>
      <i className="text-2xl lg:text-4xl   font-bold text-red-400 p-2  ">  <span className='fas fa-award text-green-900  '>
        <span className="text-red-200 p-2">{Score} {mesg}</span> </span> </i>
    </div></Fade>
    if (Count == 0) {
      s1 = <div className=" grid  grid-cols-3  flex justify-items-center m-4 text-center  items-baseline ">
        <i className="text-2xl lg:text-4xl font-semibold text-red-400 p-2 " ><span className=' text-green-900'>
          <span className='fas fa-question-circles text-red-600'><span className='p-2  text-green-700'></span></span></span></i>
        <span className='uppercase font-bold font-2xl text-yellow-900'> </span>
        <i className="text-2xl lg:text-4xl   font-bold text-red-400 p-2  ">  <span className='fas fa-awards text-green-900  '>
          <span className="text-red-600 p-2"></span> </span> </i>
      </div>
    }
    return (
      <div>{s1} </div>
    )
  }

  function SetResult() {
    var res;
    if (Result.includes('RIGHT!')) {
      res = <Bounce><div className="text-xl md:text-2xl lg:text-3xl fas fa-check text-green-500"> <span className='text-green-700'>{Result}</span></div></Bounce>
    } else if (Result.includes('WRONG')) {
      res = <HeadShake><div className="text-xl md:text-2xl lg:text-3xl fas fa-times text-red-900"> <span className='text=red-800 '>{Result}</span></div></HeadShake>
    } else if (Result.includes('round')) {
      res = <Fade><div className=" text-xl fas fa-hand-point-right text-green-900"> <span className='text-white  font-serif text-xs'>{Result}</span></div></Fade>
    } else {
      res = <Flip top ><div className=" text-2xl fas fa-border-none text-red-500 shadow-2xl bg-opacity-25">
        <span className=' text-yellow-100 text-xl md:text-2xl lg:text-4xl font-lobster '>{Result}</span>
      </div></Flip>

    }
    //       alert(res);
    return (
      <div>{res}</div>

    )
  }

  function SetMsgBoard() {

    return (
      <div className="text-white text-2xl grid grid-cols-1 ">

        <Bounce duration='5000'><div className="m-1  font-lobster text-2xl md:text-3xl lg:text-4xl  font-bold">
          {MsgBoard}
        </div></Bounce>
        <div className="mt-4 mb-4 p-2 bg-yellow-500 rounded-xl grid grid-cols-1 font-mono flex justify-items-start text-xl ">
          <div> <span className='font-serif uppercase font-extrabold '>Score Card</span> </div>
          <div><span className='left-10 p-10 text-red-700'>Questions </span> <span className=' pr-3'>Total : {Count}</span>  </div>
          <Fade duration='4000'><div><span className='text-green-600'>Answers</span><div>
          </div><span className='pl-20'>Correct :{Score}</span> </div>
            <div><span className='pl-20'>*Wrong*     :{WScore}</span> </div>
            <div><span className='pl-20'>Skipped   :{SScore}</span> </div></Fade>
        </div>
      </div>
    )
  }



  function NextQn() {

    if (Count > 9 && !Stage.includes('Try')) {

      //alert('alert0 '+Count+' '+Stage+' '+Score+' '+WScore+' '+SScore);
      var Msg = 'Well Tried!'
      var Msg = Score > 8 ? <div className='fas fa-medal  text-yellow-500'>
        <div className='text-white' >Excellent!</div></div> : Score > 6 ? <div className='fas fa-medal  text-green-600'>
          <div className='text-white' >Well Done! </div></div> : <div className='fas fa-medal  text-red-400'>
            <div className='text-white' >Well Tried!</div></div>
      //alert(Msg)
      setMsgBoard(Msg)
      setStage('Try Again')
      setScore(prevScore => Count > 10 ? 0 : prevScore);
      setWScore(prevWScore => Count > 10 ? 0 : prevWScore);
      setSScore(prevSScore => (!Submit && Count < 11 && Count > 0) ? prevSScore + 1 : prevSScore);
      if (!Submit && Count < 11 && Count > 0) {
        Speak('Skipping')
      }
      setScoreCard(true)
      setTimeout(function () { setIsOpen(true) }, 2000);

    }
    else {
      //alert(Count+' '+Stage+' '+Score+' '+WScore+' '+SScore);
      setCount(prevCount => prevCount == 10 ? 1 : prevCount + 1);
      setScore(prevScore => Count == 10 ? 0 : prevScore);
      setWScore(prevWScore => Count == 10 ? 0 : prevWScore);
      setSScore(prevSScore => (!Submit && Count < 10 && Count > 0) ? prevSScore + 1 : Stage.includes('Try') ? 0 : prevSScore);
      if (!Submit && Count < 10 && Count > 0) {
        Speak('Skipping')
      }
      ServeQn();
      setStage('Next');
      // }

    }
    return (
      <div>
        <h1>Hello, Qn!</h1>
        <h2>It is {Count} question</h2>
      </div>
    );

  }






  function ValidateAns() {
    //  var Ans = uAns == '?' ? 0 : uAns;
    // alert('uAns'+ uAns)
    var Ans = uAns;
    var QAns = eval(Qn);
    //       alert('QAns '+QAns+'==> '+QAns%1+'====> '+(QAns%1).toString().length)
    var rAns = Ans == '?' ? 0 : Number(Ans).toFixed(2);
    //   var rQAns = uAns == '?' ? uAns : Number(QAns).toFixed(2);
    var rQAns = Number(QAns).toFixed(2);
    var res = '';
    //  alert(Ans+' '+QAns+' '+rAns+' '+rQAns + ' '+RemLength)    
    if (ops[Mode] == '/' && (QAns % 1).toString().length > 4) {
      //    alert(rAns + ' '+rQAns)
      res = (rAns == rQAns ? 'RIGHT!' : `WRONG! (Answer: ${rQAns})`)
    }
    else {
      //   alert(rAns + ' '+rQAns)
      res = (Ans == QAns ? 'RIGHT!' : `WRONG! (Answer: ${QAns})`)
    }


    setResult(res);
    setScore(prevScore => res == 'RIGHT!' ? prevScore + 1 : prevScore);
    setWScore(prevWScore => res == 'RIGHT!' ? prevWScore : prevWScore + 1);
    Speak(res);
    //          alert(Qn+uAns+Ans+QAns+res);
    if (Count > 9) {
      var Msg = Score > 8 ? <div className='fas fa-medal  text-yellow-500 mb-4'>
        <div className='text-white  py-4' >Excellent!</div></div> : Score > 6 ? <div className='fas fa-medal  text-green-600 mb-4'>
          <div className='text-white py-4' >Well Done! </div></div> : <div className='fas fa-medal  text-red-400 mb-4'>
            <div className='text-white py-4' >Well Tried!</div></div>
      setMsgBoard(Msg)
      Speak(Score > 8 ? 'Excellent!' : Score > 6 ? 'Well Done!' : 'Well Tried!')
      setStage('Try Again')
      setScoreCard(true)
      setTimeout(function () { setIsOpen(true) }, 2000);
    }
    return (
      <div>
        <h1>Hello, ValidateAns! {res} </h1>
        <h2>You Answered {Ans} <p>Correct Answer: {QAns} </p></h2>
      </div>
    );

  }



  const ServeQn = () => {
    var num1 = 0;
    var num2 = 0;
    var num3 = 0;
    var qn = '';
    var tqn = '';
    var mesg = ''
    var max = Level == 1 ? 10 : Level == 2 ? 100 : 1000
    if (Level < 4) {
      //   alert(Count)

      if (Level == 0) {
        if (ops[Mode] == '+') {

          num1 = Math.round(Math.random() * 10, 0);
          num2 = Math.round(Math.random() * 10, 0);

        } else if (ops[Mode] == '-') {
          var tn=0;
          num1 = Math.round(Math.random() * 10, 0);
          num2 = Math.round(Math.random() * 10, 0);
          
          if ( num2 > num1) {
             tn=num1;
             num1=num2;
             num2=tn;
          }

        } else if (ops[Mode] == '*') {

          var factors=[0,1,2,3,5,10]
          var tn=0
          num1 = Math.round(Math.random() * 10, 0);
          var i = Math.round(Math.random() * 5, 0);
          num2 = factors[i];

        } else if (ops[Mode] == '/') {
          var factors=[1,2,3,4,5]
          num1 = Math.round(Math.random() * 10, 0);
          var i = Math.round(Math.random() * 4, 0);
          if (num1%i==0){
            num2=i;
          } else {
            num2=num1
          }

        }

        qn = `${num1}  ${ops[Mode]}  ${num2}`;
        tqn = num1.toLocaleString('en') + opts[Mode] + num2.toLocaleString('en');

      } else {

        if (Count == 10 || Count < 7) {
          num1 = Math.round(Math.random() * max, 0);
          num2 = Math.round(Math.random() * max, 0);
          if (ops[Mode] == '/' && num2 == 0) {
            num2 = 1
          }
          qn = `${num1}  ${ops[Mode]}  ${num2}`;
          tqn = num1.toLocaleString('en') + opts[Mode] + num2.toLocaleString('en');
        } else {
          num1 = Math.round(Math.random() * max, 0);
          num2 = Math.round(Math.random() * max, 0);
          num3 = Math.round(Math.random() * max, 0);
          if (ops[Mode] == '/') {
            num2 = num2 == 0 ? 1 : num2
            num3 = num3 == 0 ? 1 : num3
          }

        }

        qn = `${num1}  ${ops[Mode]}  ${num2}  ${ops[Mode]} ${num3}`;
        tqn = num1.toLocaleString('en') + opts[Mode] + num2.toLocaleString('en') + opts[Mode] + num3.toLocaleString('en');;
      }
    }
    setQn(qn);
    setTQn(tqn);
    setuAns('?');
    setResult(prevResult => ops[Mode] == '/' ? 'You can round off answer upto 2 decimals' : '');
    setSubmit(false);
    setScoreCard(false)
    return (
      <div className="text-indigo-400   pb-3">
        <h1 className="text=2xl md:text-4xl lg:text-6xl">{qn} = ?</h1>
        {mesg}

      </div>
    )
  }



  return (

    <div className="relative h-full ">

    <div className="h-full  ">
      <div className=" m-2  bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ">
        <div className=" grid  grid-cols-1  justify-items-center items-center "   >

          <div className="w-full p-4 mb-5    bg-opacity-70 shadow-2xl border-b-4  animate-pulse"><Fade top duration={3000}> <h2 className=" text-xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold ">Arithmetic Quest</h2></Fade></div>
        </div>

        <div className=' grid grid-cols-1   items-center bg-opacity-70 shadow-2xl'>
          <span>
            <Link to="/numquest"><button className=" m-2  text-white  text-xl focus:outline-none  fa fa-home text-gray-500 transition duration-500 ease-in-out  hover:text-white" ></button></Link>
            <button className=' m-2  text-white  text-xl focus:outline-none ' onClick={SetMode}>
              <i className="fa fa-cog text-purple-700  transition duration-500 ease-in-out  hover:text-white">{Mode}</i>
            </button>
            <button className='m-2 text-white  text-xl focus:outline-none  ' onClick={SetLevel}>
              <i class="fas fa-layer-group  text-yellow-500 transition duration-500 ease-in-out  hover:text-white">{Level}</i>
            </button>
            <button className='m-2 text-white  text-xl focus:outline-none  ' >
              <i class="fas fa-info  text-green-400 transition duration-500 ease-in-out  hover:text-white" onClick={() => setIsOpen(true)}></i>
            </button>
            <button className='m-2 text-white  text-xl focus:outline-none  ' onClick={SetAudio} >
              {MuteBtn}
            </button>
          </span>
        </div>
        <div >
          {SetDash()}
        </div>



        <div className=" mb-4 grid  grid-cols-1  flex justify-items-center items-center "   >
          <div>
            <Modal open={IsOpen} onClose={() => setIsOpen(false)} setGo={SetGo} options={options} levels={levels} onOptChange={onOptChange} onLvlChange={onLvlChange} >{SetMsgBoard()}</Modal>
          </div>

          <div className=" grid  grid-cols-1 lg:grid-cols-2 flex justify-items-center items-center shadow-2xl m-4 rounded-l-full" >

            <div className="m-4 grid  grid-cols-1 px-4 py-2 lg:py-4 lg:px-6 w-4/5  ">
              <p className="mb-2 mt-2 text-4xl md:text-4xl lg:text-6xl font-bold font-lobster lg:text-red-700 md:text-red-500  text-yellow-300 "> {ShowReader()} {Qn}</p>
              <p className="text-3xl md:text-3xl lg:text-4xl  font-bold bg-white text-gray-400 rounded shadow-lg w-full" >{uAns}</p>
              <p className="text-xl font-bold text-gray-400 rounded  w-full" >{SetResult()} </p>
            </div>
            <div className=' text-white  mt-0 mb-4 ml-10 mr-10 md:mt-10 lg:mt-10 md:mb-10 lg:mb-10  text-xl'  >
              {DisplayKeys()}
            </div>

          </div>

        </div>
        <div>
          {SetControls()}
        </div>


      </div>

      <CookieConsent />

    </div>


</div>


  );
}

export default Arithmetic;


