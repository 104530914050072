import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import Modal from '../components/Modal.js'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Bounce from 'react-reveal/Bounce'
import HeadShake from 'react-reveal/HeadShake'
import CookieConsent from '../components/Cookie'
import Select from 'react-select';
import Animated from 'react-select/animated' 
import './quiz.css';

function Test(props) {
    const { lvl } = useParams()
    const { mode } = useParams()
//    alert(lvl+mode)
    const [Stage, setStage] = useState('START')
    var qBank = require('../qBank/qcapital.js');
    var qUSA = require('../qBank/qUSA.js');
    var qINDIA = require('../qBank/qINDIA.js');
    var qWORLD = require('../qBank/qWORLD.js');
    var qCLASS2_MATHS = require('../qBank/qCLASS2_MATHS.js');
    var qCLASS2_ENGLISH = require('../qBank/qCLASS2_ENGLISH.js');
    var qCLASS2_HINDI = require('../qBank/qCLASS2_HINDI.js');
    var qSubjTopics = require('../qBank/qSubjTopics.js');
  //  var qLevels = require('../qBank/qLevels.js');

    var Subjects = qSubjTopics.subjects;
//    alert(Subjects+mode);
    var imode = Subjects.indexOf(mode.toUpperCase())
    const [Category, setCategory] = useState((imode > -1 && imode < Subjects.length) ? imode : 1)
    const [Subject, setSubject] = useState(Subjects[Category]);
    var Topics=qSubjTopics.topics[Subject];

    var ilvl = Topics.indexOf(lvl.toUpperCase())
    const [Level, setLevel] = useState((ilvl > -1 && ilvl < Topics.length) ? ilvl: 1)
//  const  [Level, setLevel] = useState((lvl > 0 && lvl < Topics.length-1) ? lvl : 1)
    const [Topic, setTopic] = useState(Topics[Level]);


    const [Count, setCount] = useState(0)
    const [Score, setScore] = useState(0)
    const [WScore, setWScore] = useState(0)
    const [SScore, setSScore] = useState(-1)
    const [CurQn, setCurQn] = useState(0);
    const [uAns, setuAns] = useState('');
    const [Result, setResult] = useState('')
    const [MsgBoard, setMsgBoard] = useState(<div className='fas fa-medal  text-gray-400'></div>)
    const [IsOpen, setIsOpen] = useState(false)
    const [ScoreCard, setScoreCard] = useState(false)
    const [Questions, setQuestions]=useState(qUSA.Capitals);
    const [MaxQuestions,setMaxQuestions]=useState(Questions.length>10?10:Questions.length);
    const [Qn, setQn] = useState('Test Your Skill !')
    const [TQn, setTQn] = useState('')
    const [SQn, setSQn] = useState(0)
    const BtnClass="w-full flex justify-items-stretch bg-white hover:bg-yellow-100 bg-opacity-70 py-2 mx-2 shadow-lg";
    const [Submit,setSubmit]=useState(false);


    const [IsMute, setIsMute] = useState(false)
    const [MuteBtn, setMuteBtn] = useState(<span class='fas fa-volume-up text-xl text-gray-700 transition duration-500 ease-in-out  hover:text-white' ></span>)
    const Synthref = React.useRef(window.speechSynthesis);
    var voices = window.speechSynthesis.getVoices();
    var UVoices = voices
      .filter(voice => voice.lang.includes("en-US"))
      .map(voice => voice);



    const [QOpts, setQOpts] = useState(
        <div className=" p-2 rounded-md text-left ">
          <Fade top>
            <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
              Choose the desired Category / Quiz and You will be presented with few Random Questions!
            </div>
          </Fade>
        </div>
    );


    const options = [
        { value: 0, label: <div><span className='pl-4 text-gray-800 uppercase font-bold'>USA</span></div> },
        { value: 1, label: <div><span className='pl-4 text-gray-800 uppercase font-bold'>INDIA</span></div> },
        { value: 2, label: <div><span className='pl-4 text-gray-800 uppercase font-bold'>WORLD</span></div> },
        { value: 3, label: <div><span className='pl-4 text-gray-800 uppercase font-bold'>CLASS2_MATHS</span></div> },
        { value: 4, label: <div><span className='pl-4 text-gray-800 uppercase font-bold'>CLASS2_ENGLISH</span></div> },
        { value: 5, label: <div><span className='pl-4 text-gray-800 uppercase font-bold'>CLASS2_HINDI</span></div> },       
    ];

    /**
    var options=[]
    const options2=Subjects.map((val,ind) => (      
           ' value :'+ ind + ", label: <div><span className='text-blue-800'> "+val+'</span></div> '
        ))

    for (var i of options2) {
            options.push(i);
    }

    alert('opton..len'+options)
    */

  //  const levels= qLevels.levels["USA"];
    const levels = {"USA": [
        { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Capitals</span></div> },
        { value: 2, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>GK</span></div> },
        { value: 3, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>History</span></div> },
        { value: 4, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Geography</span></div> },
      ],
      "INDIA": [
        { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>GK</span></div> },
        { value: 2, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Capitals</span></div> },
        { value: 3, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>Geography</span></div> },
        { value: 4, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>History</span></div> },
      ],
      "WORLD": [
        { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S3TOP1</span></div> },
        { value: 2, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S3TOP2</span></div> },
        { value: 3, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S3TOP3</span></div> },
        { value: 4, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S3TOP4</span></div> },
      ],
      "CLASS2_MATHS": [
        { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP1</span></div> },
        { value: 2, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP2</span></div> },
        { value: 3, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP3</span></div> },
        { value: 4, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP4</span></div> },
      ],
      "CLASS2_ENGLISH": [
        { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP1</span></div> },
        { value: 2, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP2</span></div> },
        { value: 3, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP3</span></div> },
        { value: 4, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP4</span></div> },
      ],
      "CLASS2_HINDI": [
        { value: 1, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP1</span></div> },
        { value: 2, label: <div><span className=' bg-gray-300  text-green-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP2</span></div> },
        { value: 3, label: <div><span className=' bg-gray-300  text-red-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP3</span></div> },
        { value: 4, label: <div><span className=' bg-gray-300  text-indigo-700 lg:text-2xl'></span><span className='pl-4 text-gray-800 uppercase font-bold'>S4TOP4</span></div> },
      ],
    
    };
    

    const [SwitchOpt, setSwitchOpt] = useState(
        <div className=' grid lg:grid-cols-2 justify-items-auto  rounded-lg shadow-2xl'>
        <div className="flex justify-start" >
            <div className=' mx-10 p-2 my-1 hover:text-gray-50'>
              <i class="fa fa-cog text-red-500   cursor-pointer hover:text-gray-50 shadow-inner" onClick={SetCategory}></i>
              <span className=" ml-4 uppercase  text-indigo-700 hover:text-gray-50  font-potta-one">{Subject}</span>
            </div>
        </div>
        <div className="flex justify-start font-potta-one  " >
          <div className=' mx-10 p-2 my-1  hover:text-gray-50'>
            <span class="fa fa-layer-group  text-yellow-500  hover:text-gray-50 cursor-pointer " onClick={SetLevel}></span>
            <span className=" ml-4 text-indigo-700 hover:text-gray-50 "> {Topic}</span>
          </div>
        </div>
      </div>
    );

    function SetQuestions(subject,topic) {
        var questBank=subject==="USA"?qUSA:subject==="INDIA"?qINDIA:subject==="WORLD"?qWORLD:subject==="CLASS2_MATHS"?qCLASS2_MATHS:subject==="CLASS2_HINDI"?qCLASS2_HINDI:subject==="CLASS2_ENGLISH"?qCLASS2_ENGLISH:qBank;
   //     alert('set questions'+subject+' '+topic+' '+questBank)
 //       var questions=shuffle(questBank[topic]);
        var questions=questBank[topic];
    //    alert('set questions '+ questions)
        setQuestions( questions );
        setMaxQuestions(questions.length>10?10:questions.length);
        setSQn( (Math.floor(Math.random() * questions.length)-1 ));
     
    
    };


    function SetSwitchOpt() {
    
        setSwitchOpt(
          <div className=' grid lg:grid-cols-2 justify-items-auto  rounded-lg shadow-2xl'>
            <div className="flex justify-start" >
                <div className=' mx-10 p-2 my-1 hover:text-gray-50'>
                  <i class="fa fa-cog text-red-500   cursor-pointer hover:text-gray-50 shadow-inner" onClick={SetCategory}></i>
                  <span className=" ml-4 uppercase  text-indigo-700 hover:text-gray-50  font-potta-one">{Subjects[Category]}</span>
                </div>
            </div>
            <div className="flex justify-start font-potta-one  " >
              <div className=' mx-10 p-2 my-1  hover:text-gray-50'>
                <span class="fa fa-layer-group  text-yellow-500  hover:text-gray-50 cursor-pointer " onClick={SetLevel}></span>
                <span className=" ml-4 text-indigo-700 hover:text-gray-50 ">{Level}</span>
              </div>
            </div>
          </div>
        );
    
    };
    

    function SwitchOpts() {
            if (Stage==='START') {
                var s1 =  <div className=' grid lg:grid-cols-2 justify-items-auto  rounded-lg shadow-2xl'>
                <div className="flex justify-start" >
                    <div className=' mx-10 p-2 my-1 hover:text-gray-50'>
                    <i class="fa fa-cog text-red-500   cursor-pointer hover:text-gray-50 shadow-inner" onClick={SetCategory}></i>
                    <span className=" ml-4 uppercase  text-indigo-700 hover:text-gray-50  font-potta-one">{Subject}</span>
                    </div>
                </div>
                <div className="flex justify-start font-potta-one  " >
                <div className=' mx-10 p-2 my-1  hover:text-gray-50'>
                    <span class="fa fa-layer-group  text-yellow-500  hover:text-gray-50 cursor-pointer " onClick={SetLevel} ></span>
                    <span className=" ml-4 text-indigo-700 hover:text-gray-50 "> {Topic} </span>
                </div>
                </div>
            </div>
            } else if (Stage.includes('Try')) {
                s1=<div className=" rounded-md "><Fade top><div className="text-xl sm:text-xl text-yellow-900 font-medium " >
                        <div className=' grid grid-cols-1  rounded-lg shadow-2xl'>  <span className='text-2xl  lg:text-3xl text-blue-500 shadow-2xl font-lobster '>Try Another Skill / Level?</span></div> 
                    </div></Fade></div>
            } else {
                s1=<div className=" rounded-md "><Fade top><div className="text-xl sm:text-xl text-yellow-900 font-medium " >
                        <div className=' grid grid-cols-1  rounded-lg shadow-2xl'>  <span className='m-2 bg-blue-400 shadow-md text-2xl  lg:text-3xl text-white font-semibold p-2 '>Select the Correct Option</span></div> 
                    </div></Fade></div>
            }
            return (
                <div>{s1} </div>
            )
    }


    function SetCategory() {
            var cat=Category 
            var subj=Subjects[cat];
            var level=1;
            var topic=Topics[level];
        
        setCategory(Category > Subjects.length - 2 ? 1 : Category + 1);
        cat=Category > Subjects.length - 2 ? 1 : Category + 1;
        subj=Subjects[cat];
        setLevel(1);
        setSubject(subj);
        Topics=qSubjTopics.topics[subj];
        topic=Topics[1];
        setTopic(topic);
        
        setCount(0)
        setScore(0)
        setWScore(0)
        setSScore(0)
        setCurQn(0)
        setQn('Test Your Skill !');
        setTQn('');
        setStage('START')
        setuAns('')
        setResult('')
        SetQuestions(subj,topic)
        SetSwitchOpt()

            setQOpts(<div className=" p-2 rounded-md text-left ">
            <Fade top>
            <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
                Choose the desired Subject / Topic and You will be presented with few random Questions!
            </div>
            </Fade>
            </div>);

        return (
            <div>
            <h1>Hello, Category!</h1>
            <h2>It is ops[{Category}]</h2>
            </div>
        );
    }

    function SetLevel() {
            var level=Level
            var topic=Topics[level];
            var subj=Subject;
            Topics=qSubjTopics.topics[Subject];
        //    alert('set level'+subj+'-'+topic+'-'+Topic+'-'+level)
        setLevel(Level > Topics.length - 2 ? 1 : Level + 1);
        topic=Topics[Level > Topics.length - 2 ? 1 : Level + 1];
       // alert('set level'+subj+' '+Subject+'-'+topic+'-'+Topic+'-'+level)
        setTopic(topic);

        setCount(0)
        setScore(0)
        setWScore(0)
        setSScore(0)
        setCurQn(0)
        setQn('Test Your Skill !');
        setTQn('');
        setStage('START')
        setuAns('')
        setResult('')
        SetQuestions(subj,topic)
        SetSwitchOpt()


            setQOpts(<div className=" p-2 rounded-md text-left ">
            <Fade top>
            <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
                Choose the desired Subject / Topic and You will be presented with few random Questions!
            </div>
            </Fade>
            </div>);

        return (
            <div>
            <h1>Hello, Category!</h1>
            <h2>It is ops[{Category}]</h2>
            </div>
        );
    }
  
    function SetGo() {
            setLevel(Level)
            setCategory(Category)
            setCount(0)
            setScore(0)
            setWScore(0)
            setSScore(0)
            setCurQn(0)
            setQn('Test Your Skill !');
            setTQn('');
            setStage('START')
            setuAns('')
            setResult('')
            setIsOpen(false)
            setSwitchOpt('');
            setScoreCard(false);
            
            setQOpts(<div className=" p-2 rounded-md text-left ">
            <Fade top>
            <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
                Choose the desired Subject / Topic and You will be presented with few random Questions!
            </div>
            </Fade>
            </div>);
    }

    function onOptChange(value) {
        var cat=Category 
        var subj=Subjects[cat];
        var level=1;
        var topic=Topics[level];
    
            setCategory(value);
            cat=value
            subj=Subjects[cat];
            setLevel(1);
            setSubject(subj);
            Topics=qSubjTopics.topics[subj];
            topic=Topics[1];
            setTopic(topic);
            
            setCount(0)
            setScore(0)
            setWScore(0)
            setSScore(0)
            setCurQn(0)
            setQn('Test Your Skill !');
            setTQn('');
            setStage('START')
            setuAns('')
            setResult('')
            SetQuestions(subj,topic)
            SetSwitchOpt()

                setQOpts(<div className=" p-2 rounded-md text-left ">
                <Fade top>
                <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
                    Choose the desired Subject / Topic and You will be presented with few random Questions!
                </div>
                </Fade>
                </div>);
            setIsOpen(false)
    return (
        <div>
        <h1>Hello, Category!</h1>
        <h2>It is ops[{Category}]</h2>
        </div>
    );
    }
    
    
    function onLvlChange(value) {
        var level=Level
        var topic=Topics[level];
        Topics=qSubjTopics.topics[Subject];
    
    setLevel(value);
    topic=Topics[value];
    setTopic(topic);

    setCount(0)
    setScore(0)
    setWScore(0)
    setSScore(0)
    setCurQn(0)
    setQn('Test Your Skill !');
    setTQn('');
    setStage('START')
    setuAns('')
    setResult('')
    SetQuestions(Subject,topic)
    SetSwitchOpt()


        setQOpts(<div className=" p-2 rounded-md text-left ">
        <Fade top>
        <div className="text-xl sm:text-xl text-yellow-900 mt-4 mb-6 ml-4 font-medium" >
            Choose the desired Subject / Topic and You will be presented with few random Questions!
        </div>
        </Fade>
        </div>);
        setIsOpen(false)
    return (
        <div>
        <h1>Hello, Category!</h1>
        <h2>It is ops[{Category}]</h2>
        </div>
    );
    }


    function Speak(x) {
        if (IsMute) {
          x = ''
        } else {
          x = x.replace(':', ' is ')
        }
        const utter = new SpeechSynthesisUtterance(x)
        var randVoice = UVoices[Math.floor(Math.random() * UVoices.length)];
        utter.voice = randVoice;
        Synthref.current.speak(utter)
    }
    
    function SetAudio() {
        setIsMute(prevIsMute => IsMute ? false : true)
        setMuteBtn(IsMute ? <span class='fas fa-volume-up text-xl text-gray-700 transition duration-500 ease-in-out  hover:text-white' ></span> : <span class='fas fa-volume-mute text-xl text-gray-700 transition duration-500 ease-in-out  hover:text-white' ></span>)
        Speak(IsMute ? 'Unmuted' : 'Muted')
    }
    
    function ShowReader() {
    return (
          TQn==='' ? '' : !Stage.includes('Next')?'':<button class='fas fa-volume-up text-xl lg:text-yellow-500 md:text-yellow-500  text-red-500 ' onClick={() => Speak(TQn)}></button>
        )
    }
    
    function SetControls() {
            var s = <button className='bg-red-700 text-white  m-4 text-xl p-2  rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => NextQn()} >
            {Stage}
            </button>
            if (ScoreCard) {
            s = <div><button className='bg-red-700 text-white  m-4 text-xl p-2  rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => NextQn()} >
                {Stage}</button><button className='bg-green-700 text-white  m-4 text-xl p-2 rounded-md transition duration-500 ease-in-out  hover:bg-yellow-600 transform hover:-translate-y-1 hover:scale-110 focus:outline-none shadow-2xl' onClick={() => DisplayScore()}>SCORE</button></div>
            }
    return (
            <div>{s}</div>
            )
    }


    function SetDash() {
            var mesg = (Score > 8 ? "Excellent!" : (Score > 6 ? "Good!" : ""))
        
            var s1 = <Fade top><div className=" grid  grid-cols-3  justify-items-center m-4 text-center  items-baseline ">
            <i className="text-2xl lg:text-4xl font-bold text-red-400 p-2 " ><span className=' text-green-900'>
                <span className='fas fa-question-circle text-red-700'><span className='p-2  text-white'>{CurQn}/{MaxQuestions}</span></span></span></i>
            <span className='uppercase font-2xl lg:text-2xl text-xl font-serif font-extrabold  text-yellow-200'>{Subject} - {Topic} </span>
            <i className="text-2xl lg:text-4xl   font-bold text-red-400 p-2  ">  <span className='fas fa-award text-green-900  '>
                <span className="text-red-200 p-2">{Score} {mesg}</span> </span> </i>
            </div></Fade>
            if (Count===0) {
            s1 = <div className=" grid  grid-cols-3  justify-items-center m-4 text-center  items-baseline ">
                <i className="text-2xl lg:text-4xl font-semibold text-red-400 p-2 " ><span className=' text-green-900'>
                <span className='fas fa-question-circles text-red-600'><span className='p-2  text-green-700'></span></span></span></i>
                <span className='uppercase font-bold font-2xl text-yellow-900'> </span>
                <i className="text-2xl lg:text-4xl   font-bold text-red-400 p-2  ">  <span className='fas fa-awards text-green-900  '>
                <span className="text-red-600 p-2"></span> </span> </i>
            </div>
            
            }
    return (
            <div>{s1} </div>
            )
    }
    

    function SetMsgBoard() {
    return (
          <div className=" text-green-700 text-2xl grid grid-cols-1 justify-items-center ">
            <Bounce duration='5000'><div className="m-1  font-lobster text-2xl md:text-3xl lg:text-4xl  font-bold  justify-items-center ">
              {MsgBoard}
            </div></Bounce>
            <div className="mt-4 mb-4 p-6 bg-gray-300 rounded-xl grid grid-cols-1 font-mono  text-xl w-full ">
             <div className='font-serif uppercase font-extrabold grid grid-cols-1 w-full text-center underline m-6'>Score Card</div>
              <div><span className=' text-red-700 flex pl-4 md:pl-15 lg:pl-30 '>Total Questions &nbsp;&nbsp;    : {Count}</span>  </div>
              <Fade duration='4000'>
              <div><span className=' text-red-700 flex pl-4 md:pl-15 lg:pl-30'>Skipped Questions &nbsp;: {SScore}</span>  </div>
              <div><span className=' text-red-700 flex pl-4 md:pl-15 lg:pl-30'>Wrong Answers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {WScore}</span>  </div>
              <div><span className=' text-red-700 flex pl-4 md:pl-15 lg:pl-30'>Right Answers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {Score}</span>  </div>
              </Fade>
            </div>
          </div>
        )
    }


    function NextQn() {
   //     alert(' question set '+Questions.length+' maxquestions '+MaxQuestions);
        if (Count > (MaxQuestions-1) && !Stage.includes('Try')) {
          var Msg = 'Well Tried!'
              Msg = Score > 8 ? <div className='fas fa-medal  text-yellow-500'>
            <div className=' text-green-800' >Excellent!</div></div> : Score > 6 ? <div className='fas fa-medal  text-green-600'>
              <div className='text-yellow-600' >Well Done! </div></div> : <div className='fas fa-medal  text-red-400'>
                <span className='text-red-800' >Well Tried!</span></div>
          setMsgBoard(Msg)
          setStage('Try Again')
          setScore(prevScore => Count > Questions.length ? 0 : prevScore);
          setWScore(prevWScore => Count >  Questions.length ? 0 : prevWScore);
          setSScore(prevSScore => Count >  Questions.length ? -1 : prevSScore);
          setScoreCard(true)
          DisplayScore();
          setQn('Test Your Skill !')
        } 
        else if (Stage.includes('Try'))
        {
          SetGo();
          setScoreCard(false);
    
        }
        else {
          if(Submit===false){
            setSScore(SScore + 1);
          }
          setCount(prevCount => prevCount===MaxQuestions ? 1 : prevCount + 1);
          setScore(prevScore => Count===MaxQuestions ? 0 : prevScore);
          setWScore(prevWScore => Count===MaxQuestions ? 0 : prevWScore);
          setSScore(prevSScore => Count===MaxQuestions ? -1 : prevSScore);
          setScoreCard(false)
          setSwitchOpt('');
          setIsOpen(false)
          ServeQn();
          setCurQn(CurQn<MaxQuestions ? CurQn+1 : 0);
          setSQn(SQn<Questions.length-2? SQn+1:0);
          setStage('Skip');
          setSubmit(false);
        }
        return (
          <div>
            <h1>Hello, Qn!</h1>
            <h2>It is {Count} question</h2>
          </div>
        );
    }
    
    
    const ServeQn = () => {
        var qn = '';
        var tqn = '';
        var mesg = '';
        var qid=0;
        var ans='';
        var optArray=shuffle([1,2,3,4]);
    //    qid=CurQn===MaxQuestions?0:CurQn;
     //     qid=SQn===Questions.length-1?0:SQn;
          qid=SQn;
          qn=Questions[qid][0] ;
          tqn=Questions[qid][0];
          ans=Questions[qid][5];
    
        setQn(qn);
        setTQn(tqn);
    
          setQOpts(<div id='quiz' className=" grid grid-cols-1 items-center rounded-md text-xl  lg:text-2xl m-4 ">
                {optArray.map((val,ind) => (      
                <div class="inline-flex space-x-4 mt-4 w-full" >
                  <div className='flex-2 rounded  bg-white text-white '> lt </div>
                    <button id={ind===0?'A':ind===1?'B':ind===2?'C':ind===3?'D':'A'} className={BtnClass} onClick={() => handleAnswerButtonClick(optArray,ind===0?'A':ind===1?'B':ind===2?'C':ind===3?'D':'A',Questions[qid][val],ans)}><span className="mx-2 px-4 shadow-inner bg-gray-400  hover:bg-gray-200 hover:text-gray-800">{ind===0?'A':ind===1?'B':ind===2?'C':ind===3?'D':'A'}</span><span className=" px-4  hover:text-gray-800 hover:bg-gray-200"> {Questions[qid][val]}</span></button>
                  <div className='flex-2 rounded  bg-white text-white'>lt</div>
                  </div>
                ))} 
              </div>   
          );
        setuAns('?');
        setResult('');
        setScoreCard(false)
        return (
          <div className="text-indigo-400   pb-3">
            <h1 className="text=2xl md:text-4xl lg:text-6xl">{qn} = ?</h1>
            {mesg}
          </div>
        )
    }
    
    
    const handleAnswerButtonClick = (optArray,uBtn,uAns,qAns) => {
            setSubmit(true);
            var rtBtn='';
            if (uAns===qAns){
               rtBtn=uBtn;
            }
            var RClass=BtnClass+' bg-green-800 text-white hover:text-gray-800';
            var WClass=BtnClass+' bg-red-800 text-white hover:text-gray-800';
    
                setQOpts(<div id='quiz' className=" grid grid-cols-1 items-center rounded-md text-xl  lg:text-2xl m-4 ">
                      {optArray.map((val,ind) => (      
                      <div class="inline-flex space-x-4 mt-4 w-full" >
                        <div className='flex-2 rounded  bg-white text-white '> lt </div>
                          <button id={ind===0?'A':ind===1?'B':ind===2?'C':ind===3?'D':'A'} className={Questions[CurQn][val]===qAns?RClass:Questions[CurQn][val]===uAns?WClass:BtnClass} >
                            <span className="mx-2 px-4 shadow-inner bg-gray-400  hover:bg-gray-200 hover:text-gray-800">{ind===0?'A':ind===1?'B':ind===2?'C':ind===3?'D':'A'}</span>
                            <span className=" px-4  hover:text-gray-800 hover:bg-gray-200"> {Questions[CurQn][val]}</span>
                          </button>
                        <div className='flex-2 rounded  bg-white text-white'>lt</div>
                        </div>
                      ))} 
                      </div>   
                );
    
    
            if (uAns===qAns) {
                  setScore(Score + 1);
                  setResult(<Bounce><div className="text-xl md:text-2xl lg:text-3xl fas fa-check text-green-500"> <span className='text-green-700'>RIGHT !</span></div>
                  <div>Ans : {qAns} </div></Bounce>);
                  Speak('RIGHT!');
    
            } else {
                  setWScore(WScore+1);
                  setResult(<Bounce><div className="text-xl md:text-2xl lg:text-3xl fas fa-times text-red-500"> <span className='text-red-700'>WRONG !</span></div>
                  <div>Ans : {qAns} </div></Bounce>);
                  Speak('WRONG, option  '+qAns+' is the correct answer!');
    
                }       
            setStage('Next');
        
    };


function DisplayScore() {
                setResult('');
                setQn(<div className=" rounded-md ">
                <Fade top>
                <div className="text-xl text-yellow-900 font-medium" >
                    {SetMsgBoard()}
                </div>
                </Fade>
                </div>);
                setQOpts( <div className="m-6 p-2 bg-gray-300 text-red-600 rounded-xl grid grid-cols-1 font-mono  text-xl ">
            <div className=' grid lg:grid-cols-2 mb-4 align-middle' >
                        <div className="my-4 ">
                            <label for="Category">Different Subject?</label> 
                            <Select  label="Category" 
                                defaultValue={options[Category]}
                        //               isClearable
                                isSearchable
                                components={Animated}
                                name="ArithOpts"
                                options={options}
                                onChange={onOptChange}
                                styles={ customStyles }
                            /></div><div className="my-4">
            
                            <label for="Topic">Different Topic in {Subjects[Category]}?</label> 
                            <Select  label="Topic" 
                                defaultValue={Subject}
                        //                 isClearable
                                isSearchable
                                components={Animated}
                                name="LevelOpts"
                                options={levels[Subject]}
                                onChange={LvlChange}
                            />
                        </div>
                    </div>
            <div>
            </div>   
            </div>);
    }



    function OptChange(e){
        setCategory(e)
     //   alert('hhere..'+e)
   //     SetGo()
   //     setCategory(e.value)
        onOptChange(e)
    }
  
    function LvlChange(e){
     setLevel(e)
     onLvlChange(e) 
    }
  
  
    const customStyles = {
      borderBottom: '2px dotted pink',
      padding: 40,
    }
  
    function shuffle(array) {
      let currentIndex = array.length,  randomIndex;
          while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                  array[randomIndex], array[currentIndex]];
          }
    return array;
    }

    







return (
    <div className="min-h-screen shadow-2xl align-middle bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500">

        <div >
            <div className=" m-2 ">
                <div className=" grid  grid-cols-1  justify-items-center items-center "   >
                    <div className="w-full p-4 mb-5  bg-opacity-70 shadow-2xl border-b-4  animate-pulse"><Fade top duration={3000}> <h2 className=" text-xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold ">Quiz Portal</h2></Fade></div>
                </div>
                <div>
                    <Modal open={IsOpen} onClose={() => setIsOpen(false)} setGo={SetGo} options={options} levels={levels[Subject]} onOptChange={OptChange} onLvlChange={LvlChange} >{SetMsgBoard()}</Modal>
                </div>

            <div className=' grid grid-cols-1   items-center bg-opacity-70 shadow-2xl'>
                <span>
                    <Link to="/numquest"><button className=" m-2  text-xl focus:outline-none  fa fa-home text-gray-500 transition duration-500 ease-in-out  hover:text-white" ></button></Link>
                    <button className=' m-2  text-white  text-xl focus:outline-none ' onClick={SetCategory}>
                        <i className="fa fa-cog text-purple-700  transition duration-500 ease-in-out  hover:text-white">{Category}</i>
                    </button>
                    <button className='m-2 text-white  text-xl focus:outline-none  ' onClick={SetLevel}>
                        <i class="fas fa-layer-group  text-yellow-500 transition duration-500 ease-in-out  hover:text-white">{Level}</i>
                    </button>
                    <button className='m-2 text-white  text-xl focus:outline-none  ' >
                        <i class="fas fa-info  text-green-400 transition duration-500 ease-in-out  hover:text-white" onClick={() => setIsOpen(true)}></i>
                    </button>
                    <button className='m-2 text-white  text-xl focus:outline-none  ' onClick={SetAudio} >
                            {MuteBtn}
                    </button>
                </span>
            </div>
            <div >
                {SetDash()}
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 shadow-2xl m-4 rounded bg-gray-200  items-center '>
                <div >
                    <p className="p-4 lg:p-6 m-4 text-2xl md:text-2xl lg:text-3xl font-bold font-lobster bg-white text-red-500 shadow-xl"> {ShowReader()} <span className=" font-serif"> {Qn}</span></p>
                    <p className="text-xl font-bold text-gray-400  w-full" >{Result} </p>
                </div>

                <div id='quiz' className=" grid grid-cols-1 items-center rounded-md text-2xl ">
                    {SwitchOpts()}
                    {QOpts}
                </div>  
            </div>

            <div className=" grid  grid-cols-1  justify-items-center items-center "   >
                <div className="w-full p-4 mb-5  bg-opacity-70 shadow-2xl border-b-4"><Fade top duration={3000}> <h2 className=" text-xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold ">{SetControls()}</h2></Fade></div>
            </div>

             </div>
                <CookieConsent />
            </div>

            <div>{Subjects}</div>
            <div>{Subject}</div>
            <div> {Topics}</div>
            <div>{Topic}</div>
            <div> {Category}</div>
            <div>{Level}</div>  
            </div>

    );

}

export default Test;



