module.exports = {

 usa_capitals : [ 
        ["What is the capital of Virginia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the capital of Arizona?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Name the capital of West Virginia","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Name the capital of New York","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of North Carolina?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the capital of Alabama?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Nevada?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of New Mexico?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the capital of Colorado?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Georgia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Name the capital of Washington","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Ohhio?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Which city is the  capital of California?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["What is the capital of Texas?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        ["Name the capital of Florida","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
        

 ],

 S1TOP11 : [
  { 
    questionText: 'What is the capital of Virginia?',
    answerOptions: [
          { answerText: 'Colombo', isCorrect: false},
          { answerText: 'Richmond', isCorrect: false},
          { answerText: 'New Delhi', isCorrect: true},
          { answerText: 'Hyderabad', isCorrect: false}, 
        ],

    answer: 'B',
  },

    { 
      questionText: 'What is the capital of India?',
      answerOptions: [
            { answerText: 'Colombo', isCorrect: false},
            { answerText: 'Khatmandu', isCorrect: false},
            { answerText: 'New Delhi', isCorrect: true},
            { answerText: 'Hyderabad', isCorrect: false}, 

      ],
      answer: 'C',
    },
],

Set2 : [


  { 
    questionText: 'What is the best season?',
    answerOptions: [
          { answerText: 'Winter', isCorrect: false},
          { answerText: 'Summer', isCorrect: false},
          { answerText: 'Fall', isCorrect: true},
          { answerText: 'Spring', isCorrect: false}, 

    ],
    answer: 'C',
  },
    { 
      questionText: 'Capital of USA',
      answerOptions: [
            { answerText: 'Washington DC', isCorrect: true},
            { answerText: 'Richmond', isCorrect: false},
            { answerText: 'Florida', isCorrect: false},
            { answerText: 'New York', isCorrect: false}, 

      ],
      answer: 'A',
  },
  { 
    questionText: 'What is the capital of India?',
    answerOptions: [
          { answerText: 'Colombo', isCorrect: false},
          { answerText: 'Khatmandu', isCorrect: false},
          { answerText: 'New Delhi', isCorrect: true},
          { answerText: 'Hyderabad', isCorrect: false}, 

    ],
    answer: 'C',
  },
],

  Set3 : [


    { 
      questionText: 'What is the best game?',
      answerOptions: [
            { answerText: 'Soccer', isCorrect: false},
            { answerText: 'Tennis', isCorrect: false},
            { answerText: 'Chess', isCorrect: true},
            { answerText: 'Cricket', isCorrect: false}, 
  
      ],
      answer: 'D',
    },
      { 
        questionText: 'Capital of USA',
        answerOptions: [
              { answerText: 'Washington DC', isCorrect: true},
              { answerText: 'Richmond', isCorrect: false},
              { answerText: 'Florida', isCorrect: false},
              { answerText: 'New York', isCorrect: false}, 
  
        ],
        answer: 'A',
    },
    { 
      questionText: 'What is the capital of India?',
      answerOptions: [
            { answerText: 'Colombo', isCorrect: false},
            { answerText: 'Khatmandu', isCorrect: false},
            { answerText: 'New Delhi', isCorrect: true},
            { answerText: 'Hyderabad', isCorrect: false}, 

      ],
      answer: 'C',
    },
    { 
      questionText: 'Sun Rises in which direction',
      answerOptions: [
            { answerText: 'East', isCorrect: true},
            { answerText: 'West', isCorrect: false},
            { answerText: 'North', isCorrect: false},
            { answerText: 'South', isCorrect: false}, 

      ],
      answer: 'A',
  },
  ],
  
    Set4 : [


      { 
        questionText: 'What is the best season?',
        answerOptions: [
              { answerText: 'Winter', isCorrect: false},
              { answerText: 'Summer', isCorrect: false},
              { answerText: 'Fall', isCorrect: true},
              { answerText: 'Spring', isCorrect: false}, 
    
        ],
        answer: 'C',
      },
        { 
          questionText: 'Capital of USA',
          answerOptions: [
                { answerText: 'Washington DC', isCorrect: true},
                { answerText: 'Richmond', isCorrect: false},
                { answerText: 'Florida', isCorrect: false},
                { answerText: 'New York', isCorrect: false}, 
    
          ],
          answer: 'A',
    
      },
      { 
        questionText: 'What is the capital of India?',
        answerOptions: [
              { answerText: 'Colombo', isCorrect: false},
              { answerText: 'Khatmandu', isCorrect: false},
              { answerText: 'New Delhi', isCorrect: true},
              { answerText: 'Hyderabad', isCorrect: false}, 
  
        ],
        answer: 'C',
      },
      { 
        questionText: 'Sun Rises in which direction',
        answerOptions: [
              { answerText: 'East', isCorrect: true},
              { answerText: 'West', isCorrect: false},
              { answerText: 'North', isCorrect: false},
              { answerText: 'South', isCorrect: false}, 

        ],
        answer: 'A',

    },

    { 
      questionText: 'What is the capital of India?',
      answerOptions: [
            { answerText: 'Colombo', isCorrect: false},
            { answerText: 'Khatmandu', isCorrect: false},
            { answerText: 'New Delhi', isCorrect: true},
            { answerText: 'Hyderabad', isCorrect: false}, 

      ],    
      answer: 'C',
    },
]

}