module.exports = {

  "Face & Place Values" : [
["What is the face value of 0 in the 3 digit number 203?","2","10","3","0","0"],
["Find the face value and place value of digit 4 in 456","400,4","40,4","4,40","4, 400","4, 400"],
["What are the place values of 0 in 2020?","1,100","1,0","10,1","0 only","0 only"],
["Find the place value of 9 in the number 690","1","9","10","90","90"],
["Whch of the following numbers has a digit whose place value is 90?","965","900","909","490","490"],
["Which of the digits is in the 10s place of the number 845?","8","5","4","40","4"],
["Identify the number that has a digit whose place value is 500","6456","5945","4569","235","4569"],
["What is the face value of 6 in the 4 digit number 2063?","10","0","6","100","6"],
["Find the face value and place value of digit 5 in 4563","5,5","5, 50","5, 500","500, 5","5, 500"],
["What is the place value of 0 in 4022?","100","10","0","1000","0"],
["Find the place value of 6 in the number 6990","6","6000","60","600","6000"],
["Whch of the following numbers has a digit whose place value is 400?","4356","3456","4345","214","3456"],
["Which of the digits is in the 100s place of the number 548?","None","5","4","8","5"],
["Identify the number that has a digit whose place value is 30","3456","836","23","340","836"],
["What is the face value of 1 in the 4 digit number 2103?","100","1","10","0","1"],
["Find the face value and place value of digit 9 in 4956","9,900","900, 9","90, 9","90, 9000","9,900"],
["What are the place values of 0 in 600?","0","1, 10","10, 1","10,0","0"],
["Find the place value of 2 in the number 6296","200","20","0","2000","200"],
["Whch of the following numbers has a digit whose place value is 600?","2649","2469","6249","4366","2649"],
["Which of the digits is in the 10s place of the number 8530?","5","8","3","0","3"],
["Identify the number that has a digit whose place value is 4000","4835","9435","5324","5349","4835"],
 ],

 Subtraction : [ 
["20-13=?","7","4","11","3","7"],
["312-123=?","189","186","193","185","189"],
["45-32=?","13","10","17","9","13"],
["343-69=?","274","271","278","270","274"],
["32-16=?","16","13","20","12","16"],
["454-321=?","133","130","137","129","133"],
["75-23=?","52","49","56","48","52"],
["43-19=?","24","21","28","20","24"],
["321-97=?","224","221","228","220","224"],
],

Addition : [ 
["20+44=?","64","61","68","29","64"],
["312+23=?","335","332","339","300","335"],
["45+323=?","368","365","372","333","368"],
["343+443=?","786","783","790","751","786"],
["32+65=?","97","94","101","62","97"],
["454+321=?","775","772","779","740","775"],
["75+76=?","151","148","155","116","151"],
["43+43=?","86","83","90","51","86"],
["321+97=?","418","415","422","383","418"],
],

Multiplication : [ 
["20X3=?","60","57","64","56","60"],
["312X2=?","624","621","628","620","624"],
["45X1=?","45","42","49","41","45"],
["343X4=?","1372","1369","1376","1368","1372"],
["32X3=?","96","93","100","92","96"],
["454X0=?","0","454","4","45","0"],
["75X7=?","525","522","529","521","525"],
["43X6=?","258","255","262","254","258"],
["321X8=?","2568","2565","2572","2564","2568"],
["21X9=?","189","186","193","185","189"],
["33X9=?","297","294","301","293","297"],
["65X2=?","130","127","134","126","130"],
],


Division: [
["24/3=?","8","5","12","4","8"],
["312/2=?","156","153","160","152","156"],
["55/1=?","55","52","59","51","55"],
["344/4=?","86","83","90","82","86"],
["39/3=?","13","10","17","9","13"],
["100/0=?","Undefined","100","0","1","Undefined"],
["77/7=?","11","8","15","7","11"],
["480/6=?","80","77","84","76","80"],
["320/8=?","40","37","44","36","40"],
["27/9=?","3","0","7","-1","3"],
["360/9=?","40","37","44","36","40"],
["68/2=?","34","31","38","30","34"],
],

}
