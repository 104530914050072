import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import cImg from '../images/element-info.png'
import bImg from '../images/lets-spell.png'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'
import Btn from '../components/Button'
import CookieConsent from '../components/Cookie'


function Alexa() {
  const { skill } = useParams()
  const [Stage, setStage] = useState(skill)
  const [Msg] = useState('Learning is always fun!')

  var CImg = <Fade duration={3000} ><img src={cImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade>
  var BImg = <Fade duration={3000} ><img src={bImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade>

  const [Img, setImg] = useState(Stage === 'ElementInfo' ?
    <Fade duration={3000} ><img src={cImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade> :
    Stage === 'LetsSpell' ?
      <Fade duration={3000} ><img src={bImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade> :
      <Fade duration={3000} ><img src={cImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade>
  )

  const Elementinfo = <div className='bg-gray-200' ><Fade duration={3000} >
    <div className='text-left text-justify mx-6 text-gray-800 my-2 lg:my-4 py-4 lg:p-4  rounded-lg'>
      <p className='pb-2'>  {CImg}This Alexa Device skill is created to help the users learn basic information about the Periodic table elements in a very interactive way..
              This skill can be accessed in multiple ways, Alexa skill can be invoked with the following command. </p>

      <div className='py-4  text-red-700'>
        <p>To invoke the skill -- "Alexa, open Element Info" </p>
        <p>Learn Mode -- "Teach Me" </p>
        <p>Quiz Mode -- "Quiz Me" </p>
        <p>Once the application is launched, you can also quickly access the basic facts about the periodic table elements by using commands like </p></div>

      <div className='p-6 text-green-700'>
        <p>"Alexa, tell me about Helium" </p>
        <p>"Alexa, What is the symbol for Potassium?" </p>
        <p>"Alexa, Give me the Atomic number for Arsenic" </p>
        <p>"Alexa, What do you know about Argon?" </p>
        <p>"Alexa, Ask me some questions"</p>
      </div>

      <p>This skill can be downloaded from Amazon Skill store - <a className=' text-blue-600 font-bold' href='https://www.amazon.com/gp/product/B0799MJYSR?ref&ref=cm_sw_em_r_as_dp_Qpz6pmCSUJIHd' target="_blank"  rel="noreferrer">Element Info</a></p>
    </div> </Fade></div>



  const Letspell = <div className='bg-gray-200' ><Fade duration={3000} ><div className='text-left text-justify mx-6 text-gray-800 my-2 lg:my-4 py-4 lg:p-4  rounded-lg'>
    <p className='pb-2'>  {BImg}Let's spell the words with Alexa! Wouldn't it be fun if we can take turns with Alexa to spell the words?
Here is a simple alexa skill that you can invoke with "Let's Spell" command. </p>
    <div className='py-4  text-red-700'>
      After invoking the skill, you can use the following commands to interact with the App.
<p>- Alexa, "I Spell" /  "U Spell" </p>
    </div>
The difficulty level for words gets adjusted automatically based on the number of correct/incorrect responses.
<div className='p-6 text-green-700'>You can also ask Alexa to repeat the word/spelling anytime you would like during the conversation.
</div>
This skill can be downloaded from Amazon Skill store -  <a className=' text-blue-600 font-bold' href=' https://www.amazon.com/dp/B0B5VB9Y5W?ref&ref=cm_sw_em_r_as_dp_wEpUcNhGcDPqd' target="_blank"  rel="noreferrer">Let's Spell</a></div></Fade></div>

  const [Content, setContent] = useState(Stage === 'ElementInfo' ? Elementinfo : Stage === 'LetsSpell' ? Letspell : Elementinfo)

  const [Welcome, setWelcome] = useState(Stage === 'ElementInfo' ?
    <Slide top duration={3000} ><h2 className=" my-2 text-2xl md:text-3xl lg:text-3xl  text-white font-serif font-semibold">Element Info</h2></Slide> :
    Stage === 'LetsSpell' ?
      <Slide top duration={3000} ><h2 className=" my-2 text-2xl md:text-3xl lg:text-3xl  text-white font-serif font-semibold">Let's Spell</h2></Slide> :
      <Slide top duration={3000} ><h2 className=" my-2 text-2xl md:text-3xl lg:text-3xl  text-white font-serif font-semibold">ElementInfo</h2></Slide>
  )






  function SetStage() {

    setContent(Stage === 'ElementInfo' ? Elementinfo : Stage === 'LetsSpell' ? Letspell : Elementinfo)

    setWelcome(Stage === 'ElementInfo' ?
      <Slide top duration={3000} ><h2 className=" my-2 text-2xl md:text-3xl lg:text-3xl  text-white font-serif font-semibold">Element Info</h2></Slide> :
      Stage == 'LetsSpell' ?
        <Slide top duration={3000} ><h2 className=" my-2 text-2xl md:text-3xl lg:text-3xl  text-white font-serif font-semibold">Let's Spell</h2></Slide> :
        <Slide top duration={3000} ><h2 className=" my-2 text-2xl md:text-3xl lg:text-3xl  text-white font-serif font-semibold">ElementInfo</h2></Slide>
    )

    setImg(Stage === 'ElementInfo' ?
      <Fade duration={3000} ><img src={cImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade> :
      Stage === 'LetsSpell' ?
        <Fade duration={3000} ><img src={bImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade> :
        <Fade duration={3000} ><img src={cImg} alt="test" className="  float-left p-2 rounded-xl shadow=2xl hover:opacity-90 w-60"></img></Fade>
    )

    setStage(Stage === 'ElementInfo' ? 'LetsSpell' : 'ElementInfo')

  }

  return (

    <div className="min-h-screen items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >

      <div className=" m-2 md:m-2 lg:m-4  text-indigo-200 absolute-center ">
        <div className="p-2 m-4 lg:mx-52 grid grid-cols-1  flex justify-items-center items-center  shadow-2xl bg-opacity-70 rounded-lg "   >
          <div className=' my-2 lg:col-span-1 md:col-span-1'></div>

          <div class="inline-flex space-x-4 shadow-2xl" >
            <div className='flex-2 rounded-l-full  bg-white text-white'> lt </div>
            <div className="flex-1 ..." onClick={() => SetStage()} >
              <Btn Card='NumBtn' Content="Lt" ></Btn>
            </div>
            <Link to='/md=1'><div className="flex-2  ..."  >
              <Btn Card='NumBtn' Content='Home' />
            </div></Link>
            <Link to='/SkillApp'><div className="flex-2  ..."  >
              <Btn Card='NumBtn' Content='List' />
            </div></Link>
            <div className="flex-1 ..." onClick={() => SetStage()} >
              <Btn Card='NumBtn' Content="Rt" />
            </div>
            <div className='flex-2 rounded-r-full  bg-white text-white'>lt</div>
          </div>
          {Welcome}
          <div >{Content}</div>
        </div>



      </div>



      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>


      <CookieConsent />
    </div>





  );
}

export default Alexa;