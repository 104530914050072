import React, { useState } from 'react';
import Fade from 'react-reveal/Fade'
import Card from '../components/Card'
import CookieConsent from '../components/Cookie'


function About() {
  const [Msg] = useState('Learning is always fun!')
  const [Img] = useState(<Fade duration={3000}><div className='grid grid-cols-1 flex '>
  <Card Type='About' Title='About' Content='Journey with the basics'  bTitle=' ' bContent={Msg} />
  </div></Fade>)
 
 

  return (

    <div className="min-h-screen items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >

      <div className=" m-2 md:m-2 lg:m-4  text-indigo-200 absolute-center ">
        <div className="p-2 m-4 grid grid-cols-1 md:grid-cols-3  lg:grid-cols-8  flex justify-items-center items-center  shadow-2xl bg-opacity-70 rounded-lg "   >
           <div className=' lg:col-span-1 md:col-span-1'></div>
          <div className="flex justify-items-center w-full m-10 text-center lg:col-span-6 md:col-span-3">
            {Img}       
          </div>
          <div className='lg:col-span-1 md:col-span-1'></div>
        </div>



      </div>



      <Fade top duration={5000}><div className=' grid grid-cols-1 flex w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '></span>
      </div> </Fade>



      <CookieConsent />
    </div>





  );
}

export default About;