import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import bImg from '../images/wordquest1.png'
import spb1 from '../images/sp11.png'
import spb2 from '../images/sp12.png'
import spb3 from '../images/sp13.png'
import spb4 from '../images/sp14.png'
import np1 from '../images/np1.png'
import sv1 from '../images/sv1.png'
import gp1 from '../images/gp1.png'
import tbts2 from '../images/tbts2.png'
import tbts1 from '../images/tbts1.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Slide from 'react-reveal/Slide'
import Card from '../components/Card'
import Btn from '../components/Button'
import CookieConsent from '../components/Cookie'


function WordQuest() {
  const [Stage, setStage] = useState('START')
  const [Msg, setMsg] = useState('Learning is always fun!')
  const [Welcome, setWelcome] = useState(<Fade top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Word Quest</h2></Fade>)
  const [Category, setCategory] = useState('')

  const [Set, setSet] = useState(1)
  const [SetContent, setSetContent]= useState(<div className='grid grid-cols-1   m-2  justify-items-center'><Fade duration={3000} >
                                              <img src={bImg} alt="test" className="w-full rounded-lg shadow=2xl hover:opacity-90 "></img></Fade></div>)
  const [SetD,setSetD]=useState('')

  function SetStage() {

    switch (Set) {
      case 0: 
           setSetContent(<div className='grid grid-cols-1   m-2 justify-items-center' ><Fade duration={3000}  >
           <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
           <img src={bImg} alt="test" className="rounded-lg shadow-2xl hover:opacity-70  " ></img></div></Fade></div>)
           setSetD('')
           setCategory('')
           break;
      case 1: 
         setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2   bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/SK2xiL1jL5o' target="_blank"  rel="noreferrer noopener" > <img src={spb1} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/0mKqY3q4rKo' target="_blank"  rel="noreferrer noopener" > <img src={spb2} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/2ghkK6H_SbU' target="_blank"  rel="noreferrer noopener" > <img src={spb3} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/CqxIbYpTXlE' target="_blank"  rel="noreferrer noopener" > <img src={spb4} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         </div> </Fade> ) 
           setSetD('Practice Tests')
           setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Spell Bee</h2></Slide>)
         break;
      case 2: 
         setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2   bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/x7Sp0G3dWd4' target="_blank"  rel="noreferrer noopener" > <img src={np1} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/x9vyRRrjOwk' target="_blank"  rel="noreferrer noopener" > <img src={sv1} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         </div> </Fade> ) 
           setSetD('Lessons')
           setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">Hindi</h2></Slide>)
         break;
      case 3: 
         setSetContent(<Fade duration={3000}><div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2  bg-gray-100  bg-opacity-40 justify-items-center  sm:m-4 md:m-5 lg:m-10'>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/E5u5T-0CK8c' target="_blank"  rel="noreferrer noopener" > <img src={gp1} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/H_jCpsXVD58' target="_blank"  rel="noreferrer noopener" > <img src={tbts2} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         <div className='m-4  bg-opacity-40 justify-items-center shadow-2xl'> 
         <a href='https://youtu.be/ag0H4uQfCeg' target="_blank"  rel="noreferrer noopener" > <img src={tbts1} alt="test" className="rounded-lg shadow-2xl hover:opacity-70 " ></img></a>
         </div>
         </div> </Fade> ) 
           setSetD('Lessons')
           setCategory( <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-3xl  text-white font-serif font-semibold">English</h2></Slide>)
         break;
      default:
           setSetContent('NO CONTENT!')  
           setSetD('NO CONTENT')
           setCategory('')
           break
 
   }
    setSet(prevSet=>prevSet>=3?0:prevSet+1)
   // alert(Set)
    setStage(Stage == 'START' ? 'CLOSE' : 'START')
    setMsg(Stage == 'START' ? '' : <Fade bottom duration={5000}>Learning is always fun!</Fade>)
    setWelcome(Stage == 'START' ? <Slide top duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Word Quest</h2></Slide> : <Flip bottom duration={3000} ><h2 className=" text-2xl md:text-2xl lg:text-4xl  text-white font-serif font-semibold">Word Quest</h2></Flip>)

  }

  return (

    <div className="min-h-screen items-center bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 "  >
      <Card Type='Title' Title={Welcome} />




      <div id='arithmetic' className=" m-2 md:m-4 lg:m-6  text-indigo-200 absolute-center py-4 ">
        <div className=" m-4 grid  grid-cols-1  justify-items-center items-center   "   >
          <div className=" text-center  lg:px-58 rounded-2xl p-4 ">
            {Category}
            <div className="inline-flex space-x-4 shadow-2xl" >
              <div className='flex-2 rounded-l-full  bg-white text-white'> lt </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Up" ></Btn>
              </div>
              <Link to='/md=1'><div className="flex-2  ..."  >
                <Btn Card='NumBtn' Content='Home' />
              </div></Link>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="List" />
              </div>
              <div className="flex-1 ..." onClick={() => SetStage()} >
                <Btn Card='NumBtn' Content="Dn" />
              </div>
              <div className='flex-2 rounded-r-full  bg-white text-white'>lt</div>
            </div>
            <Slide duration={3000}><div className='font-extrabold text-gray-50 text-xl lg:text=2xl'>{SetD}</div></Slide>

          </div>
          <div className=" text-center shadow-2xl bg-opacity-70" onClick={() => SetStage()}>
            {SetContent}
          </div>
        </div>
      </div>





      <Fade top duration={5000}><div className=' grid grid-cols-1  w-full justify-items-center absolute bottom-20 text-gray-50  items-center center font-mono font-medium'>
        <span className=' text-lg md:text-2xl lg:text-2xl '>{Msg}</span>
      </div> </Fade>

      <CookieConsent />
     
    </div>


  );
}

export default WordQuest;