module.exports = {

 "Country Capitals" : [ 
  ["What is the capital of  Ivory Coast?","Plymouth","Baghdad","Canberra","Yamoussoukro","Yamoussoukro"],
  ["Name the capital city of  United Arab Emirates?","Brasília","Baku","Caracas","Abu Dhabi","Abu Dhabi"],
  ["Which city is the capital of Nigeria?","Bratislava","Bamako","Castries","Abuja","Abuja"],
  ["Which of the following is the capital of  Ghana?","Brazzaville","Bandar Seri Begawan","Podgorica","Accra","Accra"],
  ["____ is the capital of  Pitcairn Islands?","Bridgetown","Bangkok","Chișinău","Adamstown","Adamstown"],
  ["What is the capital city of Ethiopia?","Brussels","Bangui","Colombo","Addis Ababa","Addis Ababa"],
  ["What is the capital of  Algeria?","Bucharest","Banjul","Conakry","Algiers","Algiers"],
  ["Name the capital city of  Niue?","Budapest","Basseterre","Copenhagen","Alofi","Alofi"],
  ["Which city is the capital of Jordan?","Buenos Aires","Beijing","Porto-Novo","Amman","Amman"],
  ["Which of the following is the capital of  Netherlands?","Cairo","Beirut","Bamako","Amsterdam","Amsterdam"],
  ["____ is the capital of  Andorra?","Canberra","Belgrade","Bandar Seri Begawan","Andorra la Vella","Andorra la Vella"],
  ["What is the capital city of Turkey?","Caracas","Belmopan","Bangkok","Ankara","Ankara"],
  ["What is the capital of  Madagascar?","Castries","Berlin","Bangui","Antananarivo","Antananarivo"],
  ["Name the capital city of  Samoa?","Podgorica","Bern","Banjul","Apia","Apia"],
  ["Which city is the capital of Turkmenistan?","Chișinău","Bishkek","Basseterre","Ashgabat","Ashgabat"],
  ["Which of the following is the capital of  Eritrea?","Colombo","Bissau","Beijing","Asmara","Asmara"],
  ["____ is the capital of  Paraguay?","Conakry","Bloemfontein","Beirut","Asunción","Asunción"],
  ["What is the capital city of Greece?","Copenhagen","Bogotá","Belgrade","Athens","Athens"],
  ["What is the capital of  Cook Islands?","Porto-Novo","Plymouth","Belmopan","Avarua","Avarua"],
  ["Name the capital city of  Iraq?","Dakar","Brasília","Berlin","Baghdad","Baghdad"],
  ["Which city is the capital of Azerbaijan?","Damascus","Bratislava","Bern","Baku","Baku"],
  ["Which of the following is the capital of  Mali?","Dodoma","Brazzaville","Bishkek","Bamako","Bamako"],
  ["____ is the capital of  Brunei?","Dhaka","Bridgetown","Bissau","Bandar Seri Begawan","Bandar Seri Begawan"],
  ["What is the capital city of Thailand?","Dili","Brussels","Bloemfontein","Bangkok","Bangkok"],
  ["What is the capital of  Central African Republic?","Djibouti","Prague","Bogotá","Bangui","Bangui"],
  ["Name the capital city of  The Gambia?","Doha","Praia","Plymouth","Banjul","Banjul"],
  ["Which city is the capital of Saint Kitts and Nevis?","Douglas","Pristina","Brasília","Basseterre","Basseterre"],
  ["Which of the following is the capital of  China?","Dublin","Pyongyang","Bratislava","Beijing","Beijing"],
  ["____ is the capital of  Lebanon?","Dushanbe","Quito","Brazzaville","Beirut","Beirut"],
  ["What is the capital city of Serbia?","El Aaiún","Rabat","Bridgetown","Belgrade","Belgrade"],
  ["What is the capital of  Belize?","Flying Fish Cove","Reykjavík","Brussels","Belmopan","Belmopan"],
  ["Name the capital city of  Germany?","Freetown","Riga","Canberra","Berlin","Berlin"],
  ["Which city is the capital of Switzerland?","Funafuti","Riyadh","Caracas","Bern","Bern"],
  ["Which of the following is the capital of  Kyrgyzstan?","Gaborone","Road Town","Castries","Bishkek","Bishkek"],
  ["____ is the capital of  Guinea-Bissau?","Monaco","Rome","Podgorica","Bissau","Bissau"],
  ["What is the capital city of South Africa?","Monrovia","Roseau","Chișinău","Bloemfontein","Bloemfontein"],
  ["What is the capital of  Colombia?","Montevideo","Rothera","Colombo","Bogotá","Bogotá"],
  ["Name the capital city of  Montserrat?","Moroni","Brazzaville","Conakry","Plymouth","Plymouth"],
  ["Which city is the capital of Brazil?","Moscow","Bridgetown","Copenhagen","Brasília","Brasília"],
  ["Which of the following is the capital of  Slovakia?","Muscat","Brussels","Porto-Novo","Bratislava","Bratislava"],
  ["____ is the capital of  Congo?","Nairobi","Bucharest","Canberra","Brazzaville","Brazzaville"],
  ["What is the capital city of Barbados?","Nassau","Budapest","Caracas","Bridgetown","Bridgetown"],
  ["What is the capital of  Belgium?","Naypyidaw","Buenos Aires","Castries","Brussels","Brussels"],
  ["Name the capital city of  Romania?","N'Djamena","Cairo","Podgorica","Bucharest","Bucharest"],
  ["Which city is the capital of Hungary?","New Delhi","Canberra","Chișinău","Budapest","Budapest"],
  ["Which of the following is the capital of  Argentina?","Ngerulmud","Caracas","Colombo","Buenos Aires","Buenos Aires"],
  ["____ is the capital of  Egypt?","Niamey","Castries","Conakry","Cairo","Cairo"],
  ["What is the capital city of Australia?","Nicosia","Podgorica","Copenhagen","Canberra","Canberra"],
  ["What is the capital of  Venezuela?","Nouakchott","Chișinău","Porto-Novo","Caracas","Caracas"],
  ["Name the capital city of  Saint Lucia?","Nouméa","Colombo","Havana","Castries","Castries"],
  [" Montenegro's capital is","Prague","Conakry","Monaco","Podgorica","Podgorica"],
  ["What is the capital of  Moldova?","Praia","Copenhagen","Monrovia","Chișinău","Chișinău"],
  ["Name the capital city of  Sri Lanka?","Pristina","Porto-Novo","Montevideo","Colombo","Colombo"],
  ["Which city is the capital of Guinea?","Pyongyang","Dakar","Moroni","Conakry","Conakry"],
  ["Which of the following is the capital of  Denmark?","Quito","Damascus","Moscow","Copenhagen","Copenhagen"],
  [" Benin's capital is","Rabat","Dodoma","Muscat","Porto-Novo","Porto-Novo"],
  [" Senegal's capital is","Reykjavík","Dhaka","Nairobi","Dakar","Dakar"],
  ["Which city is the capital of Syria?","Riga","Dili","Nassau","Damascus","Damascus"],
  ["Which city is the capital of Tanzania?","Riyadh","Djibouti","Naypyidaw","Dodoma","Dodoma"],
  [" Bangladesh's capital is","Road Town","Doha","N'Djamena","Dhaka","Dhaka"],
  [" East Timor's capital city is","Rome","Douglas","New Delhi","Dili","Dili"],
  [" Djibouti's capital city is","Roseau","Dublin","Ngerulmud","Djibouti","Djibouti"],
  [" Qatar's capital city is","Rothera","Dushanbe","Niamey","Doha","Doha"],
  [" Isle of Man's capital city is","Harare","El Aaiún","Nicosia","Douglas","Douglas"],
  ["____ is the capital of  Ireland?","Hargeisa","Flying Fish Cove","Nouakchott","Dublin","Dublin"],
  ["What is the capital city of Tajikistan?","Havana","Freetown","Nouméa","Dushanbe","Dushanbe"],
  ["What is the capital of  Western Sahara ?","Helsinki","Funafuti","Lima","El Aaiún","El Aaiún"],
  ["Name the capital city of  Christmas Island?","Honiara","Gaborone","Lisbon","Flying Fish Cove","Flying Fish Cove"],
  ["Which city is the capital of Sierra Leone?","Belgrade","Monaco","Ljubljana","Freetown","Freetown"],
  ["Which of the following is the capital of  Tuvalu?","Baghdad","Monrovia","Lobamba","Funafuti","Funafuti"],
  ["____ is the capital of  Botswana?","Baku","Montevideo","Lomé","Gaborone","Gaborone"],
  ["What is the capital city of Cayman Islands?","Bamako","Moroni","London","George Town","George Town"],
  ["What is the capital of  Ascension Island?","Bandar Seri Begawan","Moscow","Luanda","Georgetown","Georgetown"],
  ["Name the capital city of  Guyana?","Bangkok","Muscat","Lusaka","Georgetown","Georgetown"],
  ["Which city is the capital of Gibraltar?","Bangui","Nairobi","Havana","Gibraltar","Gibraltar"],
  ["Which of the following is the capital of  Burundi?","Banjul","Nassau","Plymouth","Gitega","Gitega"],
  ["Which city is the capital of Guatemala?","Basseterre","Naypyidaw","Brasília","Guatemala City","Guatemala City"],
  ["Which of the following is the capital of  Saint Barthélemy?","Beijing","N'Djamena","Bratislava","Gustavia","Gustavia"],
  ["What is the capital of  Guam?","Beirut","New Delhi","Brazzaville","Hagåtña","Hagåtña"],
  [" Bermuda's capital is","Belgrade","Ngerulmud","Bridgetown","Hamilton","Hamilton"],
  [" Vietnam's capital is","Belmopan","Niamey","Brussels","Hanoi","Hanoi"],
  [" Zimbabwe's capital is","Berlin","Nicosia","Bucharest","Harare","Harare"],
  [" Somaliland's capital is","Bern ","Nouakchott","Budapest","Hargeisa","Hargeisa"],
  ["What is the capital of  Cuba?","Bishkek","Nouméa","Buenos Aires","Havana","Havana"],
  ["Name the capital city of  Finland?","Bissau","Havana","Cairo","Helsinki","Helsinki"],
  ["Which city is the capital of Solomon Islands?","Bloemfontein","Luxembourg","Canberra","Honiara","Honiara"],
  ["Which of the following is the capital of  Pakistan?","Bogotá","Madrid","Caracas","Islamabad","Islamabad"],
  ["____ is the capital of  Indonesia?","Plymouth","Majuro","Castries","Jakarta","Jakarta"],
  ["What is the capital city of Saint Helena?","Brasília","Malabo","Podgorica","Jamestown","Jamestown"],
  ["What is the capital of  Israel?","Bratislava","Malé","Chișinău","Jerusalem","Jerusalem"],
  ["Name the capital city of  South Sudan?","Brazzaville","Managua","Colombo","Juba","Juba"],
  ["Which city is the capital of Afghanistan?","Bridgetown","Manama","Conakry","Kabul","Kabul"],
  ["Which of the following is the capital of  Uganda?","Brussels","Manila","Copenhagen","Kampala","Kampala"],
  ["What is the capital of  Nepal?","Monaco","Maputo","Porto-Novo","Kathmandu","Kathmandu"],
  ["Name the capital city of  Sudan?","Monrovia","Mariehamn","Dakar","Khartoum","Khartoum"],
  ["Which city is the capital of Rwanda?","Montevideo","Marigot","Damascus","Kigali","Kigali"],
  ["Which of the following is the capital of  Jamaica?","Moroni","Maseru","Dodoma","Kingston","Kingston"],
  ["____ is the capital of  Congo?","Moscow","Moroni","Dhaka","Kinshasa","Kinshasa"],
  ["What is the capital city of Malaysia?","Muscat","Moscow","Dili","Kuala Lumpur","Kuala Lumpur"],
  [" Kuwait's capital is","Nairobi","Muscat","Djibouti","Kuwait City","Kuwait City"],
  [" Ukraine's capital is","Nassau","Nairobi","Doha","Kyiv","Kyiv"],
  ["Name the capital city of  Bolivia?","Naypyidaw","Nassau","Douglas","La Paz","La Paz"],
  ["Name the capital city of  Gabon?","N'Djamena","Naypyidaw","Dublin","Libreville","Libreville"],
  ["What is the capital of  Malawi?","New Delhi","N'Djamena","Dushanbe","Lilongwe","Lilongwe"],
  ["Name the capital city of  Peru?","Ngerulmud","New Delhi","El Aaiún","Lima","Lima"],
  ["Which city is the capital of Portugal?","Niamey","Ngerulmud","Flying Fish Cove","Lisbon","Lisbon"],
  ["Which of the following is the capital of  Slovenia?","Nicosia","Niamey","Freetown","Ljubljana","Ljubljana"],
  ["Name the capital city of  Eswatini?","Nouakchott","Nicosia","Funafuti","Lobamba","Lobamba"],
  ["Name the capital city of  Togo?","Nouméa","Nouakchott","Gaborone","Lomé","Lomé"],
  ["Which city is the capital of United Kingdom?","Luxembourg","Nouméa","Hagåtña","London","London"],
  [" Angola's capital is","Madrid","Pago Pago","Hamilton","Luanda","Luanda"],
  [" Zambia's capital is","Majuro","Palikir","Hanoi","Lusaka","Lusaka"],
  ["What is the capital of  Luxembourg?","Malabo","Panama City","Harare","Luxembourg","Luxembourg"],
  ["Name the capital city of  Spain?","Malé","Papeete","Hargeisa","Madrid","Madrid"],
  ["Which city is the capital of Marshall Islands?","Managua","Paramaribo","Havana","Majuro","Majuro"],
  ["Which of the following is the capital of  Equatorial Guinea?","Manama","Paris","Helsinki","Malabo","Malabo"],
  ["Name the capital city of  Maldives?","Manila","Philipsburg","Honiara","Malé","Malé"],
  ["____ is the capital of  Nicaragua?","Maputo","Phnom Penh","Islamabad","Managua","Managua"],
  ["What is the capital city of Bahrain?","Mariehamn","Port Louis","Jakarta","Manama","Manama"],
  ["What is the capital of  Philippines?","Marigot","Kingston","Jamestown","Manila","Manila"],
  ["Name the capital city of  Mozambique?","Maseru","Kinshasa","Jerusalem","Maputo","Maputo"],
  ["Which city is the capital of Åland Islands?","Jakarta","Luxembourg","Juba","Mariehamn","Mariehamn"],
  ["Which of the following is the capital of  Saint Martin?","Jamestown","Madrid","Kabul","Marigot","Marigot"],
  ["____ is the capital of  Lesotho?","Jerusalem","Majuro","Kampala","Maseru","Maseru"],
  ["What is the capital city of Wallis and Futuna?","Juba","Malabo","Kathmandu","Mata Utu","Mata Utu"],
  ["What is the capital of  Mexico?","Kabul","Malé","Khartoum","Mexico City","Mexico City"],
  ["Name the capital city of  Belarus?","Kampala","Managua","Kigali","Minsk","Minsk"],
  ["Which city is the capital of Somalia?","Kathmandu","Manama","Kingston","Mogadishu","Mogadishu"],
  ["Which of the following is the capital of  Monaco?","Washington, D.C.","Manila","Kinshasa","Monaco","Monaco"],
  [" Liberia's capital is","Wellington","Maputo","Kuala Lumpur","Monrovia","Monrovia"],
  [" Uruguay's capital is","West Island","Mariehamn","Kuwait City","Montevideo","Montevideo"],
  ["Name the capital city of  Comoros?","Willemstad","Marigot","Kyiv","Moroni","Moroni"],
  ["Which city is the capital of Russia?","Windhoek","Maseru","La Paz","Moscow","Moscow"],
  ["Which of the following is the capital of  Oman?","Yaoundé","Pago Pago","Libreville","Muscat","Muscat"],
  ["Name the capital city of  Kenya?","Yaren","Palikir","Lilongwe","Nairobi","Nairobi"],
  ["Which city is the capital of Bahamas?","Yerevan","Panama City","Lima","Nassau","Nassau"],
  ["Which of the following is the capital of  Myanmar?","Zagreb","Papeete","Lisbon","Naypyidaw","Naypyidaw"],
  ["____ is the capital of  Chad?","Oslo","Paramaribo","Ljubljana","N'Djamena","N'Djamena"],
  ["What is the capital city of India?","Ottawa","Paris","Lobamba","New Delhi","New Delhi"],
  ["What is the capital of  Palau?","Ouagadougou","Philipsburg","Lomé","Ngerulmud","Ngerulmud"],
  ["Name the capital city of  Niger?","Avarua","Mogadishu","London","Niamey","Niamey"],
  ["Which city is the capital of Cyprus?","Baghdad","Monaco","Luanda","Nicosia","Nicosia"],
  ["Which of the following is the capital of  Mauritania?","Baku","Monrovia","Lusaka","Nouakchott","Nouakchott"],
  ["Which of the following is the capital of  New Caledonia?","Bamako","Panama City","Luxembourg","Nouméa","Nouméa"],
  ["Which of the following is the capital of  Tonga?","Bandar Seri Begawan","Paramaribo","Madrid","Nukuʻalofa","Nukuʻalofa"],
  ["Name the capital city of  Kazakhstan?","Bangkok","Hagåtña","St. Pierre","Nur-Sultan","Nur-Sultan"],
  ["Which city is the capital of Greenland?","Bangui","Hamilton","Stanley","Nuuk","Nuuk"],
  ["Which of the following is the capital of  Aruba?","Banjul","Hanoi","Stepanakert","Oranjestad","Oranjestad"],
  ["____ is the capital of  Norway?","Basseterre","Harare","Stockholm","Oslo","Oslo"],
  ["What is the capital city of Canada?","Beijing","Hargeisa","Sukhumi","Ottawa","Ottawa"],
  ["What is the capital of  Burkina Faso?","Beirut","Havana","Suva","Ouagadougou","Ouagadougou"],
  ["Name the capital city of  American Samoa?","Belgrade","Helsinki","Taipei","Pago Pago","Pago Pago"],
  ["Which city is the capital of Micronesia?","Belmopan","Honiara","Tallinn","Palikir","Palikir"],
  ["Which of the following is the capital of  Panama?","Praia","Islamabad","Tashkent","Panama City","Panama City"],
  ["____ is the capital of  French Polynesia?","Pristina","Jakarta","Tbilisi","Papeete","Papeete"],
  ["What is the capital city of Suriname?","Pyongyang","Jamestown","Tegucigalpa","Paramaribo","Paramaribo"],
  ["What is the capital of  France?","Quito","Jerusalem","Tehran","Paris","Paris"],
  ["Name the capital city of  Sint Maarten?","Rabat","Juba","Thimphu","Philipsburg","Philipsburg"],
  ["Which city is the capital of Cambodia?","Reykjavík","Kabul","Tirana","Phnom Penh","Phnom Penh"],
  ["Which of the following is the capital of  Mauritius?","Riga","Kampala","Tiraspol","Port Louis","Port Louis"],
  ["____ is the capital of  Papua New Guinea?","Riyadh","Kathmandu","Tokyo","Port Moresby","Port Moresby"],
  ["What is the capital city of Trinidad and Tobago?","Road Town","Khartoum","Tórshavn","Port of Spain","Port of Spain"],
  ["What is the capital of  Vanuatu?","Rome","Kigali","Tripoli","Port Vila","Port Vila"],
  ["Name the capital city of  Haiti?","Roseau","Kingston","Tskhinvali","Port-au-Prince","Port-au-Prince"],
  ["Which city is the capital of Czechia?","Rothera","Kinshasa","Tunis","Prague","Prague"],
  ["Which of the following is the capital of  Cape Verde?","Saipan","Kuala Lumpur","Ulaanbaatar","Praia","Praia"],
  [" Kosovo[g]'s capital city is","Rothera","Kuwait City","Vaduz","Pristina","Pristina"],
  [" North Korea's capital city is","Saipan","Kyiv","Valletta","Pyongyang","Pyongyang"],
  [" Ecuador's capital city is","San José","La Paz","Pago Pago","Quito","Quito"],
  ["What is the capital of  Morocco?","Yamoussoukro","Libreville","Palikir","Rabat","Rabat"],
  ["Name the capital city of  Iceland?","Abu Dhabi","Lilongwe","Panama City","Reykjavík","Reykjavík"],
  ["Which city is the capital of Latvia?","Abuja","Lima","Papeete","Riga","Riga"],
  ["Which of the following is the capital of  Saudi Arabia?","Accra","Lisbon","Paramaribo","Riyadh","Riyadh"],
  ["____ is the capital of  British Virgin Islands?","Adamstown","Ljubljana","Paris","Road Town","Road Town"],
  ["What is the capital city of Italy?","Addis Ababa","Lobamba","Philipsburg","Rome","Rome"],
  ["What is the capital of  Dominica?","Algiers","Lomé","Athens","Roseau","Roseau"],
  ["Name the capital city of  British Antarctic Territory?","Alofi","London","Avarua","Rothera","Rothera"],
  ["Which city is the capital of Northern Mariana Islands?","Amman","Luanda","Baghdad","Saipan","Saipan"],
  ["Which of the following is the capital of  Costa Rica?","Amsterdam","Lusaka","Baku","San José","San José"],
  [" Puerto Rico's capital city is","Andorra la Vella","Luxembourg","Bamako","San Juan","San Juan"],
  [" San Marino's capital city is","Ankara","Madrid","Bandar Seri Begawan","San Marino","San Marino"],
  [" El Salvador's capital city is","Antananarivo","San Marino","Bangkok","San Salvador","San Salvador"],
  ["What is the capital of  Chile?","Apia","San Salvador","Bangui","Santiago","Santiago"],
  ["Name the capital city of  Dominican Republic?","Ashgabat","Santiago","Banjul","Santo Domingo","Santo Domingo"],
  ["Which city is the capital of São Tomé and Príncipe?","Asmara","Santo Domingo","Basseterre","São Tomé","São Tomé"],
  ["Which of the following is the capital of  Bosnia and Herzegovina?","Asunción","Athens","Beijing","Sarajevo","Sarajevo"],
  ["____ is the capital of  South Korea?","Athens","Avarua","Beirut","Seoul","Seoul"],
  ["What is the capital city of Singapore?","Avarua","Baghdad","Belgrade","Singapore","Singapore"],
  ["What is the capital of  North Macedonia?","Hagåtña","Baku","Belmopan","Skopje","Skopje"],
  ["Name the capital city of  Bulgaria?","Hamilton","Bamako","San Marino","Sofia","Sofia"],
  ["Which city is the capital of Kiribati?","Hanoi","Bandar Seri Begawan","San Salvador","South Tarawa","South Tarawa"],
  ["Which of the following is the capital of  Grenada?","Harare","Bangkok","Santiago","St. George's","St. George's"],
  ["What is the capital of  Jersey?","Hargeisa","Bangui","Santo Domingo","St. Helier","St. Helier"],
  ["Name the capital city of  Antigua and Barbuda?","Havana","Banjul","Athens","St. John's","St. John's"],
  ["Which city is the capital of Guernsey?","Helsinki","Accra","Avarua","St. Peter Port","St. Peter Port"],
  ["Which of the following is the capital of  Saint Pierre and Miquelon?","Honiara","Adamstown","Washington, D.C.","St. Pierre","St. Pierre"],
  ["____ is the capital of  Falkland Islands?","Islamabad","Addis Ababa","Wellington","Stanley","Stanley"],
  ["What is the capital city of Artsakh?","Jakarta","Algiers","West Island","Stepanakert","Stepanakert"],
  ["What is the capital of  Sweden?","Jamestown","Alofi","Willemstad","Stockholm","Stockholm"],
  ["Name the capital city of  Abkhazia?","Jerusalem","Amman","Windhoek","Sukhumi","Sukhumi"],
  ["Which city is the capital of Fiji?","Juba","Amsterdam","Yaoundé","Suva","Suva"],
  ["  Taiwan's capital city is","Kabul","Andorra la Vella","Yaren","Taipei","Taipei"],
  [" Estonia's capital city is","Kampala","Ankara","Yerevan","Tallinn","Tallinn"],
  [" Uzbekistan's capital city is","Kathmandu","Antananarivo","Zagreb","Tashkent","Tashkent"],
  ["What is the capital of  Georgia?","Khartoum","Apia","Praia","Tbilisi","Tbilisi"],
  ["Name the capital city of  Honduras?","Kigali","Ashgabat","Pristina","Tegucigalpa","Tegucigalpa"],
  ["Which city is the capital of Iran?","Kingston","Asmara","Pyongyang","Tehran","Tehran"],
  ["Which of the following is the capital of  Bhutan?","Kinshasa","Asunción","Quito","Thimphu","Thimphu"],
  ["____ is the capital of  Albania?","Kuala Lumpur","Athens","Rabat","Tirana","Tirana"],
  ["What is the capital city of Transnistria?","Kuwait City","Avarua","Reykjavík","Tiraspol","Tiraspol"],
  ["What is the capital of  Japan?","Kyiv","Warsaw","Riga","Tokyo","Tokyo"],
  ["Name the capital city of  Faroe Islands?","La Paz","Washington, D.C.","Riyadh","Tórshavn","Tórshavn"],
  ["Which city is the capital of Libya?","Libreville","Wellington","Road Town","Tripoli","Tripoli"],
  ["Which of the following is the capital of  South Ossetia?","Lilongwe","West Island","Rome","Tskhinvali","Tskhinvali"],
  ["Which city is the capital of Tunisia?","Lima","Willemstad","Roseau","Tunis","Tunis"],
  ["Which of the following is the capital of  Mongolia?","Lisbon","Windhoek","Rothera","Ulaanbaatar","Ulaanbaatar"],
  ["____ is the capital of  Liechtenstein?","Ljubljana","Yaoundé","Saipan","Vaduz","Vaduz"],
  ["What is the capital city of Malta?","Lobamba","Yaren","Accra","Valletta","Valletta"],
  ["What is the capital of  Anguilla?","Lomé","Yerevan","Adamstown","The Valley","The Valley"],
  ["Name the capital city of  Vatican City?","London","Zagreb","Addis Ababa","Vatican City","Vatican City"],
  ["Which city is the capital of Seychelles?","Luanda","St. Pierre","Algiers","Victoria","Victoria"],
  ["Which of the following is the capital of  Austria?","Lusaka","Stanley","Alofi","Vienna","Vienna"],
  ["____ is the capital of  Laos?","Luxembourg","Stepanakert","Amman","Vientiane","Vientiane"],
  ["What is the capital city of Lithuania?","Madrid","Stockholm","Amsterdam","Vilnius","Vilnius"],
  ["What is the capital of  Poland?","Tiraspol","Sukhumi","Andorra la Vella","Warsaw","Warsaw"],
  ["Name the capital city of  United States?","Tokyo","Suva","Ankara","Washington, D.C.","Washington, D.C."],
  ["Which city is the capital of New Zealand?","Tórshavn","Taipei","Antananarivo","Wellington","Wellington"],
  ["Which of the following is the capital of  Cocos Islands?","Tripoli","Tallinn","Apia","West Island","West Island"],
  ["____ is the capital of  Curaçao?","Tskhinvali","Tashkent","Ashgabat","Willemstad","Willemstad"],
  ["What is the capital city of Namibia?","Tunis","Tbilisi","Asmara","Windhoek","Windhoek"],
  ["What is the capital of  Cameroon?","Ulaanbaatar","Tegucigalpa","Asunción","Yaoundé","Yaoundé"],
  ["Name the capital city of  Nauru?","Vaduz","Tehran","Athens","Yaren","Yaren"],
  ["Which city is the capital of Armenia?","Valletta","Thimphu","Avarua","Yerevan","Yerevan"],
  ["Which of the following is the capital of  Croatia?","Kyiv","Tirana","Warsaw","Zagreb","Zagreb"],
  
        

 ],

 Currencies : [ 
  ["What is the currency of Abkhazia?","Russian ruble","Falkland Islands pound","Rwandan franc","Lebanese pound","Russian ruble"],
  ["Which of the following is the currency of Afghanistan?","Afghan afghani","Danish krone","United States dollar","Lesotho loti","Afghan afghani"],
  ["The currency of Akrotiri and Dhekelia is ","Euro","Fijian dollar","Algerian dinar","Liberian dollar","Euro"],
  ["What is the currency of Albania?","Albanian lek","Euro","Saint Helena pound","Libyan dinar","Albanian lek"],
  ["Which of the following is the currency of Alderney?","Guernsey pound","Euro","Eastern Caribbean dollar","Swiss franc","Guernsey pound"],
  ["The currency of Algeria is ","Algerian dinar","CFP franc","Eastern Caribbean dollar","Euro","Algerian dinar"],
  ["What is the currency of Andorra?","Euro","Central African CFA franc","Eastern Caribbean dollar","Euro","Euro"],
  ["Which of the following is the currency of Angola?","Angolan kwanza","Gambian dalasi","Samoan tālā","Macanese pataca","Angolan kwanza"],
  ["The currency of Anguilla is ","Eastern Caribbean dollar","Georgian lari","Euro","Malagasy ariary","Eastern Caribbean dollar"],
  ["What is the currency of Antigua and Barbuda?","Eastern Caribbean dollar","Euro","São Tomé and Príncipe dobra","Malawian kwacha","Eastern Caribbean dollar"],
  ["Which of the following is the currency of Argentina?","Argentine peso","Ghanaian cedi","Saudi riyal","Malaysian ringgit","Argentine peso"],
  ["The currency of Armenia is ","Armenian dram","Gibraltar pound","West African CFA franc","Maldivian rufiyaa","Armenian dram"],
  ["What is the currency of Artsakh?","Armenian dram","Euro","Serbian dinar","West African CFA franc","Armenian dram"],
  ["Which of the following is the currency of Aruba?","Aruban florin","Danish krone","Seychellois rupee","Euro","Aruban florin"],
  ["The currency of Ascension Island is ","Saint Helena pound","Eastern Caribbean dollar","Sierra Leonean leone","United States dollar","Saint Helena pound"],
  ["What is the currency of Australia?","Australian dollar","Guatemalan quetzal","Singapore dollar","Mauritanian ouguiya","Australian dollar"],
  ["Which of the following is the currency of Austria?","Euro","Guernsey pound","United States dollar","Mauritian rupee","Euro"],
  ["The currency of Azerbaijan is ","Azerbaijani manat","Guinean franc","Netherlands Antillean guilder","Mexican peso","Azerbaijani manat"],
  ["What is the currency of Bahamas?","Bahamian dollar","West African CFA franc","Euro","United States dollar","Bahamian dollar"],
  ["Which of the following is the currency of Bahrain?","Bahraini dinar","Guyanese dollar","Solomon Islands dollar","Moldovan leu","Bahraini dinar"],
  ["The currency of Bangladesh is ","Bangladeshi taka","Haitian gourde","Central African CFA franc","Euro","Bangladeshi taka"],
  ["What is the currency of Barbados?","Barbadian dollar","Honduran lempira","Central African CFA franc","United States dollar","Barbadian dollar"],
  ["Which of the following is the currency of Belarus?","Belarusian ruble","Hong Kong dollar","Chilean peso","Mauritanian ouguiya","Belarusian ruble"],
  ["The currency of Belgium is ","Euro","Hungarian forint","Renminbi/Chinese yuan","Mauritian rupee","Euro"],
  ["What is the currency of Belize?","Belize dollar","Icelandic króna","Colombian peso","Mexican peso","Belize dollar"],
  ["Which of the following is the currency of Benin?","West African CFA franc","Indian rupee","Comorian franc","United States dollar","West African CFA franc"],
  ["The currency of Bermuda is ","Bermudian dollar","Indonesian rupiah","Congolese franc","Moldovan leu","Bermudian dollar"],
  ["What is the currency of Bhutan?","Bhutanese ngultrum","Iranian rial","Central African CFA franc","Euro","Bhutanese ngultrum"],
  ["Which of the following is the currency of Bolivia?","Bolivian boliviano","Iraqi dinar","Cook Islands dollar","Mongolian tögrög","Bolivian boliviano"],
  ["The currency of Bonaire is ","United States dollar","Euro","Costa Rican colón","Euro","United States dollar"],
  ["What is the currency of Bosnia and Herzegovina?","Bosnia and Herzegovina convertible mark","Manx pound","Euro","Eastern Caribbean dollar","Bosnia and Herzegovina convertible mark"],
  ["Which of the following is the currency of Botswana?","Botswana pula","Israeli new shekel","Ghanaian cedi","Moroccan dirham","Botswana pula"],
  ["The currency of Brazil is ","Brazilian real","Euro","Gibraltar pound","Mozambican metical","Brazilian real"],
  ["What is the currency of British Indian Ocean Territory?","United States dollar","Jamaican dollar","Euro","Burmese kyat","United States dollar"],
  ["Which of the following is the currency of British Virgin Islands?","United States dollar","Japanese yen","Danish krone","Namibian dollar","United States dollar"],
  ["The currency of Brunei is ","Brunei dollar","Jersey pound","Eastern Caribbean dollar","Australian dollar","Brunei dollar"],
  ["What is the currency of Bulgaria?","Bulgarian lev","Jordanian dinar","Afghan afghani","Nepalese rupee","Bulgarian lev"],
  ["Which of the following is the currency of Burkina Faso?","West African CFA franc","Kazakhstani tenge","Euro","Euro","West African CFA franc"],
  ["The currency of Burundi is ","Burundian franc","Kenyan shilling","Albanian lek","CFP franc","Burundian franc"],
  ["What is the currency of Cambodia?","Cambodian riel","Kiribati dollar","Guernsey pound","New Zealand dollar","Cambodian riel"],
  ["Which of the following is the currency of Cameroon?","Central African CFA franc","North Korean won","Algerian dinar","Nicaraguan córdoba","Central African CFA franc"],
  ["The currency of Canada is ","Canadian dollar","South Korean won","Euro","West African CFA franc","Canadian dollar"],
  ["What is the currency of Cape Verde?","Cape Verdean escudo","Euro","Angolan kwanza","Nigerian naira","Cape Verdean escudo"],
  ["Which of the following is the currency of Cayman Islands?","Cayman Islands dollar","Kuwaiti dinar","Eastern Caribbean dollar","New Zealand dollar","Cayman Islands dollar"],
  ["The currency of Central African Republic is ","Central African CFA franc","Kyrgyz som","Eastern Caribbean dollar","Macedonian denar","Central African CFA franc"],
  ["What is the currency of Chad?","Central African CFA franc","Lao kip","Argentine peso","Turkish lira","Central African CFA franc"],
  ["Which of the following is the currency of Chile?","Chilean peso","Euro","Armenian dram","Norwegian krone","Chilean peso"],
  ["The currency of China, People's Republic of is ","Renminbi/Chinese yuan","Tongan paʻanga","Armenian dram","Omani rial","Renminbi/Chinese yuan"],
  ["What is the currency of Colombia?","Colombian peso","Transnistrian ruble","Aruban florin","Pakistani rupee","Colombian peso"],
  ["Which of the following is the currency of Comoros?","Comorian franc","Trinidad and Tobago dollar","Saint Helena pound","United States dollar","Comorian franc"],
  ["The currency of Congo, Democratic Republic of the is ","Congolese franc","Tunisian dinar","Australian dollar","Israeli new shekel","Congolese franc"],
  ["Which of the following is the currency of Cook Islands?","Cook Islands dollar","Turkmenistan manat","Azerbaijani manat","Papua New Guinean kina","Cook Islands dollar"],
  ["The currency of Costa Rica is ","Costa Rican colón","United States dollar","Bahamian dollar","Paraguayan guaraní","Costa Rican colón"],
  ["What is the currency of Côte d'Ivoire?","West African CFA franc","Tuvaluan dollar","Bahraini dinar","Peruvian sol","West African CFA franc"],
  ["Which of the following is the currency of Croatia?","Croatian kuna","Ugandan shilling","Bangladeshi taka","Philippine peso","Croatian kuna"],
  ["The currency of Cuba is ","Cuban peso","Ukrainian hryvnia","Barbadian dollar","New Zealand dollar","Cuban peso"],
  ["What is the currency of Curaçao?","Netherlands Antillean guilder","United Arab Emirates dirham","Belarusian ruble","Polish złoty","Netherlands Antillean guilder"],
  ["Which of the following is the currency of Cyprus?","Euro","Sterling","Euro","Euro","Euro"],
  ["The currency of Czech Republic is ","Czech koruna","United States dollar","Belize dollar","Qatari riyal","Czech koruna"],
  ["What is the currency of Denmark?","Danish krone","Uruguayan peso","West African CFA franc","Romanian leu","Danish krone"],
  ["Which of the following is the currency of Djibouti?","Djiboutian franc","Uzbekistani soʻm","Bermudian dollar","Russian ruble","Djiboutian franc"],
  ["The currency of Dominica is ","Eastern Caribbean dollar","Vanuatu vatu","Bhutanese ngultrum","Tongan paʻanga","Eastern Caribbean dollar"],
  ["What is the currency of Dominican Republic?","Dominican peso","Euro","Bolivian boliviano","Transnistrian ruble","Dominican peso"],
  ["Which of the following is the currency of East Timor?","United States dollar","Venezuelan sovereign bolívar","United States dollar","Trinidad and Tobago dollar","United States dollar"],
  ["The currency of Ecuador is ","United States dollar","Vietnamese đồng","Bosnia and Herzegovina convertible mark","Tunisian dinar","United States dollar"],
  ["What is the currency of Egypt?","Egyptian pound","CFP franc","Botswana pula","Turkish lira","Egyptian pound"],
  ["Which of the following is the currency of El Salvador?","United States dollar","Yemeni rial","Brazilian real","Turkmenistan manat","United States dollar"],
  ["The currency of Equatorial Guinea is ","Central African CFA franc","Zambian kwacha","United States dollar","United States dollar","Central African CFA franc"],
  ["What is the currency of Eritrea?","Eritrean nakfa","RTGS dollar","United States dollar","Tuvaluan dollar","Eritrean nakfa"],
  ["Which of the following is the currency of Estonia?","Euro","Russian ruble","Lebanese pound","Ugandan shilling","Euro"],
  ["The currency of Eswatini is ","Swazi lilangeni","Afghan afghani","Lesotho loti","Ukrainian hryvnia","Swazi lilangeni"],
  ["What is the currency of Ethiopia?","Ethiopian birr","Euro","Liberian dollar","United Arab Emirates dirham","Ethiopian birr"],
  ["Which of the following is the currency of Falkland Islands?","Falkland Islands pound","Albanian lek","Libyan dinar","Sterling","Falkland Islands pound"],
  ["The currency of Faroe Islands is ","Danish krone","Guernsey pound","Swiss franc","United States dollar","Danish krone"],
  ["What is the currency of Fiji?","Fijian dollar","Algerian dinar","Euro","Uruguayan peso","Fijian dollar"],
  ["Which of the following is the currency of Finland?","Euro","Euro","Euro","Uzbekistani soʻm","Euro"],
  ["The currency of France is ","Euro","Angolan kwanza","Macanese pataca","Vanuatu vatu","Euro"],
  ["What is the currency of French Polynesia?","CFP franc","Eastern Caribbean dollar","Malagasy ariary","Euro","CFP franc"],
  ["Which of the following is the currency of Gabon?","Central African CFA franc","Eastern Caribbean dollar","Malawian kwacha","Venezuelan sovereign bolívar","Central African CFA franc"],
  ["The currency of Gambia is ","Gambian dalasi","Argentine peso","Malaysian ringgit","Vietnamese đồng","Gambian dalasi"],
  ["What is the currency of Georgia?","Georgian lari","Armenian dram","Maldivian rufiyaa","CFP franc","Georgian lari"],
  ["Which of the following is the currency of Germany?","Euro","Armenian dram","West African CFA franc","Yemeni rial","Euro"],
  ["The currency of Ghana is ","Ghanaian cedi","Aruban florin","Euro","Zambian kwacha","Ghanaian cedi"],
  ["What is the currency of Gibraltar?","Gibraltar pound","Saint Helena pound","United States dollar","RTGS dollar","Gibraltar pound"],
  ["Which of the following is the currency of Greece?","Euro","Australian dollar","Mauritanian ouguiya","Central African CFA franc","Euro"],
  ["The currency of Greenland is ","Danish krone","Euro","Mauritian rupee","Central African CFA franc","Danish krone"],
  ["What is the currency of Grenada?","Eastern Caribbean dollar","Azerbaijani manat","Mexican peso","Chilean peso","Eastern Caribbean dollar"],
  ["Which of the following is the currency of Guatemala?","Guatemalan quetzal","Bahamian dollar","United States dollar","Renminbi/Chinese yuan","Guatemalan quetzal"],
  ["The currency of Guernsey is ","Guernsey pound","Bahraini dinar","Moldovan leu","Colombian peso","Guernsey pound"],
  ["What is the currency of Guinea?","Guinean franc","Bangladeshi taka","Euro","Comorian franc","Guinean franc"],
  ["Which of the following is the currency of Guinea-Bissau?","West African CFA franc","Barbadian dollar","United States dollar","Congolese franc","West African CFA franc"],
  ["The currency of Guyana is ","Guyanese dollar","Belarusian ruble","Mauritanian ouguiya","Central African CFA franc","Guyanese dollar"],
  ["What is the currency of Haiti?","Haitian gourde","Euro","Mauritian rupee","Cook Islands dollar","Haitian gourde"],
  ["Which of the following is the currency of Honduras?","Honduran lempira","Belize dollar","Mexican peso","Costa Rican colón","Honduran lempira"],
  ["The currency of Hong Kong is ","Hong Kong dollar","West African CFA franc","United States dollar","West African CFA franc","Hong Kong dollar"],
  ["What is the currency of Hungary?","Hungarian forint","Bermudian dollar","Moldovan leu","Bahamian dollar","Hungarian forint"],
  ["Which of the following is the currency of Iceland?","Icelandic króna","Bhutanese ngultrum","Euro","Bahraini dinar","Icelandic króna"],
  ["The currency of India is ","Indian rupee","Bolivian boliviano","Mongolian tögrög","Bangladeshi taka","Indian rupee"],
  ["What is the currency of Indonesia?","Indonesian rupiah","United States dollar","Euro","Barbadian dollar","Indonesian rupiah"],
  ["Which of the following is the currency of Iran?","Iranian rial","Bosnia and Herzegovina convertible mark","Eastern Caribbean dollar","Belarusian ruble","Iranian rial"],
  ["The currency of Iraq is ","Iraqi dinar","Botswana pula","Moroccan dirham","Euro","Iraqi dinar"],
  ["What is the currency of Ireland?","Euro","Brazilian real","Mozambican metical","Belize dollar","Euro"],
  ["Which of the following is the currency of Isle of Man?","Manx pound","United States dollar","Burmese kyat","West African CFA franc","Manx pound"],
  ["The currency of Israel is ","Israeli new shekel","United States dollar","Namibian dollar","Albanian lek","Israeli new shekel"],
  ["What is the currency of Italy?","Euro","Brunei dollar","Australian dollar","Russian ruble","Euro"],
  ["Which of the following is the currency of Jamaica?","Jamaican dollar","Bulgarian lev","Nepalese rupee","Afghan afghani","Jamaican dollar"],
  ["The currency of Japan is ","Japanese yen","West African CFA franc","Euro","Euro","Japanese yen"],
  ["What is the currency of Jersey?","Jersey pound","Burundian franc","CFP franc","Albanian lek","Jersey pound"],
  ["Which of the following is the currency of Jordan?","Jordanian dinar","Cambodian riel","New Zealand dollar","Guernsey pound","Jordanian dinar"],
  ["The currency of Kazakhstan is ","Kazakhstani tenge","Central African CFA franc","Nicaraguan córdoba","Algerian dinar","Kazakhstani tenge"],
  ["What is the currency of Kenya?","Kenyan shilling","Canadian dollar","West African CFA franc","Euro","Kenyan shilling"],
  ["Which of the following is the currency of Kiribati?","Kiribati dollar","Cape Verdean escudo","Nigerian naira","Angolan kwanza","Kiribati dollar"],
  ["The currency of North Korea is ","North Korean won","Cayman Islands dollar","New Zealand dollar","Eastern Caribbean dollar","North Korean won"],
  ["What is the currency of South Korea?","South Korean won","Central African CFA franc","Macedonian denar","Eastern Caribbean dollar","South Korean won"],
  ["Which of the following is the currency of Kosovo?","Euro","Central African CFA franc","Turkish lira","Argentine peso","Euro"],
  ["The currency of Kuwait is ","Kuwaiti dinar","Chilean peso","Norwegian krone","Armenian dram","Kuwaiti dinar"],
  ["What is the currency of Kyrgyzstan?","Kyrgyz som","Renminbi/Chinese yuan","Omani rial","Armenian dram","Kyrgyz som"],
  ["Which of the following is the currency of Laos?","Lao kip","Colombian peso","Pakistani rupee","Aruban florin","Lao kip"],
  ["The currency of Latvia is ","Euro","Comorian franc","United States dollar","Saint Helena pound","Euro"],
  ["What is the currency of Lebanon?","Lebanese pound","Congolese franc","Israeli new shekel","Australian dollar","Lebanese pound"],
  ["Which of the following is the currency of Lesotho?","Lesotho loti","Central African CFA franc","Panamanian balboa","Euro","Lesotho loti"],
  ["The currency of Liberia is ","Liberian dollar","Cook Islands dollar","Papua New Guinean kina","Azerbaijani manat","Liberian dollar"],
  ["What is the currency of Libya?","Libyan dinar","Costa Rican colón","Paraguayan guaraní","Bahamian dollar","Libyan dinar"],
  ["Which of the following is the currency of Liechtenstein?","Swiss franc","West African CFA franc","Peruvian sol","Bahraini dinar","Swiss franc"],
  ["The currency of Lithuania is ","Euro","Croatian kuna","Philippine peso","Bangladeshi taka","Euro"],
  ["What is the currency of Luxembourg?","Euro","Cuban peso","New Zealand dollar","Barbadian dollar","Euro"],
  ["Which of the following is the currency of Macau?","Macanese pataca","Netherlands Antillean guilder","Polish złoty","Belarusian ruble","Macanese pataca"],
  ["The currency of Madagascar is ","Malagasy ariary","Euro","Euro","Euro","Malagasy ariary"],
  ["What is the currency of Malawi?","Malawian kwacha","Czech koruna","Qatari riyal","Belize dollar","Malawian kwacha"],
  ["Which of the following is the currency of Malaysia?","Malaysian ringgit","Danish krone","Romanian leu","West African CFA franc","Malaysian ringgit"],
  ["The currency of Maldives is ","Maldivian rufiyaa","Djiboutian franc","Russian ruble","Bermudian dollar","Maldivian rufiyaa"],
  ["What is the currency of Mali?","West African CFA franc","Eastern Caribbean dollar","","Bhutanese ngultrum","West African CFA franc"],
  ["Which of the following is the currency of Malta?","Euro","Dominican peso","Falkland Islands pound","Bolivian boliviano","Euro"],
  ["The currency of Marshall Islands is ","United States dollar","United States dollar","Danish krone","United States dollar","United States dollar"],
  ["What is the currency of Mauritania?","Mauritanian ouguiya","United States dollar","Fijian dollar","Bosnia and Herzegovina convertible mark","Mauritanian ouguiya"],
  ["Which of the following is the currency of Mauritius?","Mauritian rupee","Egyptian pound","Euro","Botswana pula","Mauritian rupee"],
  ["The currency of Mexico is ","Mexican peso","United States dollar","Euro","Brazilian real","Mexican peso"],
  ["What is the currency of Micronesia?","United States dollar","Central African CFA franc","CFP franc","United States dollar","United States dollar"],
  ["Which of the following is the currency of Moldova?","Moldovan leu","Eritrean nakfa","Central African CFA franc","United States dollar","Moldovan leu"],
  ["The currency of Monaco is ","Euro","Euro","Gambian dalasi","Falkland Islands pound","Euro"],
  ["What is the currency of Mongolia?","Mongolian tögrög","Swazi lilangeni","Georgian lari","Danish krone","Mongolian tögrög"],
  ["Which of the following is the currency of Montenegro?","Euro","Ethiopian birr","Euro","Fijian dollar","Euro"],
  ["The currency of Montserrat is ","Eastern Caribbean dollar","Tongan paʻanga","Ghanaian cedi","Euro","Eastern Caribbean dollar"],
  ["What is the currency of Morocco?","Moroccan dirham","Transnistrian ruble","Gibraltar pound","Euro","Moroccan dirham"],
  ["Which of the following is the currency of Mozambique?","Mozambican metical","Trinidad and Tobago dollar","Euro","CFP franc","Mozambican metical"],
  ["The currency of Myanmar is ","Burmese kyat","Tunisian dinar","Burundian franc","Central African CFA franc","Burmese kyat"],
  ["What is the currency of Namibia?","Namibian dollar","Turkish lira","Cambodian riel","Gambian dalasi","Namibian dollar"],
  ["Which of the following is the currency of Nauru?","Australian dollar","Turkmenistan manat","Central African CFA franc","Georgian lari","Australian dollar"],
  ["The currency of Nepal is ","Nepalese rupee","United States dollar","Canadian dollar","Euro","Nepalese rupee"],
  ["What is the currency of Netherlands?","Euro","Tuvaluan dollar","Cape Verdean escudo","Ghanaian cedi","Euro"],
  ["Which of the following is the currency of New Caledonia?","CFP franc","Ugandan shilling","Cayman Islands dollar","Gibraltar pound","CFP franc"],
  ["The currency of New Zealand is ","New Zealand dollar","Ukrainian hryvnia","Central African CFA franc","Euro","New Zealand dollar"],
  ["What is the currency of Nicaragua?","Nicaraguan córdoba","United Arab Emirates dirham","Central African CFA franc","Danish krone","Nicaraguan córdoba"],
  ["Which of the following is the currency of Niger?","West African CFA franc","Sterling","Chilean peso","Eastern Caribbean dollar","West African CFA franc"],
  ["The currency of Nigeria is ","Nigerian naira","United States dollar","Renminbi/Chinese yuan","Guatemalan quetzal","Nigerian naira"],
  ["What is the currency of Niue?","New Zealand dollar","Uruguayan peso","Colombian peso","Guernsey pound","New Zealand dollar"],
  ["Which of the following is the currency of North Macedonia?","Macedonian denar","Uzbekistani soʻm","Comorian franc","Guinean franc","Macedonian denar"],
  ["The currency of Northern Cyprus is ","Turkish lira","Vanuatu vatu","Congolese franc","West African CFA franc","Turkish lira"],
  ["What is the currency of Norway?","Norwegian krone","Euro","Central African CFA franc","Guyanese dollar","Norwegian krone"],
  ["Which of the following is the currency of Oman?","Omani rial","Venezuelan sovereign bolívar","Cook Islands dollar","Haitian gourde","Omani rial"],
  ["The currency of Pakistan is ","Pakistani rupee","Vietnamese đồng","Costa Rican colón","Honduran lempira","Pakistani rupee"],
  ["What is the currency of Palau?","United States dollar","CFP franc","West African CFA franc","Hong Kong dollar","United States dollar"],
  ["Which of the following is the currency of Palestine?","Israeli new shekel","Yemeni rial","Croatian kuna","Hungarian forint","Israeli new shekel"],
  ["The currency of Panama is ","Panamanian balboa","Zambian kwacha","Cuban peso","Icelandic króna","Panamanian balboa"],
  ["What is the currency of Papua New Guinea?","Papua New Guinean kina","RTGS dollar","Netherlands Antillean guilder","Indian rupee","Papua New Guinean kina"],
  ["Which of the following is the currency of Paraguay?","Paraguayan guaraní","West African CFA franc","Euro","Indonesian rupiah","Paraguayan guaraní"],
  ["The currency of Peru is ","Peruvian sol","Serbian dinar","Czech koruna","Iranian rial","Peruvian sol"],
  ["What is the currency of Philippines?","Philippine peso","Seychellois rupee","Danish krone","Iraqi dinar","Philippine peso"],
  ["Which of the following is the currency of Pitcairn Islands?","New Zealand dollar","Sierra Leonean leone","Djiboutian franc","Euro","New Zealand dollar"],
  ["The currency of Poland is ","Polish złoty","Singapore dollar","Eastern Caribbean dollar","Manx pound","Polish złoty"],
  ["What is the currency of Portugal?","Euro","United States dollar","Dominican peso","Israeli new shekel","Euro"],
  ["Which of the following is the currency of Qatar?","Qatari riyal","United States dollar","United States dollar","Euro","Qatari riyal"],
  ["The currency of Romania is ","Romanian leu","Algerian dinar","United States dollar","Jamaican dollar","Romanian leu"],
  ["What is the currency of Russia?","Russian ruble","Saint Helena pound","Egyptian pound","Japanese yen","Russian ruble"],
  ["Which of the following is the currency of Rwanda?","Rwandan franc","United States dollar","United States dollar","Jersey pound","Rwandan franc"],
  ["The currency of Saba is ","United States dollar","Mauritanian ouguiya","Central African CFA franc","Jordanian dinar","United States dollar"],
  ["What is the currency of Sahrawi Republic?","Algerian dinar","Mauritian rupee","Eritrean nakfa","Kazakhstani tenge","Algerian dinar"],
  ["Which of the following is the currency of Saint Helena?","Saint Helena pound","Mexican peso","Euro","Kenyan shilling","Saint Helena pound"],
  ["The currency of Saint Kitts and Nevis is ","Eastern Caribbean dollar","United States dollar","Swazi lilangeni","Kiribati dollar","Eastern Caribbean dollar"],
  ["What is the currency of Saint Lucia?","Eastern Caribbean dollar","Moldovan leu","Ethiopian birr","North Korean won","Eastern Caribbean dollar"],
  ["Which of the following is the currency of Saint Vincent and the Grenadines?","Eastern Caribbean dollar","Euro","Falkland Islands pound","South Korean won","Eastern Caribbean dollar"],
  ["The currency of Samoa is ","Samoan tālā","Mongolian tögrög","Danish krone","Euro","Samoan tālā"],
  ["What is the currency of San Marino?","Euro","Euro","Fijian dollar","Kuwaiti dinar","Euro"],
  ["Which of the following is the currency of São Tomé and Príncipe?","São Tomé and Príncipe dobra","Eastern Caribbean dollar","Euro","Kyrgyz som","São Tomé and Príncipe dobra"],
  ["The currency of Saudi Arabia is ","Saudi riyal","Moroccan dirham","Euro","Lao kip","Saudi riyal"],
  ["What is the currency of Senegal?","West African CFA franc","Mozambican metical","CFP franc","Euro","West African CFA franc"],
  ["Which of the following is the currency of Serbia?","Serbian dinar","Burmese kyat","Central African CFA franc","Canadian dollar","Serbian dinar"],
  ["The currency of Seychelles is ","Seychellois rupee","Namibian dollar","Gambian dalasi","Cape Verdean escudo","Seychellois rupee"],
  ["What is the currency of Sierra Leone?","Sierra Leonean leone","Australian dollar","Georgian lari","Cayman Islands dollar","Sierra Leonean leone"],
  ["Which of the following is the currency of Singapore?","Singapore dollar","Nepalese rupee","Euro","Central African CFA franc","Singapore dollar"],
  ["The currency of Sint Eustatius is ","United States dollar","Euro","Ghanaian cedi","Central African CFA franc","United States dollar"],
  ["What is the currency of Sint Maarten?","Netherlands Antillean guilder","CFP franc","Gibraltar pound","Chilean peso","Netherlands Antillean guilder"],
  ["Which of the following is the currency of Slovakia?","Euro","New Zealand dollar","Euro","Renminbi/Chinese yuan","Euro"],
  ["The currency of Solomon Islands is ","Solomon Islands dollar","Nicaraguan córdoba","Danish krone","Colombian peso","Solomon Islands dollar"],
  ["What is the currency of Somalia?","Somali shilling","West African CFA franc","Eastern Caribbean dollar","Comorian franc","Somali shilling"],
  ["Which of the following is the currency of Somaliland?","Somaliland shilling","Nigerian naira","Guatemalan quetzal","Congolese franc","Somaliland shilling"],
  ["The currency of South Africa is ","South African rand","New Zealand dollar","Guernsey pound","Central African CFA franc","South African rand"],
  ["What is the currency of South Ossetia?","Russian ruble","Macedonian denar","Guinean franc","Cook Islands dollar","Russian ruble"],
  ["Which of the following is the currency of Spain?","Euro","Turkish lira","West African CFA franc","Costa Rican colón","Euro"],
  ["The currency of South Sudan is ","South Sudanese pound","Norwegian krone","Guyanese dollar","West African CFA franc","South Sudanese pound"],
  ["What is the currency of Sri Lanka?","Sri Lankan rupee","Omani rial","Haitian gourde","Croatian kuna","Sri Lankan rupee"],
  ["Which of the following is the currency of Sudan?","Sudanese pound","Pakistani rupee","Honduran lempira","Cuban peso","Sudanese pound"],
  ["The currency of Suriname is ","Surinamese dollar","United States dollar","Hong Kong dollar","Netherlands Antillean guilder","Surinamese dollar"],
  ["What is the currency of Sweden?","Swedish krona","Israeli new shekel","Hungarian forint","Euro","Swedish krona"],
  ["Which of the following is the currency of Switzerland?","Swiss franc","Panamanian balboa","Icelandic króna","Czech koruna","Swiss franc"],
  ["The currency of Syria is ","Syrian pound","Papua New Guinean kina","Indian rupee","Danish krone","Syrian pound"],
  ["What is the currency of Taiwan?","New Taiwan dollar","Paraguayan guaraní","Indonesian rupiah","Djiboutian franc","New Taiwan dollar"],
  ["Which of the following is the currency of Tajikistan?","Tajikistani somoni","Peruvian sol","Iranian rial","Eastern Caribbean dollar","Tajikistani somoni"],
  ["The currency of Tanzania is ","Tanzanian shilling","Philippine peso","Iraqi dinar","Dominican peso","Tanzanian shilling"],
  ["What is the currency of Thailand?","Thai baht","New Zealand dollar","Euro","United States dollar","Thai baht"],
  ["Which of the following is the currency of Togo?","West African CFA franc","Polish złoty","Manx pound","United States dollar","West African CFA franc"],
  ["The currency of Tonga is ","Tongan paʻanga","Euro","Israeli new shekel","Egyptian pound","Tongan paʻanga"],
  ["What is the currency of Transnistria?","Transnistrian ruble","Qatari riyal","Euro","United States dollar","Transnistrian ruble"],
  ["Which of the following is the currency of Trinidad and Tobago?","Trinidad and Tobago dollar","Romanian leu","Jamaican dollar","Central African CFA franc","Trinidad and Tobago dollar"],
  ["The currency of Tunisia is ","Tunisian dinar","Russian ruble","Japanese yen","Eritrean nakfa","Tunisian dinar"],
  ["What is the currency of Turkey?","Turkish lira","Rwandan franc","Jersey pound","Euro","Turkish lira"],
  ["Which of the following is the currency of Turkmenistan?","Turkmenistan manat","United States dollar","Jordanian dinar","Swazi lilangeni","Turkmenistan manat"],
  ["The currency of Turks and Caicos Islands is ","United States dollar","Algerian dinar","Kazakhstani tenge","Ethiopian birr","United States dollar"],
  ["What is the currency of Tuvalu?","Tuvaluan dollar","Saint Helena pound","Kenyan shilling","Rwandan franc","Tuvaluan dollar"],
  ["Which of the following is the currency of Uganda?","Ugandan shilling","Eastern Caribbean dollar","Kiribati dollar","United States dollar","Ugandan shilling"],
  ["The currency of Ukraine is ","Ukrainian hryvnia","Eastern Caribbean dollar","North Korean won","Algerian dinar","Ukrainian hryvnia"],
  ["What is the currency of United Arab Emirates?","United Arab Emirates dirham","Eastern Caribbean dollar","South Korean won","Saint Helena pound","United Arab Emirates dirham"],
  ["Which of the following is the currency of United Kingdom?","Sterling","Samoan tālā","Euro","Eastern Caribbean dollar","Sterling"],
  ["The currency of United States is ","United States dollar","Euro","Kuwaiti dinar","Eastern Caribbean dollar","United States dollar"],
  ["What is the currency of Uruguay?","Uruguayan peso","São Tomé and Príncipe dobra","Kyrgyz som","Eastern Caribbean dollar","Uruguayan peso"],
  ["Which of the following is the currency of Uzbekistan?","Uzbekistani soʻm","Saudi riyal","Lao kip","Samoan tālā","Uzbekistani soʻm"],
  ["The currency of Vanuatu is ","Vanuatu vatu","West African CFA franc","Euro","Euro","Vanuatu vatu"],
  ["What is the currency of Vatican City?","Euro","Serbian dinar","Australian dollar","São Tomé and Príncipe dobra","Euro"],
  ["Which of the following is the currency of Venezuela?","Venezuelan sovereign bolívar","Seychellois rupee","Nepalese rupee","Saudi riyal","Venezuelan sovereign bolívar"],
  ["The currency of Vietnam is ","Vietnamese đồng","Sierra Leonean leone","Euro","West African CFA franc","Vietnamese đồng"],
  ["What is the currency of Wallis and Futuna?","CFP franc","Singapore dollar","CFP franc","Serbian dinar","CFP franc"],
  ["Which of the following is the currency of Yemen?","Yemeni rial","United States dollar","New Zealand dollar","Seychellois rupee","Yemeni rial"],
  ["The currency of Zambia is ","Zambian kwacha","Netherlands Antillean guilder","Nicaraguan córdoba","Sierra Leonean leone","Zambian kwacha"],
  ["The currency of Zimbabwe is ","RTGS dollar","Euro","West African CFA franc","Singapore dollar","RTGS dollar"],
  

],

GK : [ 
  ["GWhat is the capital of Virginia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["GWhich city is the capital of Arizona?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["GName the capital of West Virginia","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["GName the capital of New York","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["GWhat is the capital of North Carolina?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the capital of Alabama?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Nevada?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of New Mexico?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the capital of Colorado?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Georgia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Name the capital of Washington","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Ohhio?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the  capital of California?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Texas?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Name the capital of Florida","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  

],

Geography : [ 
  ["gWhat is the capital of Virginia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["gWhich city is the capital of Arizona?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["gName the capital of West Virginia","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["gName the capital of New York","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["gWhat is the capital of North Carolina?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the capital of Alabama?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Nevada?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of New Mexico?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the capital of Colorado?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Georgia?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Name the capital of Washington","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Ohhio?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Which city is the  capital of California?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["What is the capital of Texas?","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  ["Name the capital of Florida","Colombo","Richmond","New Delhi","Hyderabad","Richmond"],
  

],


}